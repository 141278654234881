export const langMap = {
    "UpdateResponse": {
        "IT": "Il tuo account è stato aggiornato.",
        "EN": "Your account has been updated.",
        "FR": "Votre compte a été mis à jour.",
        "ES": "Tu cuenta ha sido actualizada.",
        "DE": "Ihr Account wurde aktualisiert"
    },
    "HelloUser": {
        "IT": "Ciao",
        "EN": "Hello",
        "FR": "Bonjour",
        "ES": "Hola",
        "DE": "Hallo"
    },
    "LoginUser": {
        "IT": "Ultimo accesso: ",
        "EN": "Last login: ",
        "FR": "Dernière connexion : ",
        "ES": "Último acceso: ",
        "DE": "Letzte Anmeldung: "
    },
    "BackButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "Retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "InfoTitle": {
        "IT": "Informazioni",
        "EN": "Info",
        "FR": "Info",
        "ES": "Información",
        "DE": "Persönliche Informationen"
    },
    "FirstNameTitle": {
        "IT": "Cambia il nome",
        "EN": "Change first name",
        "FR": "Changer le prénom",
        "ES": "Cambiar el primer nombre",
        "DE": "Vornamen ändern"
    },
    "CountryTitle": {
        "IT": "Cambia il paese",
        "EN": "Change country",
        "FR": "Changer de pays",
        "ES": "Cambiar pais",
        "DE": "Land wählen"
    },
    "OrganizationTitle": {
        "IT": "Cambia il nome dell'organizzazione",
        "EN": "Change organisation name",
        "FR": "Changer le nom de l'organisation",
        "ES": "Cambiar el nombre de la organización",
        "DE": "Organisationsname ändern"
    },
    "LastNameTitle": {
        "IT": "Cambia cognome",
        "EN": "Change last name",
        "FR": "Changer le nom de famille",
        "ES": "Cambiar el apellido",
        "DE": "Nachnamen ändern"
    },
    "JobTitle": {
        "IT": "Cambia il titolo di lavoro",
        "EN": "Change job title",
        "FR": "Modifier l’intitulé du poste",
        "ES": "Cambiar el título de tu puesto de trabajo",
        "DE": "Berufsbezeichnung ändern"
    },
    "CredentialsTitle": {
        "IT": "Credenziali",
        "EN": "Credentials",
        "FR": "Identifiants",
        "ES": "Credenciales",
        "DE": "Zugangsdaten ändern"
    },
    "UsernameTitle": {
        "IT": "Cambia nome utente",
        "EN": "Change Username",
        "FR": "Changer le nom d'utilisateur",
        "ES": "Cambie el nombre de usuario",
        "DE": "Benutzernamen ändern"
    },
    "PasswordTitle": {
        "IT": "Cambia la Password",
        "EN": "Change Password",
        "FR": "Changer le mot de passe",
        "ES": "Cambia la contraseña",
        "DE": "Passwort ändern"
    },
    "PasswordText": {
        "IT": "Nuova Password",
        "EN": "New Password",
        "FR": "Nouveau mot de passe",
        "ES": "Nueva contraseña",
        "DE": "Neues Passwort"
    },
    "EmailTitle": {
        "IT": "Cambia email",
        "EN": "Change Email",
        "FR": "Changer l'e-mail",
        "ES": "Cambiar e-mail",
        "DE": "Emailaddresse ändern"
    },
    "ConfirmPasswordTitle": {
        "IT": "Conferma password",
        "EN": "Confirm Password",
        "FR": "Confirmez le mot de passe",
        "ES": "Confirmar contraseña",
        "DE": "Passwort bestätigen"
    },
    "ConfirmPasswordText": {
        "IT": "Conferma la nuova password",
        "EN": "Confirm New Password",
        "FR": "Confirmer le nouveau mot de passe",
        "ES": "Confirmar nueva contraseña",
        "DE": "Bestätigen Sie Ihr neues Passwort"
    },
    "CurrentPasswordTitle": {
        "IT": "Password attuale (richiesta)",
        "EN": "Current Password (Required)",
        "FR": "Mot de passe actuel (requis)",
        "ES": "Contraseña actual (requerida)",
        "DE": "Aktuelles Passwort (erforderlich)"
    },
    "CurrentPasswordText": {
        "IT": "Password attuale",
        "EN": "Current Password",
        "FR": "Mot de passe actuel",
        "ES": "Contraseña actual",
        "DE": "Aktuelles Passwort"
    },
    "UpdateButtonText": {
        "IT": "Aggiorna",
        "EN": "Update",
        "FR": "Mise à jour",
        "ES": "Actualizar",
        "DE": "Aktualisieren"
    },
    "DeleteAccountFormTitle": {
        "IT": "Modulo di disattivazione dell'account",
        "EN": "Disable Account Form",
        "FR": "Formulaire de désactivation de compte",
        "ES": "Formulario de desactivación de cuenta",
        "DE": "Kontoformular deaktivieren"
    },
    "DeleteAccountFormInnerTitle": {
        "IT": "Volete disattivare il vostro account?",
        "EN": "Do you want to disable your account?",
        "FR": "Voulez-vous désactiver votre compte ?",
        "ES": "¿Desea desactivar su cuenta?",
        "DE": "Möchten Sie Ihr Konto deaktivieren?"
    },
    "DeleteAccountFormTextConfigTop": {
        "IT": "Per disattivare il vostro account, scrivete la vostra password attuale e cliccate sul pulsante sottostante.",
        "EN": "To disable your account, write your current password and click the button below",
        "FR": "Pour désactiver votre compte, écrivez votre mot de passe actuel et cliquez sur le bouton ci-dessous.",
        "ES": "Para desactivar su cuenta, introduzca su contraseña actual y haga clic en el botón de abajo.",
        "DE": "Um Ihr Konto zu deaktivieren, geben Sie Ihr aktuelles Passwort ein und klicken Sie auf die Schaltfläche unten"
    },
    "DeleteAccountFormButtonText": {
        "IT": "Disattivare l'account",
        "EN": "Disable account",
        "FR": "Désactiver le compte",
        "ES": "Desactivar cuenta",
        "DE": "Konto deaktivieren"
    },
    "DeleteAccountFormConfirmButtonText": {
        "IT": "Disattivare",
        "EN": "Disable",
        "FR": "Désactiver",
        "ES": "Desactivar",
        "DE": "Deaktivieren"
    },
    "DeleteAccountFormSuccessTitle": {
        "IT": "L'email è stata inviata",
        "EN": "Email sent",
        "FR": "Courriel envoyé",
        "ES": "Correo electrónico enviado",
        "DE": "E-Mail gesendet"
    },
    "DeleteAccountFormSuccessText": {
        "IT": "Un'email con le istruzioni per disabilitare l'account è stata inviata al vostro indirizzo di posta elettronica.",
        "EN": "An email with the instructions to disable the account has been sent to your email address",
        "FR": "Un email contenant les instructions pour désactiver le compte a été envoyé à votre adresse.",
        "ES": "Se ha enviado a su dirección de correo electrónico un mensaje con instrucciones para desactivar la cuenta.",
        "DE": "Eine E-Mail mit Anweisungen zum Deaktivieren des Kontos wurde an Ihre E-Mail-Adresse gesendet"
    },
    "DeleteAccountFormErrorTitle": {
        "IT": "Attenzione, qualcosa è andato storto!",
        "EN": "Warning, something went wrong!",
        "FR": "Attention, une erreur s'est produite !",
        "ES": "Atención, se ha producido un error",
        "DE": "Achtung, etwas ist schief gelaufen!"
    },
    "DeleteAccountFormErrorText": {
        "IT": "Si è verificato un errore e il vostro account non è stato disabilitato. Si prega di",
        "EN": "An error occurred and your account hasn't been disabled. Please",
        "FR": "Une erreur s'est produite et votre compte n'a pas été désactivé. Veuillez",
        "ES": "Se ha producido un error y su cuenta no ha sido desactivada. Por favor,",
        "DE": "Ein Fehler ist aufgetreten und Ihr Konto wurde nicht deaktiviert. Bitte"
    },
    "DeleteAccountFormErrorTextLink": {
        "IT": "contattarci",
        "EN": "contact us",
        "FR": "nous contacter",
        "ES": "póngase en contacto con nosotros",
        "DE": "kontaktiere uns"
    }
}
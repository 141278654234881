export const langMap =  {
    "EmailInstructionsResponse": {
        "IT": "È stata inviata un'e-mail contenente istruzioni su come confermare la reimpostazione della password",
        "EN": "An email containing instructions on how to confirm the password reset has been sent to ",
        "FR": "Un e-mail contenant des instructions sur la façon de confirmer que la réinitialisation du mot de passe a été envoyée à",
        "ES": "Se ha enviado un correo electrónico que contiene instrucciones sobre cómo confirmar el reinicio de la contraseña",
        "DE": "Eine E -Mail mit Anweisungen zur Bestätigung des Zurücksetzens des Passworts wurde gesendet"
    },
    "SendingRequestText": {
        "IT": "richiesta di invio ...",
        "EN": "sending request...",
        "FR": "envoyer une requête...",
        "ES": "enviando pedido...",
        "DE": "anfrage wird versendet"
    },
    "BackButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "Retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "TopText": {
        "IT": "Resetta la password",
        "EN": "Reset Password",
        "FR": "réinitialiser le mot de passe",
        "ES": "Restablecer la contraseña",
        "DE": "Passwort zurücksetzen"
    },
    "ThankText": {
        "IT": "Grazie!",
        "EN": "Thank You!",
        "FR": "Merci !",
        "ES": "¡Gracias!",
        "DE": "Danke!"
    },
    "UsernameLabel": {
        "IT": "Il tuo nome utente o e-mail",
        "EN": "Your username or email",
        "FR": "Votre nom d'utilisateur ou votre e-mail",
        "ES": "Su nombre de usuario o correo electrónico",
        "DE": "Ihr Benutzername oder Ihre E -Mail"
    },
    "PasswordLabel": {
        "IT": "Nuova password",
        "EN": "New password",
        "FR": "Nouveau mot de passe",
        "ES": "Nueva contraseña",
        "DE": "Neues Passwort"
    },
    "ConfirmPasswordLabel": {
        "IT": "Conferma la nuova password",
        "EN": "Confirm new password",
        "FR": "Confirmer le nouveau mot de passe",
        "ES": "Confirmar nueva contraseña",
        "DE": "Bestätigen Sie Ihr neues Passwort"
    },
    "ConfirmButton": {
        "IT": "Conferma il ripristino",
        "EN": "Confirm Reset",
        "FR": "Confirmer la réinitialisation",
        "ES": "Confirmar reinicio",
        "DE": "Zurücksetzen bestätigen"
    }
}
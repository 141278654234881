export const errorMessages = {
    "serviceUnavailable": {
        "IT": "Mi dispiace, questo servizio attualmente non funziona. Riprovare più tardi.",
        "EN": "Sorry, this service is currently not working. Try again later.",
        "FR": "Désolé, ce service ne fonctionne actuellement pas. Réessayez plus tard.",
        "ES": "Lo sientimos, este servicio no funciona actualmente. Inténtalo de nuevo más tarde.",
        "DE": "Entschuldigung, dieser Service funktioniert derzeit nicht. Versuchen Sie es später noch einmal."
    },
    "noConcurrentAccess": {
        "IT": "L'accesso contemporaneo non è consentito per lo stesso utente",
        "EN": "Concurrent access is not permitted for the same user",
        "FR": "L'accès simultané n'est pas autorisé pour le même utilisateur",
        "ES": "El acceso concurrente no está permitido para el mismo usuario",
        "DE": "Der gleichzeitige Zugriff ist für denselben Benutzer nicht gestattet"
    },
    "missingRefreshToken": {
        "IT": "Il token di aggiornamento è mancante",
        "EN": "Refresh token is missing",
        "FR": "Le jeton de rafraîchissement est manquant",
        "ES": "Falta el token de actualización",
        "DE": "Refresh -Token fehlt"
    },
    "invalidCredentials": {
        "IT": "Username e / o password non validi",
        "EN": "Invalid username and/or password",
        "FR": "Nom d'utilisateur et / ou mot de passe incorrect",
        "ES": "Nombre de usuario y / o contraseña inválido",
        "DE": "Ungültiger Benutzername und / oder Passwort"
    },
    "missingCredentials": {
        "IT": "Impossibile leggere il nome utente e la password dal corpo della richiesta HTTP",
        "EN": "Could not read the username and password from the HTTP request's body",
        "FR": "Impossible de lire le nom d'utilisateur et le mot de passe à partir du corps de la demande HTTP",
        "ES": "No se pudo leer el nombre de usuario y la contraseña desde el cuerpo de la solicitud HTTP",
        "DE": "Der angegebene Benutzername und Passwort konnte in der http-Anfrage nicht gelesen werden. "
    },
    "passwordError": {
        "IT": "La password deve essere lunga almeno 8 caratteri e contenere almeno 1 carattere maiuscolo, 1 carattere minuscolo, 1 cifra, 1 carattere speciale (come $, #, %) e nessuno spazio",
        "EN": "Password must be at least 8 characters long and contain at least 1 uppercase character, 1 lowercase character, 1 digit, 1 special character ( such as $, #, %) and NO whitespaces",
        "FR": "Le mot de passe doit comporter au moins 8 caractères et contenir au moins 1 caractère majuscule, 1 caractère minuscule, 1 chiffre, 1 caractère spécial (tel que $, #,%) et aucun espace blanc",
        "ES": "La contraseña debe tener al menos 8 caracteres y contener al menos 1 carácter mayúscula, 1 carácter en minúsculas, 1 número, 1 carácter especial (como $, #, %) y sin espacios en blancos",
        "DE": "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Ziffer, 1 Sonderzeichen (z.B. $, #, %) und keine Leerzeichen enthalten"
    },
    "serverError": {
        "IT": "Errore del server",
        "EN": "Server error",
        "FR": "Erreur du serveur",
        "ES": "Error del Servidor",
        "DE": "Serverfehler"
    },
    "JSONKeyNotFound": {
        "IT": "JSON key non trovato",
        "EN": "JSON key not found",
        "FR": "JSON Key introuvable",
        "ES": "Json Key no encontrado",
        "DE": "JSON Key nicht gefunden"
    },
    "occupationCountryCodeNotFound": {
        "IT": "Occupazione o codice paese non trovato",
        "EN": "Occupation or country code not found",
        "FR": "Occupation ou code de pays introuvable",
        "ES": "Ocupación o código de país no encontrado",
        "DE": "Beruf oder Ländercode nicht gefunden"
    },
    "expiredLicense": {
        "IT": "La licenza di questo utente è scaduta",
        "EN": "This user's license has expired",
        "FR": "Cette licence de l'utilisateur a expiré",
        "ES": "Esta licencia de usuario ha expirado",
        "DE": "Die Lizenz dieses Benutzers ist abgelaufen"
    },
    "emailAlreadyExists": {
        "IT": "Questa e-mail è già in uso",
        "EN": "This email is already in use",
        "FR": "Cette adresse email est déjà utilisée",
        "ES": "Este correo electrónico ya está en uso",
        "DE": "Diese E-Mail-Adresse wird schon verwendet"
    },
    "nameAlreadyExists": {
        "IT": "Questo nome/e-mail è già in uso",
        "EN": "This name/email is already in use",
        "FR": "Cette nome/adresse email est déjà utilisée",
        "ES": "Este nombre/correo electrónico ya está en uso",
        "DE": "Diesen Name/ diese E-Mail-Adresse wird schon verwendet"
    },
    "passwordCheck": {
        "IT": "Le due password fornite non corrispondono",
        "EN": "The two provided passwords don't match",
        "FR": "Les deux mots de passe fournis ne correspondent pas",
        "ES": "Las dos contraseñas proporcionadas no coinciden",
        "DE": "Die beiden angegebenen Passwörter stimmen nicht überein"
    },
    "invalidRegistrationCode": {
        "IT": "Codice di conferma non valido fornito",
        "EN": "Invalid confirmation code provided",
        "FR": "Code de confirmation fourni non valide",
        "ES": "Código de confirmación proporcionado no válido",
        "DE": "Ungültiger Bestätigungscode angegeben"
    },
    "wrongPassword": {
        "IT": "Password corrente errata o mancante",
        "EN": "Incorrect or missing current password",
        "FR": "Mot de passe actuel incorrect ou manquant",
        "ES": "Contraseña actual incorrecta o faltante",
        "DE": "Falsches oder fehlendes aktuelles Passwort"
    },
    "expiredToken": {
        "IT": "Token scaduto",
        "EN": "Expired token",
        "FR": "Jeton expiré",
        "ES": "Token caducado",
        "DE": "Abgelaufenes Token"
    },
    "firstNameError": {
        "IT": "Nome mancante o non valido",
        "EN": "Missing or invalid first name",
        "FR": "Prénom manquant ou non valide",
        "ES": "Nombre faltante o inválido",
        "DE": "Fehlender oder ungültiger Vorname"
    },
    "lastNameError": {
        "IT": "Cognome mancante o non valido",
        "EN": "Missing or invalid last name",
        "FR": "Nom de famille manquant ou non valide",
        "ES": "Apellido faltante o inválido",
        "DE": "Fehlender oder ungültiger Nachname"
    },
    "jobTitleError": {
        "IT": "Titolo di lavoro mancante",
        "EN": "Missing job title",
        "FR": "Intitulé du poste manquant",
        "ES": "Título del puesto de trabajo faltante",
        "DE": "Fehlende Berufsbezeichnung"
    },
    "organizationNameError": {
        "IT": "Nome dell'organizzazione mancante",
        "EN": "Missing organization name",
        "FR": "Nom de l'organisation manquante",
        "ES": "Nombre de la organización faltante",
        "DE": "Fehlender Organisationsname"
    },
    "homeCountryError": {
        "IT": "Paese di origine mancante",
        "EN": "Missing home country",
        "FR": "Pays d'origine manquant",
        "ES": "País de origen faltante",
        "DE": "Fehlendes Land"
    },
    "emailError": {
        "IT": "Email mancante o non valida",
        "EN": "Missing or invalid email",
        "FR": "E-mail manquant ou non valide",
        "ES": "Correo electrónico faltante o inválido",
        "DE": "Fehlende oder ungültige E -Mail"
    },
    "checkError": {
        "IT": "Le due password fornite non corrispondono",
        "EN": "The two provided passwords don't match",
        "FR": "Les deux mots de passe fournis ne correspondent pas",
        "ES": "Las dos contraseñas proporcionadas no coinciden",
        "DE": "Die beiden angegebenen Passwörter stimmen nicht überein"
    },
    "concurrentAccessError": {
        "IT": "Flag di accesso contemporaneo mancante",
        "EN": "Missing concurrent access flag",
        "FR": "Flag d'accès simultané manquant",
        "ES": "Flag de acceso simultáneo faltante",
        "DE": "Fehlende gleichzeitige Zugangsflagge"
    },
    "originError": {
        "IT": "Origine del sito web mancante",
        "EN": "Missing origin website",
        "FR": "Site web d'origine manquant",
        "ES": "Sitio web de origen faltante",
        "DE": "Fehlende Origin -Website"
    },
    "usernameError": {
        "IT": "Nome utente mancante o non valido",
        "EN": "Missing or invalid username",
        "FR": "Nom d'utilisateur manquant ou non valide",
        "ES": "Nombre de usuario faltante o inválido",
        "DE": "Fehlender oder ungültiger Benutzername"
    },
    "userNotFound": {
        "IT": "Nessun utente è stato trovato con il nome utente/e-mail fornito",
        "EN": "No user has been found for the provided username/email",
        "FR": "Aucun utilisateur n'a été trouvé pour le nom d'utilisateur / l'e-mail fourni",
        "ES": "No se ha encontrado ningún usuario para el nombre de usuario/correo electrónico proporcionado",
        "DE": "Für den angegebenen Benutzernnamen / die angegebene E-Mail Adresse wurde kein Benutzer gefunden."
    },
    "invalidConfirmationCode": {
        "IT": "Il codice fornito non è valido",
        "EN": "The provided code is invalid",
        "FR": "Le code fourni n'est pas valide",
        "ES": "El código introducido no es válido",
        "DE": "Der angegebene Code ist ungültig"
    }
}
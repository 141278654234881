export const langMap = {
    "PageNotFoundTitleLeft": {
        "IT": "Pagina non",
        "EN": "Page not",
        "FR": "Page non",
        "ES": "Página no",
        "DE": "Seite nicht"
    },
    "PageNotFoundTitleRight": {
        "IT": "trovata",
        "EN": "found",
        "FR": "trouvé",
        "ES": "encontrada",
        "DE": "gefunden"
    },
    "PageNotFoundText": {
        "IT": "Ops! Sembra che tu abbia seguito un link sbagliato. Se pensi che sia un errore nostro, ti preghiamo di segnalarcelo.",
        "EN": "Oops! Looks like you followed a bad link. If you think this is a problem with us, please tell us.",
        "FR": "Oops! On dirait que vous avez suivi un mauvais lien. Si vous pensez que c'est un problème avec nous, veuillez nous le dire.",
        "ES": "¡Ups! Parece que seguiste un enlace incorrecto. Si crees que esto es un problema nuestro, por favor díganos.",
        "DE": "Hoppla! Sieht so aus, als hätten Sie einem schlechten Link gefolgt. Wenn Sie der Meinung sind, dass dies ein Problem bei uns ist, sagen Sie es uns bitte."
    },
    "BackHomeButtonText": {
        "IT": "Torna alla Homepage",
        "EN": "Go back home",
        "FR": "Retour à l'accueil",
        "ES": "Regresar a la página de inicio",
        "DE": "Zurück zur Startseite"
    }
}
export const availableCountries = [
    {"name":"France", "code":"FR", "disabled":false},
    {"name":"Germany", "code":"DE", "disabled":false},
    {"name":"Republic of Ireland", "code":"IE", "disabled":false},
    {"name":"Italy", "code":"IT", "disabled":false},
    {"name":"Netherlands", "code":"NL", "disabled":false},
    {'name':"Spain", "code":"ES", "disabled":false},
    {"name":"Switzerland", "code":"CH", "disabled":false},
    {"name":"United Kingdom", "code":"UK", "disabled": false},
    {"name":"Algeria", "code":"DZ", "disabled":true},
    {"name":"Austria", "code":"AT", "disabled":true},
    {"name":"Belgium", "code":"BE", "disabled":true},
    {"name":"Bulgaria", "code":"BG", "disabled":true},
    {"name":"Cyprus", "code":"CY", "disabled":true},
    {"name":"Czech Republic", "code":"CZ", "disabled":true},
    {"name":"Denmark", "code":"DK", "disabled":true},
    {"name":"Estonia", "code":"EE", "disabled":true},
    {"name":"Egypt", "code":"EG", "disabled":true},
    {"name":"Greece", "code":"EL", "disabled":true},
    {"name":"Finland", "code":"FI", "disabled":true},
    {"name":"Georgia", "code":"GE", "disabled":true},
    {"name":"Croatia", "code":"HR", "disabled":true},
    {"name":"Hungary", "code":"HU", "disabled":true},
    {"name":"Iceland", "code":"IS", "disabled":true},
    {"name":"Liechtenstein", "code":"LI", "disabled":true},
    {"name":"Lithuania", "code":"LT", "disabled":true},
    {"name":"Luxembourg", "code":"LU", "disabled":true},
    {"name":"Latvia", "code":"LV", "disabled":true},
    {"name":"Morocco", "code":"MA", "disabled":true},
    {"name":"Malta", "code":"MT", "disabled":true},
    {"name":"Norway", "code":"NO", "disabled":true},
    {"name":"Poland", "code":"PL", "disabled":true},
    {"name":"Portugal", "code":"PT", "disabled":true},
    {"name":"Romania", "code":"RO", "disabled":true},
    {"name":"Sweden", "code":"SE", "disabled":true},
    {"name":"Slovenia", "code":"SI", "disabled":true},
    {"name":"Slovakia", "code":"SK", "disabled":true},
    {"name":"Tunisia", "code":"TN", "disabled":true},
    {"name":"Ukraine", "code":"UA", "disabled":true}
];

export const isCountrySelected = (currentCountry) =>{
    return currentCountry.code !== initialCountryObject.code;
}

export const initialCountryObject = {name:"Pick a country"};


export const langMap = {
    "WelcomeTextTop": {
        "IT": "Lightcast è leader mondiale nell'analisi del mercato del lavoro. Il nostro strumento ",
        "EN": "Lightcast is the global leader in labour market analytics. Our",
        "FR": "Lightcast est le leader mondial de l'analyse du marché du travail.  Notre outil",
        "ES": "Lightcast es el líder mundial en análisis del mercado laboral. Nuestra herramienta",
        "DE": "Lightcast ist der weltweit führende Anbieter von Arbeitsmarktanalysen.Unser "
    },
    "WelcomeTextBottom": {
        "IT": "permette di approfondire l'andamento del mercato del lavoro in Europa, considerando la domanda di assunzione, il salario, le compagnie che più assumono, le competenze richieste per una particolare occupazione, e molto altro. ",
        "EN": "tool enables you to dive deep into labour market trends across Europe, including hiring demand, median wages, top employers, skills for a particular occupation, and much more. ",
        "FR": "vous permet d'explorer en profondeur les tendances du marché du travail à travers l'Europe, notamment la demande d'embauche, les salaires médians, les principaux employeurs, les compétences requises pour une profession donnée, et bien plus encore.",
        "ES": "le permite profundizar en las tendencias del mercado laboral en toda Europa, incluida la demanda de contratación, salarios medios, los principales empleadores, habilidades para una ocupación profesional en particular, y mucho más.",
        "DE": "Tool ermöglicht es Ihnen, tief in die Arbeitsmarkttrends in ganz Europa einzutauchen, einschließlich des Einstellungsbedarfs, der Durchschnittslöhne, der wichtigsten Arbeitgeber, der Qualifikationen für einen bestimmten Beruf und vieles mehr."
    },
    "InstrunctionTextTop": {
        "IT": "Inoltre, è completamente gratuito!  Seleziona semplicemente una nazione a tua scelta, e clicca sul bottone \"scegli un'occupazione\" per vedere la lista delle sue 10 occupazioni più richieste. ",
        "EN": "Plus, it's totally free!  Simply select the country of your choice, and click on the \"choose an occupation\" button to see the list of its top 10 occupations. ",
        "FR": "En plus, c'est entièrement gratuit ! Sélectionnez simplement le pays de votre choix et cliquez sur le bouton \"choisir une profession\" pour voir la liste de ses 10 professions les plus demandées.",
        "ES": "Además, ¡es totalmente gratis! Simplemente seleccione el país de su preferencia y haga clic en el botón \"elija una ocupación\" para ver la lista de sus 10 principales ocupaciones.",
        "DE": "Und es ist völlig kostenlos! Wählen Sie einfach das Land Ihrer Wahl aus und klicken Sie auf die Schaltfläche \"Beruf wählen\", um die Liste der 10 wichtigsten Berufe anzuzeigen."
    },
    "InstrunctionTextBottom": {
        "IT": "Infine, seleziona l'occupazione che vuoi analizzare. Pronto per iniziare?",
        "EN": "Finally, select the occupation you want to analyse. Ready to start?",
        "FR": "Enfin, sélectionnez la profession que vous souhaitez analyser. Vous êtes prêts !",
        "ES": "Por último, seleccione la ocupación que desea analizar. Listo para empezar?",
        "DE": "Wählen Sie schließlich den Beruf aus, den Sie analysieren möchten. Bereit zum Starten?"
    },
    "SeriesWidgetHiringTitle": {
        "IT": "Come si presenta la domanda di assunzione dei datori di lavoro?",
        "EN": "What Does Employer Hiring Demand Look Like?",
        "FR": "À quoi ressemble la demande d'embauche de l'employeur ?",
        "ES": "¿Cómo se ve la demanda de contratación del empleador?",
        "DE": "Wie sieht die Arbeitsnachfrage aus?"
    },
    "SeriesWidgetHiringSubtitle": {
        "IT": "Annunci di lavoro unici per questa professione negli ultimi 12 mesi.",
        "EN": "Unique job postings for this occupation over the last 12 months.",
        "FR": "Offres d'emploi uniques pour cette occupation au cours des 12 derniers mois.",
        "ES": "Publicaciones de trabajo únicas para esta ocupación en los últimos 12 meses.",
        "DE": "Deduplizierte Stellenangebote für diesen Beruf in den letzten 12 Monaten. "
    },
    "SeriesWidgetEmploymentTitle": {
        "IT": "Quanti sono gli occupati in questa professione?",
        "EN": "How Many Are Employed in This Occupation?",
        "FR": "Combien sont employés dans cette profession ?",
        "ES": "¿Cuántos están contratados en esta ocupación?",
        "DE": "Wie viele sind in diesem Beruf beschäftigt?"
    },
    "SeriesWidgetEmploymentSubtitle": {
        "IT": "Numeri di occupati per questa occupazione in 10 anni.",
        "EN": "Employment numbers for this occupation over 10 years.",
        "FR": "Nombre d'emplois pour cette profession sur 10 ans.",
        "ES": "Números de empleos para esta ocupación en los últimos años.",
        "DE": "Beschäftigungszahlen für diesen Beruf über 10 Jahre."
    },
    "UnlockMoreTitle": {
        "IT": "Sblocca più approfondimenti",
        "EN": "Unlock more insights",
        "FR": "Débloquer plus d'informations",
        "ES": "Desbloquear más información",
        "DE": "Schalten Sie mehr Erkenntnisse frei"
    },
    "UnlockMoreText": {
        "IT": "Ci piacerebbe mostrarti quanto pagano queste professioni, quali industrie le impiegano, dove sono concentrate, le competenze che i datori di lavoro richiedono durante l'assunzione e molto altro. Per vedere maggiori dettagli, fai clic sul pulsante Sblocca in basso.",
        "EN": "We’d love to show you how much these occupations pay, which industries employ them, where they are concentrated, the skills employers request when hiring, and much more. To see more detail, click Unlock button below.",
        "FR": "Nous aimerions vous montrer combien ces professions paient, quelles industries les utilisent, où elles sont concentrées, les compétences que les employeurs demandent lors de l'embauche, et bien plus encore. Pour voir plus de détails, cliquez sur le bouton Déverrouiller ci-dessous.",
        "ES": "Nos encantaría mostrarte cuánto pagan estas ocupaciones, qué industrias las contratan, dónde están concentradas, las habilidades que los empleadores solicitan al contratar y mucho más. Para ver más detalles, haga clic en el botón Desbloquear a continuación.",
        "DE": "Wir würden Ihnen gerne zeigen, wie viel diese Berufe zahlen, in welchen Branchen sie gesucht werden, wo sie konzentriert sind, die Fähigkeiten, die Arbeitgeber bei der Einstellung suchen, und vieles mehr. Um weitere Details anzuzeigen, klicken Sie unten auf die Schaltfläche \"Freischalten\"."
    },
    "MedianWidgetTitle": {
        "IT": "Quanto viene pagata questa professione?",
        "EN": "How Much Does This Occupation Pay?",
        "FR": "Combien paie cette profession ?",
        "ES": "¿Cuánto paga esta ocupación?",
        "DE": "Wie viel zahlt dieser Beruf?"
    },
    "MedianWidgetSubtitle": {
        "IT": "Salari mediani offerti dai datori di lavoro negli ultimi 10 anni.",
        "EN": "Median wages offered by employers over the last 10 years.",
        "FR": "Salaires médians offerts par les employeurs au cours des 10 dernières années.",
        "ES": "Los salarios medios ofrecidos por los empleadores en los últimos 10 años.",
        "DE": "Durchschnittliche Löhne, die Arbeitgeber in den letzten 10 Jahren angeboten haben."
    },
    "MedianWidgetMaxText": {
        "IT": "MAX",
        "EN": "MAX",
        "FR": "MAX",
        "ES": "MAX",
        "DE": "MAX"
    },
    "MedianWidgetMinText": {
        "IT": "MIN",
        "EN": "MIN",
        "FR": "MIN",
        "ES": "MIN",
        "DE": "MIN"
    },
    "MedianWidgetMedianText": {
        "IT": "MEDIANA",
        "EN": "MEDIAN",
        "FR": "MÉDIAN",
        "ES": "MEDIANA",
        "DE": "MEDIAN"
    },
    "UnlockButtonText": {
        "IT": "Registrati",
        "EN": "Register",
        "FR": "S'inscrire",
        "ES": "Registrate",
        "DE": "Registrieren"
    },
    "BarsWidgetDemandingTitle": {
        "IT": "Quali industrie chiedono questa occupazione?",
        "EN": "Which Industries Are Demanding This Occupation?",
        "FR": "Quelles industries demandent cette profession ?",
        "ES": "¿Qué industrias requieren esta ocupación?",
        "DE": "Welche Branchen suchen diesen Beruf?"
    },
    "BarsWidgetDemandingSubtitle": {
        "IT": "Le prime 10 industrie che pubblicano offerte di lavoro per questa occupazione.",
        "EN": "Top 10 industries posting job offers for this occupation.",
        "FR": "Top 10 des industries publiant des offres d'emploi pour cette profession.",
        "ES": "Las 10 industrias principales que publican ofertas de trabajo para esta ocupación.",
        "DE": "Top 10 Branchen mit Stellenangeboten für diesen Beruf."
    },
    "BarsWidgetOfferingTitle": {
        "IT": "Dove si trovano i datori di lavoro che offrono questa occupazione?",
        "EN": "Where Are Employers Offering this Occupation?",
        "FR": "Où les employeurs offrent-ils cette profession ?",
        "ES": "¿Dónde están los empleadores que ofrecen esta ocupación?",
        "DE": "Wo bieten Arbeitgeber diesen Beruf an?"
    },
    "BarsWidgetOfferingSubtitle": {
        "IT": "Le principali località del Paese per quota di offerta di questa professione.",
        "EN": "Top locations of the country by share of offer for this occupation.",
        "FR": "Les meilleurs emplacements du pays par part d'offre pour cette occupation.",
        "ES": "Las mejores ubicaciones del país por participación de la oferta para esta ocupación.",
        "DE": "Top Standorte des Landes nach Anteil des Angebots für diesen Beruf."
    },
    "BarsWidgetSpecialisedSkillsTitle": {
        "IT": "Quali competenze specialistiche sono richieste per questo ruolo?",
        "EN": "Which Specialised Skills Are Required For This Role?",
        "FR": "Quelles compétences spécialisées sont nécessaires pour ce rôle ?",
        "ES": "¿Qué habilidades especializadas se requieren para este rol?",
        "DE": "Welche Fachkenntnisse sind für diese Rolle erforderlich?"
    },
    "BarsWidgetSpecialisedSkillsSubtitle": {
        "IT": "Le 10 principali competenze specialistiche richieste dai datori di lavoro negli annunci di lavoro.",
        "EN": "Top 10 specialised skills requested by employers in job postings.",
        "FR": "Top 10 des compétences spécialisées demandées par les employeurs dans les offres d'emploi.",
        "ES": "Top 10 habilidades especializadas solicitadas por empleadores en las ofertas de trabajo.",
        "DE": "Top 10 Fachkenntnisse, die von Arbeitgebern in Stellenanzeigen angefordert werden."
    },
    "BarsWidgetCommonSkillsTitle": {
        "IT": "Quali competenze comuni sono richieste per questo ruolo?",
        "EN": "Which Common Skills Are Required For This Role?",
        "FR": "Quelles compétences communes sont nécessaires pour ce rôle ?",
        "ES": "¿Qué habilidades comunes se requieren para este rol?",
        "DE": "Welche transversalen Kompetenzen sind für diese Rolle erforderlich?"
    },
    "BarsWidgetCommonSkillsSubtitle": {
        "IT": "Le 10 competenze più comuni richieste dai datori di lavoro negli annunci di lavoro.",
        "EN": "Top 10 common skills requested by employers in job postings.",
        "FR": "Top 10 des compétences communes demandées par les employeurs dans les offres d'emploi.",
        "ES": "Top 10 habilidades comunes solicitadas por los empleadores en las publicaciones de trabajo.",
        "DE": "Top 10 transversale Kompetenzen, die von Arbeitgebern in Stellenanzeigen gesucht werden."
    },
    "BookADemoIntroductionTitle": {
        "IT": "Pronto a sbloccare opportunità insieme?",
        "EN": "Ready to unlock opportunities together?",
        "FR": "Prêt à libérer de nouvelles opportunités ensemble?",
        "ES": "Listo para desbloquear oportunidades juntos?",
        "DE": "Sind Sie bereit, gemeinsam neue Möglichkeiten zu erschließen?"
    },
    "BookADemoIntroductionTextTop": {
        "IT": "Ora che hai esplorato il nostro strumento",
        "EN": "Now that you explored our",
        "FR": "Maintenant que vous avez exploré notre outil",
        "ES": "Ahora que haz explorado nuestra herramienta",
        "DE": "Nachdem Sie nun unser"
    },
    "BookADemoIntroductionTextBottom": {
        "IT": ", è il momento di passare ad un nuovo livello. L'ampia ricchezza dai dati di Lightcast può risolvere le tue sfide più pressanti sul mercato del lavoro. Contattaci per discutere i tuoi bisogni e obiettivi, risolviamo problemi e sblocchiamo opportunità insieme.",
        "EN": "tool, it's time to take your journey to the next level. Lightcast's vast wealth of data can solve your most pressing labour market challenges. Get in touch to discuss your needs and goals, let's solve problems and unlock opportunities together.",
        "FR": ", il est temps de passer à au niveau supérieur. L'immense richesse de données dont dispose Lightcast peut résoudre vos défis les plus pressants sur le marché du travail. Contactez-nous pour discuter de vos besoins et objectifs, résolvons les problèmes et débloquons des opportunités ensemble.",
        "ES": ", es hora de llevar tu viaje al siguiente nivel. La gran cantidad de datos de Lightcast puede resolver los desafíos más apremiantes del mercado laboral. Contáctanos para analizar tus necesidades y objetivos, resolvamos problemas y abramos más oportunidades juntos.",
        "DE": "Tool kennengelernt haben, ist es an der Zeit, Ihre Reise auf die nächste Ebene zu bringen. Der riesige Datenschatz von Lightcast kann Ihre dringendsten Herausforderungen auf dem Arbeitsmarkt lösen. Setzen Sie sich mit uns in Verbindung, um Ihre Bedürfnisse und Ziele zu besprechen. Lassen Sie uns gemeinsam Probleme lösen und neue Möglichkeiten erschließen."
    },
    "BookADemoTitle": {
        "IT": "Come possiamo aiutarti?",
        "EN": "How can we help you?",
        "FR": "Comment peut-on vous aider ? ",
        "ES": "Cómo podemos ayudarte?",
        "DE": "Wie können wir Ihnen helfen?"
    },
    "BookADemoInnerTitle": {
        "IT": "Invia la richiesta",
        "EN": "Send the request",
        "FR": "Envoyer la demande",
        "ES": "Enviar la solicitud",
        "DE": "Senden Sie die Anfrage"
    },
    "BookADemoTextConfigTop": {
        "IT": "Fare clic sul pulsante Conferma per completare la procedura e prenotare una demo.",
        "EN": "Click Confirm button to complete the procedure and book a demo.",
        "FR": "Cliquez sur “Confirmer” pour terminer la procédure et réserver une démo.",
        "ES": "Haga clic en el botón Confirmar para completar el procedimiento y agenda una demostración.",
        "DE": "Klicken Sie auf die Schaltfläche Bestätigen, um Ihre Anfrage abzuschließen und eine Demo zu buchen."
    },
    "BookADemoTextConfigBottom": {
        "IT": "Verrai contattato a breve",
        "EN": "You will be contacted shortly",
        "FR": "Vous serez contacté rapidement",
        "ES": "Serás contactado en breve",
        "DE": "Sie werden in Kürze kontaktiert"
    },
    "BookADemoButtonText": {
        "IT": "Contattaci",
        "EN": "Contact us now",
        "FR": "Contactez-nous maintenant",
        "ES": "Contáctanos",
        "DE": "Jetzt Kontakt aufnehmen"
    },
    "BookADemoConfirmButtonText": {
        "IT": "Conferma",
        "EN": "Confirm",
        "FR": "Confirmer",
        "ES": "Confirmar",
        "DE": "Bestätigen"
    },
    "BookADemoErrorTitle": {
        "IT": "Attenzione, qualcosa è andato storto!",
        "EN": "Warning, something went wrong!",
        "FR": "Avertissement, quelque chose s'est mal passé !",
        "ES": "Advertencia, ¡algo salió mal!",
        "DE": "Warnung, etwas ist schief gelaufen!"
    },
    "BookADemoErrorText": {
        "IT": "Si è verificato un errore, si prega di chiudere questa finestra e",
        "EN": "An error occurred, please close this window and",
        "FR": "Une erreur s'est produite, veuillez fermer cette fenêtre et",
        "ES": "Se produjo un error, cierre esta ventana y",
        "DE": "Ein Fehler ist aufgetreten, bitte schließen Sie dieses Fenster und"
    },
    "BookADemoErrorTextLink": {
        "IT": "contattaci",
        "EN": "contact us",
        "FR": "nous contacter",
        "ES": "contáctenos",
        "DE": "kontaktieren Sie uns"
    },
    "SendingRequestText": {
        "IT": "richiesta di invio...",
        "EN": "sending request...",
        "FR": "envoyer une requête...",
        "ES": "enviando pedido...",
        "DE": "Ihre Anfrage wird gesendet"
    }
}
export const langMap = {
    "PrintInviteTextTop": {
        "IT": "Questa è solo una piccola parte dei dati che abbiamo a disposizione.",
        "EN": "This is only a tiny part of the data we have available.",
        "FR": "Ce n'est qu'une infime partie des données dont nous disposons.",
        "ES": "Esta es solo una pequeña parte de los datos que tenemos disponibles.",
        "DE": "Dies ist nur ein winziger Teil der Daten, die wir zur Verfügung haben."
    },
    "PrintInviteTextBottom": {
        "IT": "Per sbloccare l'intero livello di dettaglio, contattaci a ",
        "EN": "To unlock the full level of detail, contact us at ",
        "FR": "Pour déverrouiller tout le niveau de détail, contactez-nous à ",
        "ES": "Para desbloquear el nivel completo de detalles, contáctenos en ",
        "DE": "Um den detaillierten Datensatz freizuschalten, kontaktieren Sie uns unter"
    }
}
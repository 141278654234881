import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/DropdownItem";
import React, {useContext} from "react";
import { Languages } from "../../config/languages";
import LanguageContext from "../../utils/context/i18n/languageContext";
import Image from "react-bootstrap/Image";
import { updateResourceRequest } from "../../utils/REST/requests";
import { Endpoints } from "../../config/api";
import UserContext from "../../utils/context/user/userContext";
import FiltersContext from "../../utils/context/filters/filtersContext";
import { getAvailableOccupations, isOccupationSelected } from "../../config/widgets/selectors/availableOccupationsByCountry";
import { isCountrySelected } from "../../config/widgets/selectors/availableCountries";

const LanguageSelectorItem = ({classImg, lang, flag, preview = false}) => {
    return (
        <div className="flag-container text-right">
            <Image src={flag} alt={lang} className={classImg}/>
            {
                preview &&
                <span className="p-2">{lang}</span>
            }
        </div>
    );

}

const LanguageSelector = ({className}) => {
    const user = useContext(UserContext).user;
    const langContext = useContext(LanguageContext);
    const filtersContext = useContext(FiltersContext);
    const classNameString = `lang-selector dropdown-basic-button ${className}`

    return (
        <DropdownButton className={classNameString} title={
            <LanguageSelectorItem
                lang={langContext.lang}
                flag={require(`../../assets/flags/flags/${langContext.flag}`)}
                classImg="lang-selected"
            />
            }
        drop="up" >
        {
            Languages.map(({lang, flag, locale, language}) => {
               return (
                <DropdownItem 
                    key={lang} { ...(langContext.lang === lang ? {active: "active"} : {})} as="button"
                    onClick={ async() => {
                            if(isCountrySelected(filtersContext.selectedCountry)){
                                let newOccs = filtersContext.displayedOccupations;
                                let occ = filtersContext.selectedOccupation;
                                newOccs = await getAvailableOccupations(lang, filtersContext.selectedCountry.code);
                                if(isOccupationSelected(filtersContext.selectedOccupation))
                                    occ = newOccs.find( o => o.code === occ.code);
                                filtersContext.changeOccupation({country: filtersContext.selectedCountry, occupation: occ, displayedOccupations: newOccs});
                            }
                            langContext.changeLang({lang: lang, locale: locale, flag: flag, language: language});
                            if(user)
                                updateResourceRequest(Endpoints.USER_UPDATE.replace("{id}", user.id), {profileId: user.lastProfile, lastLanguage: lang});
                        }     
                    } 
                >
                <LanguageSelectorItem
                    lang={language}
                    flag={require(`../../assets/flags/flags/${flag}`)}
                    preview={true}
                    classImg="lang-item-selected"
                />      
                </DropdownItem>
               )
            })
        }           
        </DropdownButton>
    );
}

export default LanguageSelector
export const langMap = {
    "ProvidedCodeError": {
        "IT": "Non è stato fornito alcun codice di registrazione",
        "EN": "No registration code has been provided",
        "FR": "Aucun code d'enregistrement n'a été fourni",
        "ES": "No se ha proporcionado ningún código de registro",
        "DE": "Es wurde kein Registrierungscode angegeben. "
    },
    "HomepageButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "Retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "SuccessResetTitle": {
        "IT": "Reset della password avvenuto correttamente!",
        "EN": "Password reset successfully!",
        "FR": "Mot de passe réinitialisé avec succès !",
        "ES": "¡Contraseña reestablecida con éxito!",
        "DE": "Passwort erfolgreich zurückgesetzt!"
    },
    "SuccessResetTextTop": {
        "IT": "Ora puoi usare le tue nuove credenziali.",
        "EN": "Now you can use your new credentials.",
        "FR": "Vous pouvez maintenant utiliser vos nouvelles informations d'identification.",
        "ES": "Ahora puede usar sus nuevas credenciales.",
        "DE": "Sie können jetzt Ihre neuen Anmeldedaten verwenden. "
    },
    "SuccessResetTextBottom": {
        "IT": "Fai clic sul pulsante in basso per usarle.",
        "EN": "Click on the button below to use them.",
        "FR": "Cliquez sur le bouton ci-dessous pour les utiliser.",
        "ES": "Haga clic en el botón de abajo para usarlas.",
        "DE": "Klicken Sie auf die Schaltfläche unten, um sie zu verwenden."
    },
    "ButtonLoginText": {
        "IT": "Login",
        "EN": "Login",
        "FR": "Connexion",
        "ES": "Acceso",
        "DE": "Anmeldung"
    },
    "CheckingCodeText": {
        "IT": "Controllo codice...",
        "EN": "Checking code...",
        "FR": "Vérification du code...",
        "ES": "Verificación de código...",
        "DE": "Code überprüfen..."
    }
}
export const chartClasses = "d-flex justify-content-center flex-column text-center";

function setYAxisOffset(width) {
    if (width<=650) {
        return 0
    } else if (width<=700) {
      return 100
    } else if (width<=975) {
        return 120
    } else if (width<=1200) {
        return 125
    } else if (width<=1350) {
      return 150
    } else if (width<=1700) {
      return 200
    }else {
      return 300
    }
}
const offset = setYAxisOffset(window.innerWidth);

export const barsOptions = {
  axisY: {
    offset: offset,
  },
  axisX: {
    display: true,
    onlyInteger: true,
    offset: 0
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false
        }
    }]
  },
  chartPadding: {
    top: 0,
    right: 20,
    bottom: 0,
    left: 10
  },
  reverseData: false
};

export const barsOptionsPrint = {
  axisY: {
    offset: 10,
  },
  axisX: {
    display: true,
    onlyInteger: true,
    offset: 0
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false
        }
    }]
  },
  chartPadding: {
    top: 0,
    right: 20,
    bottom: 0,
    left: 10
  },
  reverseData: false
}; 

export const barsOptionsReverse = {
    ...barsOptions,
    reverseData:true
};

export const barsOptionsReversePrint = {
  ...barsOptionsPrint,
  reverseData:true
};

function getPaddingLeft(){
  return ((window.innerWidth) / 23) 
}
function getPaddingRigth(){
  return ((window.innerWidth) / 95) 
}

const getLocalizedKValues = (value, locale) => {
  if (typeof value === "undefined")
    return "0"
  const v =  value < 1000 ? value : (value/1000).toFixed(1);
  return Number(v).toLocaleString(locale);
}

export const seriesOptions = {
  chartPadding: {
    top: 20,
    right: -getPaddingRigth(),
    bottom: 10,
    left: getPaddingLeft()
  },
  transform:{
    labels: getLocalizedKValues
  },
  suffix: "k"
};

export const seriesOptionsPrint = {
  chartPadding: {
    top: 20,
    right: 0,
    bottom: 10,
    left: 50
  },
  transform:{
    labels: getLocalizedKValues
  },
  suffix: "k"
};

export const reverseShareOptions = {
  ...barsOptionsReverse,
  transform:{
    labels: (v, l) => Number(v).toFixed(1).toLocaleString(l)
  },
  suffix: " %"
}
export const reverseShareOptionsPrint = {
  ...barsOptionsReversePrint,
  transform:{
    labels: (v, l) => Number(v).toFixed(1).toLocaleString(l)
  },
  suffix: " %"
}
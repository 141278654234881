import React, {useEffect, useState, useContext} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { defaultSalaryWidgetData } from './DefaultSalaryWidgetData'
import getSymbolFromCurrency from 'currency-symbol-map'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import LanguageContext from "../../utils/context/i18n/languageContext";
import { langMap } from "./i18n";

const SalaryWidget = (
  {
    endpoint,
    datasource,
    requestHandler,
    filter,
    profileId,
    solutionId, 
    title,
    maxLabel,
    minLabel,
    medianLabel,
    disableRls = false,
    className, 
    locale = navigator.language, 
    country
  }) => {
    
    const {lang} = useContext(LanguageContext);
    const [data, setData] = useState(defaultSalaryWidgetData);
    const [error, setError] = useState("");
    
    useEffect(() => {
      requestHandler(endpoint, datasource, filter, profileId, solutionId, setData, setError, disableRls);
    }, [endpoint, datasource, requestHandler, filter, profileId, solutionId, disableRls]);

    let currencySymbol = "";
    let median = "";
    let min = "";
    let max = "";  
    let avarage = "";  
    
    if (error){
      median = "Service unavailable";
    } else {
      currencySymbol = getSymbolFromCurrency(data.currency);
      median = `${Math.round(data.salary_50).toLocaleString(locale)}  ${currencySymbol}`;
      min = `${Math.round(data.min_salary).toLocaleString(locale)}  ${currencySymbol}`;
      max = `${Math.round(data.max_salary).toLocaleString(locale)}  ${currencySymbol}`;
      avarage = `${Math.round(data.salary_50_total).toLocaleString(locale)}  ${currencySymbol}`;
    }

    return (
      <Card border="light" className={`${className}`}>
        <Card.Body className="salary-widget-field">
          <Row className="vertical-salary-widget">
            <Col xs={12} className="p-0">
              <div className="d-block">
                <div className="section-min">
                  <b className="mt-1 mb-2 title-section-min">{maxLabel}</b>
                  <p className="mt-1 vertical-salary-price">{max}</p>
                </div>
                <div className="section-median">
                  <b className="mt-1 mb-2 title-section-median">{medianLabel}</b>
                  <h4 className="mt-1 vertical-salary-median">{median}</h4>
                </div>
                <div className="section-min">
                  <b className="mt-1 mb-2 title-section-min">{minLabel}</b>
                  <p className="mt-1 vertical-salary-price">{min}</p>
                </div>
              </div>
              <hr/>
              <Row>
                <Col xs={12} className="level-avarage m-auto">
                  <Row className="widget-avarage m-auto">
                    <Col xs={12} className="text-secondary title-avarage">
                      {langMap.MedianWidgetMedianCountryOfText[lang]}{country}
                    </Col>
                    <Col xs={12} className="slot-avarage">
                      <Row className="avarageandicon-slot">
                        <Col className="m-auto text-left pt-1 pb-1 icon-avarage">
                          <FontAwesomeIcon icon={faGlobe} className="text-white icon-global"/>
                        </Col>
                        <Col xs={9} className="value-avarage mt-1 mb-1">
                          <h4 className="h-100">{avarage}</h4>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {error ? "Service unavailable" : 
            <div className="horizontal-salary-widget">
              <Col xs={12} className="pt-3">
                <div className="salary-median">
                  <p className="salary-section-median">{medianLabel}</p>
                  <p className="mb-0 salary-median-price">{median}</p>
                </div>
              </Col>
              <Row>
                <Col xs={1} className="salary-widget-section-external left"></Col>
                <Col xs={2} className="salary-widget-section-middle left"></Col>
                <Col xs={3} className="salary-widget-section-internal left"></Col>
                <Col xs={3} className="salary-widget-section-internal right"></Col>
                <Col xs={2} className="salary-widget-section-middle right"></Col>
                <Col xs={1} className="salary-widget-section-external right"></Col>
              </Row>
              <Row>
                <Col xs={1} className="salary-widget-section-up left"></Col>
                <Col xs={2} className="salary-widget-section-up right"></Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
                <Col xs={2} className="salary-widget-section-up left"></Col>
                <Col xs={1} className="salary-widget-section-up right"></Col>
              </Row>
              <Row>
                <Col xs={6} className="p-0">
                  <div className="salary-min">
                    <p className="salary-section pt-1 pb-1">{minLabel}</p>
                    <p className="salary-price pt-1 pb-1">{min}</p>
                  </div>
                </Col>
                <Col xs={6} className="p-0">
                  <div className="salary-max">
                    <p className="salary-section pt-1 pb-1">{maxLabel}</p>
                    <p className="salary-price pt-1 pb-1">{max}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="level-avarage m-auto">
                  <Row className="widget-avarage m-auto">
                    <Col xs={12} className="text-secondary title-avarage">
                      {langMap.MedianWidgetMedianCountryInText[lang]}{country}
                    </Col>
                    <Col xs={12} className="slot-avarage">
                      <Row>
                        <Col className="m-auto text-left pt-1 pb-1">
                          <FontAwesomeIcon icon={faGlobe} className="text-white icon-avarage"/>
                        </Col>
                        <Col xs={9} className="value-avarage mt-1 mb-1">
                          <h4 className="h-100">{avarage}</h4>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
        </Card.Body>
      </Card>
    );
};
  
export default SalaryWidget;
import React from "react";
import GeneralButton from "../GeneralButton/GeneralButton";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { AppRoutes } from "../../config/routes";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

const RestrictedContentCover = (
  {
    imgPath,
    label,
    altImage = "",
    className = "",
    classImage = ""
  }) => {

  
  return (
    <Col sm className={`restricted-content-cover ${className}`}>
        <Image alt={altImage} src={imgPath} className={classImage}/>
        <GeneralButton
            label = {label}
            className = "restricted-content-btn my-5"
            buttonIcon={faUnlockAlt}
            destination={AppRoutes.Registration.path}
        />
    </Col>
  );
};

export default RestrictedContentCover;
import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';


const GeneralButton = (
  {
    label ,
    className = "",
    iconClassName = "",
    buttonIcon = null,
    destination = "",
    clickFunction = null
  }) => {


  return (

    <Button variant="secondary-lc" as={Link} to={destination} onClick={clickFunction} className={` ${className} `}>
      {`${label} `}<FontAwesomeIcon icon={buttonIcon} className={`d-none d-sm-inline ${iconClassName}`} />
    </Button>
  
  );
};

export default GeneralButton;
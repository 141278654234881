export const langMap = {
    "ConditionsError": {
        "IT": "Si prega di accettare i termini e le condizioni per procedere",
        "EN": "Please accepts the terms and conditions to proceed",
        "FR": "Veuillez accepter les termes et conditions pour continuer",
        "ES": "Por favor acepta los términos y condiciones para proceder",
        "DE": "Bitte akzeptieren Sie Bedingungen um fortzufahren"
    },
    "EmailInstructionsResponse": {
        "IT": "È stata inviata un'e-mail contenente istruzioni su come completare il processo di registrazione",
        "EN": "An email containing instructions on how to complete the registration process has been sent to",
        "FR": "Un e-mail contenant des instructions sur la façon de terminer le processus d'enregistrement a été envoyé à",
        "ES": "Se ha enviado un correo electrónico que contiene instrucciones sobre cómo completar el proceso de registro",
        "DE": "Eine E -Mail mit Anweisungen zum Abschluss des Registrierungsprozesses wurde an gesendet"
    },
    "EmailInstructionsResponseDE": {
        "IT": "",
        "EN": "",
        "FR": "",
        "ES": "",
        "DE": " gesendet"
    },
    "SendingRequestText": {
        "IT": "invio della richiesta...",
        "EN": "sending request...",
        "FR": "envoyer une requête...",
        "ES": "enviando pedido...",
        "DE": "anfrage wird versendet..."
    },
    "BackButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "Retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "ImageTitle": {
        "IT": "Registrati",
        "EN": "Register",
        "FR": "S'inscrire",
        "ES": "Registrate",
        "DE": "Registrieren"
    },
    "ThankText": {
        "IT": "Grazie!",
        "EN": "Thank You!",
        "FR": "Merci !",
        "ES": "¡Gracias!",
        "DE": "Danke!"
    },
    "AlreadyAccountText": {
        "IT": "Hai già un account?",
        "EN": "Do you already have an account?",
        "FR": "Avez-vous déjà un compte ?",
        "ES": "Ya tienes una cuenta?",
        "DE": "Haben Sie bereits ein Konto?"
    },
    "InfoTitle": {
        "IT": "Informazioni",
        "EN": "Info",
        "FR": "Info",
        "ES": "Información",
        "DE": "Persönliche Informationen"
    },
    "FirstNameTitle": {
        "IT": "Il tuo nome",
        "EN": "Your first name",
        "FR": "Votre prénom",
        "ES": "Tu nombre",
        "DE": "Ihr Vorname"
    },
    "FirstNameText": {
        "IT": "Nome",
        "EN": "First name",
        "FR": "Prénom",
        "ES": "Primer nombre",
        "DE": "Vorname"
    },
    "CountryTitle": {
        "IT": "Il tuo paese",
        "EN": "Your country",
        "FR": "Votre pays",
        "ES": "Tu país",
        "DE": "Ihr Land"
    },
    "CountryText": {
        "IT": "Paese",
        "EN": "Country",
        "FR": "Pays",
        "ES": "País",
        "DE": "Land"
    },
    "OrganizationTitle": {
        "IT": "Nome della tua organizzazione",
        "EN": "Your organisation name",
        "FR": "Nom de votre organisation",
        "ES": "Nombre de tu organización",
        "DE": "Name Ihrer Organisation"
    },
    "OrganizationText": {
        "IT": "Nome organizzazione/azienda",
        "EN": "Organization/company name",
        "FR": "Nom de l'organisation / de l'entreprise",
        "ES": "Nombre de la organización/empresa",
        "DE": "Name der Organisation"
    },
    "LastNameTitle": {
        "IT": "Il vostro cognome",
        "EN": "Your last name",
        "FR": "Votre nom de famille",
        "ES": "Tu apellido",
        "DE": "Ihr Nachname"
    },
    "LastNameText": {
        "IT": "Cognome",
        "EN": "Last name",
        "FR": "Nom de famille",
        "ES": "Apellido",
        "DE": "Nachname"
    },
    "JobTitle": {
        "IT": "Il tuo titolo di lavoro",
        "EN": "Your job title",
        "FR": "Votre intitulé de poste",
        "ES": "Tu puesto de trabajo",
        "DE": "Ihre Berufsbezeichnung"
    },
    "JobText": {
        "IT": "Titolo di lavoro",
        "EN": "Job title",
        "FR": "Titre d'emploi",
        "ES": "Título de tu puesto de trabajo",
        "DE": "Berufsbezeichnung"
    },
    "CredentialsTitle": {
        "IT": "Credenziali",
        "EN": "Credentials",
        "FR": "Identifiants",
        "ES": "Credenciales",
        "DE": "Zugangsdaten"
    },
    "PasswordTitle": {
        "IT": "La tua password",
        "EN": "Your Password",
        "FR": "Votre mot de passe",
        "ES": "Tu contraseña",
        "DE": "Ihr Passwort"
    },
    "PasswordText": {
        "IT": "Password",
        "EN": "Password",
        "FR": "Mot de passe",
        "ES": "Clave",
        "DE": "Passwort"
    },
    "EmailTitle": {
        "IT": "La tua email",
        "EN": "Your Email",
        "FR": "Votre e-mail",
        "ES": "Tu correo electrónico",
        "DE": "Ihre Email"
    },
    "EmailText": {
        "IT": "esempio@company.com",
        "EN": "example@company.com",
        "FR": "exemple@company.com",
        "ES": "ejemplo@company.com",
        "DE": "beispiel@company.com"
    },
    "ConfirmPasswordTitle": {
        "IT": "Conferma password",
        "EN": "Confirm Password",
        "FR": "Confirmez le mot de passe",
        "ES": "Confirmar contraseña",
        "DE": "Passwort bestätigen"
    },
    "ConfirmPasswordText": {
        "IT": "Conferma password",
        "EN": "Confirm Password",
        "FR": "Confirmez le mot de passe",
        "ES": "Confirmar contraseña",
        "DE": "Passwort bestätigen"
    },
    "AgreePrivacyText": {
        "IT": "Accetto l'informativa sulla",
        "EN": "I agree to the",
        "FR": "J'accepte la politique de",
        "ES": "Acepto la política de",
        "DE": "Ich akzeptiere die"
    },
    "AgreePrivacyLink": {
        "IT": "privacy",
        "EN": "privacy policy",
        "FR": "confidentialité",
        "ES": "privacidad",
        "DE": "Datenschutzbestimmungen"
    },
    "AgreeTermsText": {
        "IT": "Sono d'accordo con ",
        "EN": "I agree to the",
        "FR": "Je suis d'accord avec le",
        "ES": "Estoy de acuerdo con los",
        "DE": "Ich stimme den"
    },
    "AgreeTermsLink": {
        "IT": "termini di utilizzo",
        "EN": "terms of use",
        "FR": "conditions d'usage",
        "ES": "condiciones de uso",
        "DE": "nutzungsbedingungen"
    },
    "RegistrationButton": {
        "IT": "Registrati",
        "EN": "Register",
        "FR": "S'inscrire",
        "ES": "Registrate",
        "DE": "Registrieren"
    },
    "AlreadyAccountTextSub": {
        "IT": "Hai già un account?",
        "EN": "Already have an account?",
        "FR": "Vous avez déjà un compte?",
        "ES": "¿Ya tienes una cuenta?",
        "DE": "Sie haben bereits ein Konto?"
    },
    "AlreadyAccountLinkSub": {
        "IT": "Accedi qui",
        "EN": "Login here",
        "FR": "Connectez-vous ici",
        "ES": "Acceder aquí",
        "DE": "Hier anmelden"
    }
}
import React, { useContext } from "react"
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from 'react-router-dom';
import { AppRoutes } from "../../../config/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { langMap } from "../SignIn/i18n";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import { useEffect } from "react";



const TermsAndConditions = () => {

    const lang = useContext(LanguageContext).lang;

    useEffect(() => {
        let tooltip = document.getElementsByClassName("chartist-tooltip");
        if(tooltip.length){
          document.querySelector(".chartist-tooltip").remove();
        }
    }, []);    


    return(

        <main className="main-tabledpage">
            <section className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
                <Header/>
            </section>
            <section className="row-tabledpage section-body">
                <div className="single-tabledpage pb-5 pt-5">
                    <p className="text-center">
                    <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                        <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> {langMap.BackButton[lang]}
                    </Card.Link>
                    </p>
                    <Col xs={6} className="body-termsandcond">
                        <h3 className="fw-bolder w-100 text-center title-termsandconditions text-secondary-lc py-2 mt-2">
                            LIGHTCAST EUROPEAN INSIGHTS TERMS OF USE
                        </h3>
                        <div className="m-6 mt-5 mb-4 content-termsandconditions">
                            <p className="text-center">
                                By accessing the Lightcast European Insights dataset and API (“<b>Dataset</b>” and, 
                                together with the API, the “<b>Lightcast Materials</b>”), you agree to the following 
                                terms (these “<b>Terms</b>”) between you and Economic Modeling, LLC (“Lightcast”). 
                                Lightcast will not revoke this license, or your access to the API and Dataset, 
                                provided you abide by these terms. As used herein, the term “you” or “your” 
                                refers to an individual, representing yourself or, if applicable, acting as legal 
                                representative for a group, business entity or corporation
                            </p>
                            <div className="main-point mt-5">
                                <div className="main-point-number"><b>1</b></div>
                                <h5 className="mb-0 text-center main-point-text">License</h5>
                            </div>
                            <div className="mt-3 sub-point">
                                <p className="m-2 mt-1 mb-5">
                                    You acknowledge that as between you and Lightcast, Lightcast owns all right, title, 
                                    and interest in and to the Lightcast Materials. Subject to your compliance with these 
                                    Terms, Lightcast grants you a limited, personal, royalty-free, fully paid-up, perpetual, 
                                    non-transferable, non-sublicensable, and worldwide right and license to use the Dataset 
                                    solely as provided in these Terms.
                                </p>
                            </div>
                            <div className="main-point mt-3">
                                <div className="main-point-number"><b>2</b></div>
                                <h5 className="mb-0 text-center main-point-text">What You <u>Can</u> Do</h5>
                            </div>
                            <div className="mt-4 sub-point">
                                <div className="sub-point-number"><b>2.1</b></div>
                                <p className="m-2 mt-1">
                                    You may copy and redistribute all or part of the Dataset in any medium or format, 
                                    and for any purpose – excluding commercial or for-profit purposes.
                                </p>
                            </div>
                            <div className="mt-2 sub-point">
                                <div className="sub-point-number"><b>2.2</b></div>
                                <p className="m-2 mt-1">
                                    You may transform and build upon the Dataset for any purpose – excluding
                                    commercial or for-profit purposes.
                                </p>
                            </div>
                            <div className="mt-2 sub-point">
                                <div className="sub-point-number"><b>2.3</b></div>
                                <p className="m-2 mt-1">
                                    Commercial or for-profit purposes may be permitted provided the user
                                    contacts Lightcast and enters into a no-cost contract setting forth
                                    the agreed intended use and any intended transformations and builds
                                    (derivatives) upon the Dataset. Lightcast reserves the right to deny
                                    any use of the Lightcast Materials at its sole discretion.
                                </p>
                                <p className="m-2 mt-5  mb-5">
                                    (each, a “<b>Permitted Use</b>”)
                                </p>
                            </div>
                            <div className="main-point mt-3">
                                <div className="main-point-number"><b>3</b></div>
                                <h5 className="mb-0 text-center main-point-text">What You <u>Can’t</u> Do</h5>
                            </div>
                            <div className="mt-4 sub-point">
                                <div className="sub-point-number"><b>3.1</b></div>
                                <p className="m-2 mt-1">
                                    You can’t use the Lightcast Materials except as expressly stated in Section 2.
                                </p>
                            </div>
                            <div className="mt-4 sub-point">
                                <div className="sub-point-number"><b>3.2</b></div>
                                <p className="m-2 mt-1">
                                    You can’t redistribute all or any part of the Dataset as your own work
                                </p>
                                <p className="m-2 mt-5">
                                    (each, a “<b>Permitted Use</b>”)
                                </p>
                            </div>
                        </div>
                    </Col>
                </div>
            </section>
            <Footer className="w-100" />
        </main>
    )
}

export default TermsAndConditions
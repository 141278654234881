import React, {useContext} from "react"
import Header from "../../Header/Header";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import { langMap } from "./i18n";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft} from "@fortawesome/free-solid-svg-icons"
import { AppRoutes } from "../../../config/routes";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import NotFoundImage from "../../../assets/illustrations/thank_you.png"

const CompletedBooking = () => {

    const { lang } = useContext(LanguageContext); 

    return(
        <main>
            <section className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
                <Header/>
            </section>
            <section className="d-flex align-items-center mt-5 mt-lg-6 mb-lg-5">
            <Container>
                    <p className="text-center">
                        <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> {langMap.BackToHomepageButtonText[lang]}
                        </Card.Link>
                    </p>
                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                        <div className="text-center text-md-center mb-4 mt-md-0">
                            <h1 className="mb-0 text-success text-secondary-lc">
                                {langMap.BookADemoSuccessTitle[lang]}
                            </h1>
                            <h4 className="secondary-text-success pt-3">
                                {langMap.BookADemoSuccessText[lang]}<br/> 
                            </h4>  
                        </div>
                    </Col>
                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                        <Image src={NotFoundImage} alt="not-found-image" className="img-fluid w-50 m-auto" />
                    </Col>
                </Container>
            </section>
        </main>
    )

};

export default CompletedBooking;

import React, {useContext, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faAngleLeft, faEnvelope, faUnlockAlt, faBriefcase, faBuilding, faEarthEurope} from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from 'react-router-dom';
import CompanyLogoFull from "../../../assets/img/Lighcast_RGB_Lockup_Color_full.png"
import { AppRoutes } from "../../../config/routes";
import EmailSentImage from "../../../assets/img/Email_sent.png";
import { Endpoints } from "../../../config/api";
import gifLoading from "../../../assets/gif/Lightcast_LoadingGif_White.gif"
import Header from "../../Header/Header";
import { langMap } from "./i18n";
import PasswordPopup from "../../PasswordPopup/PasswordPopup";
import { ROOT_WEBAPP } from "../../../config/env";

import LanguageContext from "../../../utils/context/i18n/languageContext";
import { errorMessages } from "../../../config/errors";
import { noAuthRequest } from "../../../utils/REST/requests";
import { useEffect } from "react";


const Registration = () => {

  const lang = useContext(LanguageContext).lang;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [homeCountry, setHomeCountry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    let tooltip = document.getElementsByClassName("chartist-tooltip");
    if(tooltip.length){
      document.querySelector(".chartist-tooltip").remove();
    }
  }, []);


  const onDismiss = () => {
    setResponse(false);
    setError(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!termsAccepted || !privacyAccepted) {
      setError(langMap.ConditionsError[lang]);
      return;
    }
   
    setSendingEmail(true);

    const res = await noAuthRequest("POST", Endpoints.REGISTRATION, {email, firstName, lastName, jobTitle, organizationName, homeCountry, password, check, concurrentAccess: false, origin: ROOT_WEBAPP});
    if (res.data?.accepted) {
      setResponse(`${langMap.EmailInstructionsResponse[lang]} ${email}`);
      setError("");
    }
    else {
      setResponse("");
      if (res.error){
        setTermsAccepted(false);
        setPrivacyAccepted(false);
        setError(res.error);
      }
      else
        navigate(AppRoutes.ServerError.path);
    };
    setSendingEmail(false);
  };


  return (
    <main className="background-subscribe">
      <section className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
        <Header/>
      </section>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 h-100">
        {
          sendingEmail ?
          <Col xs={12} className="d-flex align-items-center h-100 justify-content-center">
            <div className="text-center text-md-center mb-10 mt-7 md-0">
              <div className="loading-logo-bg">
                <Image className="loading-logo" alt="loading-logo-gif" src={gifLoading} />
              </div>
              <h3 className="mb-0 text-checking text-secondary-lc ">{langMap.SendingRequestText[lang]}</h3>
            </div>
          </Col>
          :
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" />{langMap.BackButtonText[lang]}
              </Card.Link>
            </p>
            {
              response ?
              <Row className="justify-content-center form-bg-image">
                <Col sm={12} className="d-flex align-items-center justify-content-center">
                  <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-1000">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <Image src={CompanyLogoFull} width={500} alt="Lightcast Logo" />
                      <h4 className="mb-0 title-create-account">{langMap.ImageTitle[lang]}</h4>
                    </div>
                    <h2 className="mail-sent-title text-center">{langMap.ThankText[lang]}</h2>
                    <hr className="hr-mail-sent"></hr>
                    <h6 className="text-center mail-sent-text mt-2 mb-4">
                      {response}
                    </h6>
                    <div className="text-center w-100 mb-5 mt-2">
                      <Image src={EmailSentImage} width={450} alt="Lightcast Logo" />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Card.Link as={Link} to={AppRoutes.Presentation.path}>
                        <Button variant="primary" type="submit">
                          {langMap.BackButtonText[lang]}
                        </Button>
                      </Card.Link>
                    </div>
                  </div>
                </Col>
              </Row>
              :
              <Row className="justify-content-center form-bg-image">
                <Col sm={12} className="d-flex align-items-center justify-content-center">
                  <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-1000">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <Image src={CompanyLogoFull} width={500} alt="Lightcast Logo" />
                      <h4 className="mb-0 title-create-account">{langMap.ImageTitle[lang]}</h4>
                    </div>
                    <p className="text-center">
                      <Card.Link as={Link} to={AppRoutes.Signin.path} className="text-secondary-lc">
                        <b>{langMap.AlreadyAccountText[lang]}</b>
                      </Card.Link>
                    </p>
                    <Form className="mt-5" onSubmit={handleSubmit}>
                      <Col sm className="collumns-input mb-3">
                          <h5 className="m-auto text-secondary-lc">{langMap.InfoTitle[lang]}</h5>
                          <hr className="hr-input-category m-auto mx-2 text-secondary-lc"></hr>
                      </Col>
                      <Row className="collumns-input">
                        <Col sm className="collumn-input">
                          <Form.Group id="firstName" className="mb-4">
                          <Form.Label className="required-field-title">{langMap.FirstNameTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUserCircle} />
                              </InputGroup.Text>
                              <Form.Control tabIndex="1" autoComplete="new-name" required type="text" placeholder={langMap.FirstNameText[lang]} onChange={({ target }) => setFirstName(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group id="homeCountry" className="mb-4">
                          <Form.Label className="required-field-title">{langMap.CountryTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEarthEurope} />
                              </InputGroup.Text>
                              <Form.Control required tabIndex="3" autoComplete="new-country" type="text" placeholder={langMap.CountryText[lang]} onChange={({ target }) => setHomeCountry(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group id="organizatioName" className="mb-4">
                          <Form.Label className="required-field-title">{langMap.OrganizationTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faBuilding} />
                              </InputGroup.Text>
                              <Form.Control required tabIndex="5" autoComplete="new-orgnization" type="text" placeholder={langMap.OrganizationText[lang]} onChange={({ target }) => setOrganizationName(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm className="collumn-input">
                          <Form.Group id="lastName" className="mb-4">
                          <Form.Label className="required-field-title">{langMap.LastNameTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUserCircle} />
                              </InputGroup.Text>
                              <Form.Control tabIndex="2" required type="text" autoComplete="new-last-name" placeholder={langMap.LastNameText[lang]} onChange={({ target }) => setLastName(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group id="jobTitle" className="mb-4">
                          <Form.Label className="required-field-title">{langMap.JobTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faBriefcase} />
                              </InputGroup.Text>
                              <Form.Control required autoComplete="new-job" tabIndex="4" type="text" placeholder={langMap.JobText[lang]} onChange={({ target }) => setJobTitle(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Col sm className="collumns-input mb-3">
                        <h5 className="m-auto text-secondary-lc">{langMap.CredentialsTitle[lang]}</h5>
                        <hr className="hr-input-category m-auto mx-2 text-secondary-lc"></hr>
                      </Col>
                      <Row className="collumns-input">
                        <Col sm className="collumn-input">
                          <Form.Group id="email" className="mb-4">
                            <Form.Label className="required-field-title">{langMap.EmailTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope} />
                              </InputGroup.Text>
                              <Form.Control autoComplete="new-email" tabIndex="6" autoFocus required type="email" placeholder={langMap.EmailText[lang]} onChange={({ target }) => setEmail(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group id="password" className="mb-4">
                            <Form.Label className="required-field-title">
                              {langMap.PasswordTitle[lang]}
                              <p className="mb-0 mx-1 text-secondary-lc">*</p>
                              <div style={{marginTop: "-2px"}}><PasswordPopup /></div>
                            </Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </InputGroup.Text>
                              <Form.Control tabIndex="7" autoComplete="new-password" required type="password" placeholder={langMap.PasswordText[lang]} onChange={({ target }) => setPassword(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group id="confirmPassword" className="mb-4">
                            <Form.Label className="required-field-title">{langMap.ConfirmPasswordTitle[lang]}<p className="mb-0 mx-1 text-secondary-lc">*</p></Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </InputGroup.Text>
                              <Form.Control tabIndex="8" required type="password" autoComplete="new-password" placeholder={langMap.ConfirmPasswordText[lang]} onChange={({ target }) => setCheck(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm className="collumn-input" />
                      </Row>
                      {
                        error &&
                        
                        <Col xs={12} xl={12}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <Alert variant="danger" dismissible={true} onClose={() => onDismiss()}>
                                  {error.split(", ").map((err, i)=> <p key={"err" + i} className="error-label">{errorMessages[err][lang]}</p>)}
                                </Alert>
                            </div>
                        </Col>
                      }
                      <FormCheck type="checkbox" className="d-flex justify-content-center align-items-center mb-2 mt-5">
                        <FormCheck.Input tabIndex="9" required id="terms" className="me-2" onClick={() => setTermsAccepted(prev => !prev)}/>
                        <FormCheck.Label htmlFor="terms">
                          {langMap.AgreeTermsText[lang]} <Card.Link href={AppRoutes.TermsAndConditions.path} target="_blank">{langMap.AgreeTermsLink[lang]}</Card.Link>
                        </FormCheck.Label>
                      </FormCheck>
                      <FormCheck type="checkbox" className="d-flex justify-content-center align-items-center mb-3 mt-3">
                        <FormCheck.Input tabIndex="10" required id="privacy" className="me-2" onClick={() => setPrivacyAccepted(prev => !prev)}/>
                        <FormCheck.Label htmlFor="privacy">
                          {langMap.AgreePrivacyText[lang]} <Card.Link href="https://lightcast.io/privacy-policy" target="_blank">{langMap.AgreePrivacyLink[lang]}</Card.Link>
                        </FormCheck.Label>
                      </FormCheck>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button tabIndex="10" variant="primary" type="submit" className="w-50">
                          {langMap.RegistrationButton[lang]}
                        </Button>
                      </div>
                    </Form>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <span className="fw-normal">
                        {langMap.AlreadyAccountTextSub[lang]} <Card.Link as={Link} to={AppRoutes.Signin.path} className="fw-bold">{langMap.AlreadyAccountLinkSub[lang]}</Card.Link>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        } 
      </section>
    </main>
  );
};

export default Registration;
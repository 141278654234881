import React, { useContext, useRef} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { currentYear } from "../../../config/dates";
import { Endpoints } from "../../../config/api";
import AccessRestrictedWidget from "../../AccessRestrictedWidget/AccessRestrictedWidget";
import { SeriesValueWidget } from "@bge/global_solution_workbench_commons";
import { GeneralSelector } from "@bge/global_solution_workbench_commons";
import char1 from "../../../assets/img/char1.png"
import char2 from "../../../assets/img/char2.png"
import LanguageContext from "../../../utils/context/i18n/languageContext";
import {availableCountries, initialCountryObject} from "../../../config/widgets/selectors/availableCountries";
import {getAvailableOccupations, initialOccupationObject} from "../../../config/widgets/selectors/availableOccupationsByCountry";
import { dataRequest } from "../../../utils/REST/requests";
import { chartClasses, seriesOptions, seriesOptionsPrint } from "../../../config/charts/chartOptions";
import FiltersContext from "../../../utils/context/filters/filtersContext";
import { langMap } from "../Presentation/i18n";
import { I18N_ENABLED } from "../../../config/env";
import { ROOT_WEBAPP } from "../../../config/env";
import { AppRoutes } from "../../../config/routes";

function isCountrySelected(currentCountry){
  return currentCountry.code !== initialCountryObject.code;
}

function isOccupationSelected(currentOccupation){
  return currentOccupation.code !== initialOccupationObject.code;
}

function setLow(valuesSeries){
  const min = Math.min(...valuesSeries.map(s => Math.min(...s)));
  const res = min * 0.9;
  return Math.round(res);
}

const objectDeepCopy = (objectToCopy) => {
  if(typeof objectToCopy !== "object"){
    return objectToCopy;
  }
  let objectCopy = Array.isArray(objectToCopy) ? [] : {};

  for(let key in objectToCopy){
    const value = objectToCopy[key];
    objectCopy[key] = objectDeepCopy(value);
  }
  return objectCopy;
};

let seriesOptionsCopy = objectDeepCopy(seriesOptions)
let seriesOptionsPrintCopy = objectDeepCopy(seriesOptionsPrint)

const EmbeddableView = () => {

  const componentRef = useRef();
  const { lang } = useContext(LanguageContext);
  const filtersContext = useContext(FiltersContext);

  const currentCountry = filtersContext.selectedCountry;
  const currentOccupation = filtersContext.selectedOccupation;
  const displayedOccupations = filtersContext.displayedOccupations;

  const filter = {occupationCode: currentOccupation.code, countryCode: currentCountry.code, language: I18N_ENABLED === "true" ? lang : "EN"};

  const resetOccupation = async (info) => {
    const newOccs = await getAvailableOccupations(lang, info.code);
    filtersContext.changeCountry({country: info, displayedOccupations: newOccs});
  };

  const changeOccupation = (info) => {
    filtersContext.changeOccupation({country: currentCountry, occupation: info, displayedOccupations: displayedOccupations});
  }


  return (
    <main className="main-tabledpage">
      <section className="row-tabledpage section-body" id="content-body" ref={componentRef}>
        <Container className="m-0 p-0 w-100 content-presentation">
          <Row className="mt-5">
            <Col xs={12} className="text-center container">
              <p className="title-embedded-page">Get a Snapshot of Our Data</p>
              <p className="subtitle-embedded-page m-auto pb-4">
                Pick a country. Choose one of its Top 10 occupations. Begin your Lightcast European Workforce Analytics journey.
              </p>
            </Col>
          </Row>
          <Row>
            <Container className="w-100 single-tabledpage">
              <Row className="selectorsField mb-4 mt-4 p-1">
                <Col sm={6} className="text-center my-2 selection-title">
                  <GeneralSelector
                    current={currentCountry.name}
                    passedItems={availableCountries}
                    clickHandler={resetOccupation}
                    classSelected="country-selector"
                  />
                </Col>
                <Col sm={6} className="text-center my-2">
                    <GeneralSelector
                      current={currentOccupation.name}
                      passedItems={displayedOccupations}
                      clickHandler={changeOccupation}
                      key={currentCountry.code}
                      classSelected="occupation-selector"
                      disabled={!isCountrySelected(currentCountry)}
                  />
                </Col>
              </Row>

              { isOccupationSelected(currentOccupation) ? 
                <>
                  <div className="pb-5">
                  {/* { isCountrySelected(currentCountryInfo) ?  */}
                    <Row className="vertical-chars-card">
                      <Col xs={12}  className={`${chartClasses} widget-center`}>
                        <AccessRestrictedWidget
                          restricted = {false}
                          whenAccessible = {
                            <SeriesValueWidget
                              endpoint={`${Endpoints.DATA}/job_posting_trend`}
                              requestHandler={dataRequest}
                              filter={filter}
                              startYear={currentYear}
                              title = {langMap.SeriesWidgetHiringTitle[lang]}
                              subtitle = {langMap.SeriesWidgetHiringSubtitle[lang]}
                              chartClassName="jobposting-char ct-series-a ct-double-octave"
                              options={seriesOptionsCopy}
                              printOptions={seriesOptionsPrintCopy}
                              showTitle={false}
                              showSubtitle={false}
                              setLowAttribute={setLow}
                              printWidget={true}
                            />
                          }
                          title = {langMap.SeriesWidgetHiringTitle[lang]}
                          subtitle = {langMap.SeriesWidgetHiringSubtitle[lang]}
                          className = "chars-card embedded-chars h-100"
                        />
                      </Col>
                    </Row>
                    <Row className="vertical-chars-card">
                      <Col xs={12}  className={`${chartClasses} widget-center`}>
                        <AccessRestrictedWidget
                            whenAccessible = {
                              <SeriesValueWidget
                                endpoint={`${Endpoints.DATA}/lmi_trend`}
                                requestHandler={dataRequest}
                                filter={filter}
                                startYear={currentYear}
                                title = {langMap.SeriesWidgetEmploymentTitle[lang]}
                                subtitle = {langMap.SeriesWidgetEmploymentSubtitle[lang]}
                                chartClassName="lmitrend-char ct-series-a ct-double-octave"
                                options={seriesOptions}
                                printOptions={seriesOptionsPrint}
                                showTitle={false}
                                showSubtitle={false}
                                monthFormat={false}
                                setLowAttribute={setLow}
                                printWidget={true}
                              />
                            }
                            title = {langMap.SeriesWidgetEmploymentTitle[lang]}
                            subtitle = {langMap.SeriesWidgetEmploymentSubtitle[lang]}
                            className = "chars-card embedded-chars h-100"
                        />
                      </Col>
                    </Row>
                </div>
                </>
                :
                <Row>
                  <Col xs={12} className="text-center mb-5 p-0">
                    <Row className="text-center w-100 img-graph-collection m-auto">
                      <Col sm={12} className={`${chartClasses} default-chart-container`}>
                        <AccessRestrictedWidget
                          restricted = {true}
                          title = {langMap.SeriesWidgetHiringTitle[lang]}
                          subtitle = {langMap.SeriesWidgetHiringSubtitle[lang]}
                          className = "chars-card h-100 mt-auto"
                          whenNotAccessible = {
                            <Image src={char1} alt="demo-char-embedded-image" className="demo-char-embedded" />
                          }
                        />
                      </Col>
                      <Col sm={12} className={`${chartClasses} default-chart-container`}>
                      <AccessRestrictedWidget
                        restricted = {true}
                        title = {langMap.SeriesWidgetEmploymentTitle[lang]}
                        subtitle = {langMap.SeriesWidgetEmploymentSubtitle[lang]}
                        className = "chars-card h-100 mt-auto"
                        whenNotAccessible = {
                          <Image src={char2} alt="demo-char-embedded-image" className="demo-char-embedded" />
                        }
                      />
                      </Col>
                    </Row>
                  </Col>
                </Row>
            }
            <Row>
              <a href={ROOT_WEBAPP + AppRoutes.Registration.path} target="_blank" rel="noreferrer" className="mainapp-link">
                <Col className="text-center mainapp-link-container">
                  Try out the full demo!
                </Col>
              </a>
            </Row>
            </Container>
            </Row>
        </Container>
      </section>
    </main>
  );
};

export default EmbeddableView;
export const langMap = {
    "MedianWidgetMedianCountryInText": {
        "IT": "Stipendio mediano in ",
        "EN": "Median Salary in ",
        "FR": "Salaire médian dans ",
        "ES": "Salario medio en ",
        "DE": "Mediangehalt in "
    },
    "MedianWidgetMedianCountryOfText": {
        "IT": "Mediana di ",
        "EN": "Median of ",
        "FR": "Médian ",
        "ES": "Mediana de ",
        "DE": "Median von "
    }
}
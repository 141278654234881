import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

const AccessRestrictedWidget = (
  {
    restricted,
    whenAccessible,
    whenNotAccessible,
    title,
    subtitle,
    className = ""
  }) => {

  
  return (
    <Card border="light" className={`access-restricted-widget-bg ${className}`}>
      < Card.Body >
        <Row className="h-100 w-100 m-0 d-flex align-items-center card-table">
          <Col xs={12} className="h-100 px-xl-0 card-row">
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </Col>
          <Col xs={12} className="text-xl-center align-items-center justify-content-xl-center mb-3 mb-xl-0 card-row">
          { restricted ? 
            whenNotAccessible
            : whenAccessible
          }
          </Col>
        </Row>
      </Card.Body >
    </Card >
  );
};

export default AccessRestrictedWidget;
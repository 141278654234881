export const langMap = {
    "PasswordContainTitle": {
        "IT": "La password deve essere e contenere:",
        "EN": "Password must be and contain:",
        "FR": "Le mot de passe doit contenir :",
        "ES": "La contraseña debe ser y contener:",
        "DE": "Passwort muss sein und enthalten:"
    },
    "PasswordContainCharacterText": {
        "IT": "-Almeno lunga 8 caratteri",
        "EN": "-At least 8 character long",
        "FR": "- 8 caractères ",
        "ES": "-Al menos 8 caracteres de largo",
        "DE": "-mindestens 8 Zeichen lang sein"
    },
    "PasswordContainUppercaseText": {
        "IT": "-Almeno 1 carattere maiuscolo e 1 carattere minuscolo",
        "EN": "-At least 1 uppercase and 1 lowercase character",
        "FR": "- 1 lettre majuscule et 1 lettre minuscule",
        "ES": "-Al menos 1 carácter mayúscula y 1 carácter minúsculas",
        "DE": "-mindestens 1 Großbuchstaben und 1 Kleinbuchstaben enthalten"
    },
    "PasswordContainNumberText": {
        "IT": "-Almeno 1 numero",
        "EN": "-At least 1 number",
        "FR": "- 1 chiffre",
        "ES": "-Al menos 1 número",
        "DE": "-mindestens 1 Zahl"
    },
    "PasswordContainSpecialText": {
        "IT": "-Almeno 1 carattere speciale ($, %, & ...)",
        "EN": "-At least 1 special character ( $, % , & ...)",
        "FR": "- 1 caractère spécial ($,%, & ...)",
        "ES": "-Al menos 1 carácter especial ($, %, y ...)",
        "DE": "-mindestens 1 Sonderzeichen"
    },
    "PasswordContainWhitespaceText": {
        "IT": "-No spazi bianchi",
        "EN": "-No whitespaces",
        "FR": "- Pas d'espaces",
        "ES": "-No espacios en blanco",
        "DE": "-keine Leerzeichen"
    }
}
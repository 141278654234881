
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Link } from 'react-router-dom';
import { AppRoutes } from "../../../config/routes";
import ErrorImage from "../../../assets/illustrations/500.svg"
import { useContext } from "react";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import { langMap } from "./i18n";

const ServerError = ({onBackToHome}) => {

  const lang = useContext(LanguageContext).lang

  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={5} className="order-2 order-lg-1 text-center text-lg-left">
              <h1 className="text-secondary-lc mt-5">
                {langMap.ServiceDownTitleLeft[lang]}<span className="fw-bolder">{langMap.ServiceDownTitleCenter[lang]}</span>{langMap.ServiceDownTitleRight[lang]}
               </h1>
              <p className="lead my-4">
                {langMap.ServiceDownText[lang]}
               </p>
              <Button as={Link} variant="primary" className="animate-hover" to={AppRoutes.Presentation.path} onClick= {() => onBackToHome(false)}>
                <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                {langMap.BackHomeButtonText[lang]}
              </Button>
            </Col>
            <Col xs={12} lg={7} className="order-1 order-lg-2 text-center d-flex align-items-center justify-content-center">
              <Image src={ErrorImage} alt="service-down-image" className="img-fluid w-75" />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ServerError;
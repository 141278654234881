export const langMap = {
    "BackButton": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "Retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "TopText": {
        "IT": "Accedi",
        "EN": "Sign In",
        "FR": "S'identifier",
        "ES": "Iniciar sesión",
        "DE": "Einloggen"
    },
    "UsernamePlaceholder": {
        "IT": "esempio@company.com",
        "EN": "example@company.com",
        "FR": "exemple@company.com",
        "ES": "ejemplo@company.com",
        "DE": "beispiel@company.com"
    },
    "UsernameLabel": {
        "IT": "Il tuo nome utente o e-mail",
        "EN": "Your username or email",
        "FR": "Votre nom d'utilisateur ou votre e-mail",
        "ES": "Tu nombre de usuario o correo electrónico",
        "DE": "Ihr Benutzername oder Ihre E-Mail"
    },
    "PasswordLabel": {
        "IT": "La tua password",
        "EN": "Your password",
        "FR": "Votre mot de passe",
        "ES": "Tu contraseña",
        "DE": "Ihr Passwort"
    },
    "PasswordPlaceholder": {
        "IT": "Password",
        "EN": "Password",
        "FR": "Mot de passe",
        "ES": "Clave",
        "DE": "Passwort"
    },
    "RememberButton": {
        "IT": "Ricordati di me",
        "EN": "Remember me",
        "FR": "Se souvenir de moi",
        "ES": "Recuérdame",
        "DE": "Login speichern"
    },
    "SignInButton": {
        "IT": "Accedi",
        "EN": "Sign In",
        "FR": "S'identifier",
        "ES": "Iniciar sesión",
        "DE": "Einloggen"
    },
    "LostPswdButton": {
        "IT": "Hai dimenticato la password?",
        "EN": "Forgot password?",
        "FR": "Mot de passe oublié ?",
        "ES": "¿Se te olvidó tu contraseña?",
        "DE": "Passwort vergessen?"
    }
}
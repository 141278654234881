import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import FormCheck from "react-bootstrap/FormCheck";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useNavigate } from 'react-router-dom';

import CompanyLogoFull from "../../../assets/img/Lighcast_RGB_Lockup_Color_full.png"

import { AppRoutes } from "../../../config/routes";
import {loginRequest, noAuthRequest} from "../../../utils/REST/requests";

import UserContext from "../../../utils/context/user/userContext";
import ServerError from "../ServerError/ServerError";
import {langMap} from "./i18n";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import Header from "../../Header/Header";
import { errorMessages } from "../../../config/errors";
import { Languages } from "../../../config/languages";
import { Endpoints } from "../../../config/api";
import { SOLUTION_ID, SOLUTION_NAME } from "../../../config/env";
import { useEffect } from "react";

const Signin = () => {

  const langContext = useContext(LanguageContext);
  const lang = langContext.lang;
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [unavailable, setUnavailable] = useState(false);

  useEffect(() => {
    let tooltip = document.getElementsByClassName("chartist-tooltip");
    if(tooltip.length){
      document.querySelector(".chartist-tooltip").remove();
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    const data = await loginRequest(username, password, setUnavailable, rememberMe);
    if(data){
      data.error = data.error ? errorMessages[data.error][lang] : "";
      userContext.handleUserLogin(data);
      if (data.user){
        const lastLang = data.user.lastLanguage;
        if(lastLang)
          langContext.changeLang(Languages.find( obj => obj.lang === lastLang));

        const solutionId = SOLUTION_NAME ? data.user.solutions.find( s => s.name === SOLUTION_NAME).id : SOLUTION_ID
        noAuthRequest("POST", Endpoints.LOGGING, {userId: data.user.id, profileId: 0, solutionId})

        navigate(AppRoutes.Presentation.path);
      }
    };
  };

  const onErrorDismiss = () => {
    const data =  {
      user: null,
      error: ""
    }
    userContext.handleUserLogin(data);
  }

  if (unavailable)
    return <ServerError onBackToHome={setUnavailable}/>
  else
    return (
      <main>
        <section className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
          <Header/>
        </section>
        <section className="d-flex align-items-center mb-lg-5 background-signin">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> {langMap.BackButton[lang]}
              </Card.Link>
            </p>
            <Row className="justify-content-center mb-6 form-bg-image">
              <Col xs={12} className="login-field d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-lg-5 pb-lg-4 w-100 fmxw-1000">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                      <Image src={CompanyLogoFull} width={450} className="logo-signin" alt="Lightcast Logo" />
                      <h4 className="mb-0 title-create-account">{langMap.TopText[lang]}</h4>
                  </div>
                  <Form className="mt-4 input-spacing-login" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label className="text-dark-lc">{langMap.UsernameLabel[lang]}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} className="text-secondary-lc" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required placeholder={langMap.UsernamePlaceholder[lang]} onChange={({ target }) => setUsername(target.value)}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4  password-title">
                        <Form.Label className="text-dark-lc">{langMap.PasswordLabel[lang]}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                          </InputGroup.Text>
                          <Form.Control required type="password" placeholder={langMap.PasswordPlaceholder[lang]} onChange={({ target }) => setPassword(target.value)} />
                        </InputGroup>
                      </Form.Group>
                      {
                        userContext.error &&
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Alert variant="danger" dismissible={true} onClose={() => onErrorDismiss(null)}>
                              {userContext.error}
                          </Alert>
                        </div>
                      }
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox" >
                          <FormCheck.Input id="defaultCheck5" defaultChecked={true} className="me-2" onClick={() => setRememberMe(prev => !prev)} />
                          <FormCheck.Label htmlFor="defaultCheck5" className="mb-0 text-dark-lc">{langMap.RememberButton[lang]}</FormCheck.Label>
                        </Form.Check>
                      </div>
                    </Form.Group>
                    <Button variant="secondary-lc" type="submit" className="button-signin-login w-100">
                      {langMap.SignInButton[lang]}
                    </Button>
                    <div className="w-100">
                      <Button variant="primary-lc" as={Link} to={AppRoutes.ForgotPassword.path} className="button-forgot-password w-50 mt-3">
                        {langMap.LostPswdButton[lang]}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
};

export default Signin;
export const langMap = {
    "PickACountry": {
        "IT": "Seleziona un paese",
        "EN": "Pick a country",
        "FR": "Choisir un pays",
        "ES": "Elija un país",
        "DE": "Wählen Sie ein Land aus"
    },
    "ChooseAnOccupation": {
        "IT": "Scegli un'occupazione",
        "EN": "Choose an occupation",
        "FR": "Choisissez une occupation",
        "ES": "Elija una ocupación",
        "DE": "Wählen Sie einen Beruf"
    }
}
import React, { useEffect } from "react";
import {Outlet} from "react-router-dom";

const RouteWithScripts = () => {

    useEffect(() => {
      if(!document.getElementById("hs-script-loader")){
        let script1 = document.createElement("script")
        script1.setAttribute("src", "https://cdn-cookieyes.com/client_data/d4f7a417f5061e6606816180/script.js");
        script1.setAttribute("type", "text/javascript");
        script1.setAttribute("id", "cookieyes");

        let script2 = document.createElement("script")
        script2.setAttribute("src", "//js.hs-scripts.com/4906807.js");
        script2.setAttribute("type", "text/javascript");
        script2.setAttribute("id", "hs-script-loader");
        script2.setAttribute("async", "");
        script2.setAttribute("defer", "");

        document.head.appendChild(script1);
        document.head.appendChild(script2);
      }
    })
    
    return (
      <Outlet/>
    );
};

export default RouteWithScripts;
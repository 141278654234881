import { noAuthRequest } from "../../../utils/REST/requests";
import { Endpoints } from "../../api";
import { allOccupations } from "./occupationsList";

export const initialOccupationObject = {name:"Choose an occupation"};

let lastCountry = "";
let lastLang = "EN"
let lastTop10 = null

export const getAvailableOccupations = async (lang, country) => {
    if ( !(lang === lastLang) || !(lastCountry === country)) {;
        lastLang = lang;
        lastCountry = country;
        const res = await noAuthRequest("GET", `${Endpoints.TOP_10_OCCUPATIONS}/${lang}/${country}`);
        lastTop10 = res.data;
    }
    const displayedOccupations = [...lastTop10];
    const lastCodes = lastTop10.map( occ => occ.code);
    allOccupations.forEach( occ => {
        if (!lastCodes.includes(occ.code))
            displayedOccupations.push(occ);
    });
    return displayedOccupations;
};

export const isOccupationSelected = (currentOccupation) => {
    return currentOccupation.code !== initialOccupationObject.code;
};
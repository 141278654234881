import {LOGIN, LOGOUT} from "../actions/actionTypes";

export const initialState =  {user: null, isAuth: false, error: ""};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      if(action.error) {
        return {
          isAuth: false,
          user: null,
          error: action.error
        };
      }
      else if(action.user) {
        return {
          isAuth: true,
          user: action.user,
          error: ""
        };
      }
      return initialState;
    case LOGOUT:
      return {
        isAuth: false,
        user: null,
        error: ""
      }
    default:
      return state;
  }
};

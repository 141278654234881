export const langMap = {
    "ContactUs": {
        "IT": "Contattaci",
        "EN": "Contact us",
        "FR": "Nous contacter",
        "ES": "Contáctenos",
        "DE": "Kontaktieren Sie uns"
    },
    "TermsAndConditions": {
        "IT": "Termini di utilizzo",
        "EN": "Terms of use",
        "FR": "Conditions d'usage",
        "ES": "Condiciones de uso",
        "DE": "Nutzungsbedingungen"
    },
    "PrivacyPolicy": {
        "IT": "Politica sulla riservatezza",
        "EN": "Privacy policy",
        "FR": "Politique de confidentialité",
        "ES": "Política de privacidad",
        "DE": "Datenschutzbestimmungen"
    }
}
import axios from "axios";
import { Endpoints } from "../../config/api";

let currentToken = null;
let refreshToken = null;
let requestsToRefresh = [];
let isRefreshing = false;
let remember = false;

// axios instance for calls requiring authentication with JWT token
const authenticatedApiClient = axios.create();
authenticatedApiClient.defaults.headers['Content-Type'] = "application/json";

// standard axios instance used for no-auth API calls
axios.defaults.headers['Content-Type'] = "application/json";

const setTokens = (token, refresh, rememberMe) => {
    authenticatedApiClient.defaults.headers['Authorization'] = 'Bearer ' + token;
    currentToken = token;
    refreshToken = refresh;
    remember = rememberMe;
    if(rememberMe){
        localStorage.setItem("token", currentToken);
        localStorage.setItem("refreshToken", refreshToken);
    };
};

export const clearTokens = () => {
    // console.log("Clearing tokens");
    authenticatedApiClient.defaults.headers['Authorization'] = null;
    currentToken = null;
    refreshToken = null;
    localStorage.clear();
}

authenticatedApiClient.interceptors.response.use(null, (err) => {
    const { response, config } = err;
    if(response){
        if (response.status === 401) {
            // If we have not logged in before, it makes no sense
            // to try to get a new token
            if (!currentToken) {
              return Promise.reject(err)
            };
    
            if (!isRefreshing) {
                isRefreshing = true
                // console.log('Request for a new token')
                // Send request to refresh token
                axios({
                    method: "GET",
                    url: Endpoints.REFRESH,
                    headers: {
                        "Authorization": 'Bearer ' + refreshToken
                    }
                })
                .then(({data}) => {
                    // console.log("Got new tokens");
                    setTokens(data.access_token, data.refresh_token, remember);
                    requestsToRefresh.forEach((cb) => cb(currentToken));
                })
                .catch( err => {
                    // If you have a closed system, you can also
                    // redirect/router to the login page
                    clearTokens();
                    requestsToRefresh.forEach((cb) => cb(null))
                })
                .finally(() => {
                    requestsToRefresh = []
                    isRefreshing = false
                });
            };
        
            return new Promise((resolve, reject) => {
                // In our variable (requests that expect a new token
                // from the first request), we add a callback,
                // which the first request to execute
                requestsToRefresh.push((token) => {
                    if (token) {
                        config.headers.Authorization = 'Bearer ' + token;
                        resolve(axios(config));
                    };
                    // If the first request could not update the token, we
                    // must return the basic request processing logic
                    reject(err);
                });
            });
        };
    };
    return Promise.reject(err);
});


export const loginRequest = (username, password, failureHandler, rememberMe) => {
    const loginJson = {username, password};
    // send the username and password to the server
   
    return axios({method: "POST", url:Endpoints.LOGIN, data: loginJson})
    .then(response => {
        if(response.status === 200){
            const {access_token, refresh_token} = response.data.tokens;
            setTokens(access_token, refresh_token, rememberMe);
            
            return {
                user: response.data.user,
                error: ""
            }
        };
    })
    .catch( err => {
        if (err?.response?.data){
            return {
                user: null,
                error: err.response.data.message
            }
        }
        failureHandler(true)
    });
};


export const noAuthRequest = (method, endpoint, body = null) => {
    let config = {method, url: endpoint}
    if( method === "POST" || method === "PUT"){
        config["data"] = body;
    }
    return axios(config)
    .then( res => {
        if(res.status === 200){
            return {
                data: res.data,
                error: ""
            };
        }
        return {data: null, error: res.error};
    })
    .catch( err => {
        if (err.response)
            return {data: null, error: err.response.data};
    })
}

export const userInfoFromJwtRequest = (token , refreshToken) => {
    setTokens(token, refreshToken, true);

    return authenticatedApiClient({method: "GET", url: Endpoints.USER_INFO})
    .then(res => {
        if (res.status === 200){
            return {
                user: res.data,
                error: "",
                status: 200
            };
        };
    })
    .catch( error => {
        if(error.response){
            if(error.response.status === 401){
                return {user: null, error: "Your token has expired. Please Sign In again", status: 401};
            };
        };
        return {user: null, error: "The service is temporarily unavailable, try again later", status: 503};
    });
};


export const dataRequest  = (endpoint, datasource, filter, profileId, solutionId, onSuccess, onError, disableRls, format = null) => {
    if(endpoint && filter) {
        let url = `${endpoint}`;
        authenticatedPostRequest(url, filter, onSuccess, onError);
    };
};


export const updateResourceRequest = (endpoint, body) => {
    return authenticatedRequest("PUT", endpoint, body)
    .then (res => {
        if(res.status === 200){
            return { response: res.data, error: ""};
        }
        return { response: "",  error: res.error};
    })
}

export const authenticatedPostRequest = (url, body, onSuccess, onError) => {
    authenticatedRequest("POST", url, body)
    .then ( res => {
        if(res.status === 200){
            onSuccess(res.data);
            onError("");
        }
        else {
            onError(res.error);
        }
    })
    .catch( err => {
        onError(err.response);
    });
};

export const authenticatedRequest = (method, url, body) => {
    let config = {method: method, url: url};
    if(method === "POST" || method === "PUT"){
        config["data"] = body;
    }
    return authenticatedApiClient(config)
    .then( res => {
        return {data: res.data, error: "", status: res.status};
    })
    .catch( err => {
        if(err.response)
            return {data: null, error: err.response.data, status: err.response.status};
        return {data: null, error: "service unavailable", status: 503};
    });
};





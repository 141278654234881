import React, { useReducer} from "react";
import * as ACTIONS from "../../actions/actions";
import * as FiltersReducer from "../../reducers/filtersReducer";
import FiltersContext from "./filtersContext";


const FiltersContextState = ({children, start}) => {

    const [stateFiltersReducer, dispatchFiltersReducer] = useReducer(
        FiltersReducer.FiltersReducer,
        start
    );

    const handleChangeCountry = (data) => {
        dispatchFiltersReducer(ACTIONS.changeCountry(data));
    };

    const handleChangeOccupation = (data) => {
        dispatchFiltersReducer(ACTIONS.changeOccupation(data));
    };


    return(
        <FiltersContext.Provider
            value={{
                selectedCountry: stateFiltersReducer.selectedCountry,
                selectedOccupation: stateFiltersReducer.selectedOccupation,
                displayedOccupations: stateFiltersReducer.displayedOccupations,
                changeCountry: (data) => handleChangeCountry(data),
                changeOccupation: (data) => handleChangeOccupation(data)
            }}
        >
            {children}
        </FiltersContext.Provider>
    )


};

export default FiltersContextState;
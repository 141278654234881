import { ROOT_DATA_API, ROOT_UMT_API } from "./env";

export const Endpoints = {
    LOGIN: `${ROOT_UMT_API}/api/users/login`,
    LOGGING: `${ROOT_UMT_API}/api/logs`,
    REGISTRATION: `${ROOT_UMT_API}/api/users/registration/new`,
    CONFIRM_REGISTRATION: `${ROOT_UMT_API}/api/users/registration/confirm`,
    USER_PROFILE: `${ROOT_UMT_API}/api/users/`,
    USER_UPDATE: `${ROOT_UMT_API}/api/users/{id}/last`,
    USER_INFO: `${ROOT_UMT_API}/api/jwt/user`,
    DATA: `${ROOT_DATA_API}/api/data/lead-generation`,
    TOP_10_OCCUPATIONS: `${ROOT_DATA_API}/api/data/lead-generation/top-occupations`,
    BOOKADEMO: `${ROOT_UMT_API}/api/users/{id}/demo`,
    RESET_PASSWORD: `${ROOT_UMT_API}/api/passwords/reset/request`,
    CONFIRM_RESET_PASSWORD: `${ROOT_UMT_API}/api/passwords/reset/confirm`,
    USER_OPT_OUT: `${ROOT_UMT_API}/api/users/opt-out`,
    CONFIRM_USER_OPT_OUT: `${ROOT_UMT_API}/api/users/opt-out/confirm`
};


export const Datasources = {
    EMSI_API_UK: "uk-jpa",
    EMSI_API_GLOBAL: "global-postings",
    EU_DATA: "EU_DATA"
}
import React, { useContext, useRef, useState} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { currentYear } from "../../../config/dates";
import { Endpoints } from "../../../config/api";
import AccessRestrictedWidget from "../../AccessRestrictedWidget/AccessRestrictedWidget";
import { BarChartWidget, PdfPrinter, SeriesValueWidget } from "@bge/global_solution_workbench_commons";
import UserContext from "../../../utils/context/user/userContext";
import { GeneralSelector } from "@bge/global_solution_workbench_commons";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import { useReactToPrint } from "react-to-print";
import PrintLayout from "../../PrintLayout/PrintLayout";
import GeneralButton from "../../GeneralButton/GeneralButton";
import { AppRoutes } from "../../../config/routes";
import notAccessibleBarChartImg from "../../../assets/img/notAccessibleBarChart.png"
import notAccessibleBarChartImgMobile from "../../../assets/img/notAccessibleBarChartMobile.png"
import notAccessibleMedianChartImg from "../../../assets/img/notAccessibleMedianChart.png"
import notAccessibleMedianChartImgMobile from "../../../assets/img/notAccessibleMedianChartMobile.png"
import gifLoading from "../../../assets/gif/Lightcast_LoadingGif_White.gif";
import char1 from "../../../assets/img/char1.png"
import char2 from "../../../assets/img/char2.png"
import RestrictedContentCover from "../../AccessRestrictedWidget/RestrictedContentCover";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import {availableCountries, isCountrySelected} from "../../../config/widgets/selectors/availableCountries";
import {getAvailableOccupations, isOccupationSelected} from "../../../config/widgets/selectors/availableOccupationsByCountry";
import { dataRequest } from "../../../utils/REST/requests";
import SalaryWidget from "../../SalaryWidget/SalaryWidget";
import { barsOptionsReverse, chartClasses, seriesOptions, reverseShareOptions, seriesOptionsPrint, barsOptionsReversePrint, reverseShareOptionsPrint } from "../../../config/charts/chartOptions";
import FiltersContext from "../../../utils/context/filters/filtersContext";
import {ModalPopup} from "@bge/global_solution_workbench_commons";
import { authenticatedRequest } from "../../../utils/REST/requests";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import ToTheTopButton from "../../ToTheTopButton/ToTheTopButton";
import { PageBreak } from "@bge/global_solution_workbench_commons";
import { langMap } from "./i18n";
import { I18N_ENABLED, ROOT_WEBAPP } from "../../../config/env";
import { useNavigate } from "react-router-dom";

function setLow(valuesSeries){
  const min = Math.min(...valuesSeries.map(s => Math.min(...s)));
  const res = min * 0.9;
  return Math.round(res);
}

const objectDeepCopy = (objectToCopy) => {
  if(typeof objectToCopy !== "object"){
    return objectToCopy;
  }
  let objectCopy = Array.isArray(objectToCopy) ? [] : {};

  for(let key in objectToCopy){
    const value = objectToCopy[key];
    objectCopy[key] = objectDeepCopy(value);
  }
  return objectCopy;
};

let seriesOptionsCopy = objectDeepCopy(seriesOptions)
let seriesOptionsPrintCopy = objectDeepCopy(seriesOptionsPrint)

const Presentation = () => {

  const componentRef = useRef();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { lang } = useContext(LanguageContext);
  const filtersContext = useContext(FiltersContext);
  const [sendingRequest, setSendingRequest] = useState(false);
  // const [confirmedRequest, setConfirmedRequest] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorBookADemoText, setErrorBookADemoText] = useState(false);

  const currentCountry = filtersContext.selectedCountry;
  const currentOccupation = filtersContext.selectedOccupation;
  const displayedOccupations = filtersContext.displayedOccupations;

  const filter = {occupationCode: currentOccupation.code, countryCode: currentCountry.code, language: I18N_ENABLED === "true" ? lang : "EN"};

  const resetOccupation = async(info) => {
    let tooltip = document.getElementsByClassName("chartist-tooltip");
    if(tooltip.length){
      document.querySelector(".chartist-tooltip").remove();
    }
    const newOccs = await getAvailableOccupations(lang, info.code);
    filtersContext.changeCountry({country: info, displayedOccupations: newOccs});
  };

  const changeOccupation = (info) => {
    filtersContext.changeOccupation({country: currentCountry, occupation: info, displayedOccupations: displayedOccupations});
  };

  const handlePrint = useReactToPrint({
    documentTitle: `${currentCountry.name}-${currentOccupation.name}.pdf`,
    content: () => componentRef.current
  });

  async function bookADemo(){
    const textToSend = document.getElementById("book-a-demo-content").value;
    if(textToSend.length){
      setErrorBookADemoText(false);
      setSendingRequest(true);
      const finalEndpoint = Endpoints.BOOKADEMO.replace("{id}", userContext.user.id);
      await authenticatedRequest("POST", finalEndpoint, {source: ROOT_WEBAPP, notes: textToSend});
      setSendingRequest(false);
      navigate(AppRoutes.CompletedBooking.path);
      // setConfirmedRequest(true);
    }else{
      setErrorBookADemoText(true);
    }
  };

  return (
    <main className="main-tabledpage">
      <div className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
        <Header/>
      </div>
      <div className="row-tabledpage section-body" id="content-body" ref={componentRef}>
        <PrintLayout/>
        <Container className="m-0 p-0 w-100 content-presentation">
          <Row className="welcome-section">
            <Col xs={12} className="text-left welcome-bg container">
              <h1>Lightcast European Insights</h1>
              <p className="welcome-text d-print-none">
                {langMap.WelcomeTextTop[lang]} <b>Lightcast European Insights</b> {langMap.WelcomeTextBottom[lang]}
              </p>
            </Col>
          </Row>
          <Row>
            <Container className="w-100 single-tabledpage">
              <Row className="selectorsField mb-4 mt-4 p-1">
                <Col sm={6} className="text-center my-2 selection-title">
                  <GeneralSelector
                    current={currentCountry.name}
                    passedItems={availableCountries}
                    clickHandler={resetOccupation}
                    classSelected="country-selector"
                  />
                </Col>
                <Col sm={6} className="text-center my-2">
                  {/* { isCountrySelected(currentCountryInfo) ?  */}
                    <GeneralSelector
                      current={currentOccupation.name}
                      passedItems={displayedOccupations}
                      clickHandler={changeOccupation}
                      key={currentCountry.code}
                      classSelected="occupation-selector"
                      disabled={!isCountrySelected(currentCountry)}
                  />
                  {/* : 
                    <div className="text-center my-2">
                      <p class="label d-inline">Pick an occupation</p>
                      <p>Before choosing an occupation, select a country</p>
                    </div>
                  } */}
                </Col>
              </Row>

              { isOccupationSelected(currentOccupation) ? 
                <>
                  <div className="pb-5">
                    <Row className="vertical-chars-card">
                      <Col xs={12}  className={`${chartClasses} widget-center`}>
                        <AccessRestrictedWidget
                          restricted = {false}
                          whenAccessible = {
                            <SeriesValueWidget
                              endpoint={`${Endpoints.DATA}/job_posting_trend`}
                              requestHandler={dataRequest}
                              filter={filter}
                              startYear={currentYear}
                              title = {langMap.SeriesWidgetHiringTitle[lang]}
                              subtitle = {langMap.SeriesWidgetHiringSubtitle[lang]}
                              chartClassName="jobposting-char ct-series-a ct-double-octave"
                              options={seriesOptionsCopy}
                              printOptions={seriesOptionsPrintCopy}
                              showTitle={false}
                              showSubtitle={false}
                              setLowAttribute={setLow}
                              printWidget={true}
                            />
                          }
                          title = {langMap.SeriesWidgetHiringTitle[lang]}
                          subtitle = {langMap.SeriesWidgetHiringSubtitle[lang]}
                          className = "chars-card h-100"
                        />
                      </Col>
                    </Row>
                    <PageBreak/>
                    <Row className="vertical-chars-card">
                      <Col xs={12}  className={`${chartClasses} widget-center`}>
                        <AccessRestrictedWidget
                            whenAccessible = {
                              <SeriesValueWidget
                                endpoint={`${Endpoints.DATA}/lmi_trend`}
                                requestHandler={dataRequest}
                                filter={filter}
                                startYear={currentYear}
                                title = {langMap.SeriesWidgetEmploymentTitle[lang]}
                                subtitle = {langMap.SeriesWidgetEmploymentSubtitle[lang]}
                                chartClassName="lmitrend-char ct-series-a ct-double-octave"
                                options={seriesOptions}
                                printOptions={seriesOptionsPrint}
                                showTitle={false}
                                showSubtitle={false}
                                monthFormat={false}
                                setLowAttribute={setLow}
                                printWidget={true}
                              />
                            }
                            title = {langMap.SeriesWidgetEmploymentTitle[lang]}
                            subtitle = {langMap.SeriesWidgetEmploymentSubtitle[lang]}
                            className = "chars-card h-100"
                        />
                      </Col>
                    </Row>
                    { !userContext.user &&
                    <Row>
                      <Col xs={12} className="d-print-none">
                        <h2 className="fw-bolder text-secondary-lc py-2 text-center pt-3 pb-0 mb-0">
                          {langMap.UnlockMoreTitle[lang]}
                        </h2>
                        <p className="insights-text d-print-none">
                          {langMap.UnlockMoreText[lang]}
                        </p>
                      </Col>
                    </Row>
                    }
                    <Row className="third-page">
                      <Col xs={12} className={`${chartClasses} widget-center`}>
                        <AccessRestrictedWidget
                              restricted = {!userContext.user}
                              whenAccessible = { 
                                <SalaryWidget
                                  endpoint={`${Endpoints.DATA}/salary`}
                                  requestHandler={dataRequest}
                                  filter={filter}
                                  maxLabel={langMap.MedianWidgetMaxText[lang]}
                                  minLabel={langMap.MedianWidgetMinText[lang]}
                                  medianLabel={langMap.MedianWidgetMedianText[lang]}
                                  country={currentCountry.name}
                              />
                              }
                              whenNotAccessible = {
                                <>
                                  <RestrictedContentCover
                                    imgPath={notAccessibleMedianChartImg}
                                    label={langMap.UnlockButtonText[lang]}
                                    classImage={"w-100 median-chart-img"}
                                    altImage="noaccess-median-chart-img"
                                  />
                                  <RestrictedContentCover
                                    imgPath={notAccessibleMedianChartImgMobile}
                                    label={langMap.UnlockButtonText[lang]}
                                    classImage={"w-100 median-chart-img-mobile"}
                                    altImage="noaccess-median-chart-img-mobile"
                                  />
                                </>
                              }
                              title = {langMap.MedianWidgetTitle[lang]}
                              subtitle = {langMap.MedianWidgetSubtitle[lang]}
                              className = "chars-card h-100"
                          />
                      </Col>
                    </Row>
                    <PageBreak/>
                    <Row>
                      <Col sm className={`${chartClasses} ${ userContext.user && "horizontal-widget" } row-widget-print widget-left`}>
                        <AccessRestrictedWidget
                            restricted = {!userContext.user}
                            whenAccessible = { 
                              <BarChartWidget
                                endpoint={`${Endpoints.DATA}/top_industries`}
                                requestHandler={dataRequest}
                                filter={filter}
                                title = {langMap.BarsWidgetDemandingTitle[lang]}
                                subtitle = {langMap.BarsWidgetDemandingSubtitle[lang]}
                                chartClassName="ct-square charVertical"
                                options={barsOptionsReverse}
                                printOptions={barsOptionsReversePrint}
                                showTitle={false}
                                showSubtitle={false}
                                printWidget={true}
                              />
                            }
                            whenNotAccessible = {
                              <>
                                <RestrictedContentCover
                                  imgPath={notAccessibleBarChartImg}
                                  label={langMap.UnlockButtonText[lang]}
                                  classImage={"w-100 bar-chart-img"}
                                  altImage="noaccess-bar-chart-img"
                                />
                                <RestrictedContentCover
                                  imgPath={notAccessibleBarChartImgMobile}
                                  label={langMap.UnlockButtonText[lang]}
                                  classImage={"w-100 bar-chart-img-mobile"}
                                  altImage="noaccess-bar-chart-img-mobile"
                                />
                              </>
                            }
                            title = {langMap.BarsWidgetDemandingTitle[lang]}
                            subtitle = {langMap.BarsWidgetDemandingSubtitle[lang]}
                            className = "chars-card h-100 first-graph"
                        />
                      </Col>
                      <Col sm className={`${chartClasses} ${ userContext.user && "horizontal-widget" } row-widget-print widget-right third-page`}>
                        <AccessRestrictedWidget
                          restricted = {!userContext.user}
                          whenAccessible = { 
                            <BarChartWidget
                              endpoint={`${Endpoints.DATA}/top_location`}
                              requestHandler={dataRequest}
                              filter={filter}
                              title = {langMap.BarsWidgetOfferingTitle[lang]}
                              subtitle = {langMap.BarsWidgetOfferingSubtitle[lang]}
                              chartClassName="ct-square charVertical"
                              printOptions={reverseShareOptionsPrint}
                              options={reverseShareOptions}
                              showTitle={false}
                              showSubtitle={false}
                              printWidget={true}
                            />
                          }
                          whenNotAccessible = {
                            <>
                              <RestrictedContentCover
                                imgPath={notAccessibleBarChartImg}
                                label={langMap.UnlockButtonText[lang]}
                                classImage={"w-100 bar-chart-img"}
                                altImage="noaccess-bar-chart-img"
                              />
                              <RestrictedContentCover
                                imgPath={notAccessibleBarChartImgMobile}
                                label={langMap.UnlockButtonText[lang]}
                                classImage={"w-100 bar-chart-img-mobile"}
                                altImage="noaccess-bar-chart-img-mobile"
                              />
                            </>
                          }
                          title = {langMap.BarsWidgetOfferingTitle[lang]}
                          subtitle = {langMap.BarsWidgetOfferingSubtitle[lang]}
                          className = "chars-card h-100"
                        />                    
                      </Col>
                    </Row>
                    <PageBreak/>
                    <Row>
                      <Col sm className={`${chartClasses} ${ userContext.user && "horizontal-widget" } row-widget-print widget-left col-sm`}>
                        <AccessRestrictedWidget
                            restricted = {!userContext.user}
                            whenAccessible = { 
                              <BarChartWidget
                                endpoint={`${Endpoints.DATA}/top_specialized_skills`}
                                requestHandler={dataRequest}
                                filter={filter}
                                title = {langMap.BarsWidgetSpecialisedSkillsTitle[lang]}
                                subtitle = {langMap.BarsWidgetSpecialisedSkillsSubtitle[lang]}
                                chartClassName="ct-square charVertical"
                                options={barsOptionsReverse}
                                printOptions={barsOptionsReversePrint}
                                showTitle={false}
                                showSubtitle={false}
                                printWidget={true}
                            />
                            }
                            whenNotAccessible = {
                              <>
                                <RestrictedContentCover
                                  imgPath={notAccessibleBarChartImg}
                                  label={langMap.UnlockButtonText[lang]}
                                  classImage={"w-100 bar-chart-img"}
                                  altImage="noaccess-bar-chart-img"
                                />
                                <RestrictedContentCover
                                  imgPath={notAccessibleBarChartImgMobile}
                                  label={langMap.UnlockButtonText[lang]}
                                  classImage={"w-100 bar-chart-img-mobile"}
                                  altImage="noaccess-bar-chart-img-mobile"
                                />
                              </>
                            }
                            title = {langMap.BarsWidgetSpecialisedSkillsTitle[lang]}
                            subtitle = {langMap.BarsWidgetSpecialisedSkillsSubtitle[lang]}
                            className = "chars-card h-100"
                        />
                      </Col>
                      <Col sm className={`${chartClasses} ${ userContext.user && "horizontal-widget" } row-widget-print widget-right col-sm`}>
                        <AccessRestrictedWidget
                          restricted = {!userContext.user}
                          whenAccessible = { 
                            <BarChartWidget
                              endpoint={`${Endpoints.DATA}/top_common_skills`}
                              requestHandler={dataRequest}
                              filter={filter}
                              title = {langMap.BarsWidgetCommonSkillsTitle[lang]}
                              subtitle = {langMap.BarsWidgetCommonSkillsSubtitle[lang]}
                              chartClassName="ct-square charVertical"
                              options={barsOptionsReverse}
                              printOptions={barsOptionsReversePrint}
                              showTitle={false}
                              showSubtitle={false}
                              printWidget={true}
                            />
                          }
                          whenNotAccessible = {
                            <>
                              <RestrictedContentCover
                                imgPath={notAccessibleBarChartImg}
                                label={langMap.UnlockButtonText[lang]}
                                classImage={"w-100 bar-chart-img"}
                                altImage="noaccess-bar-chart-img"
                              />
                              <RestrictedContentCover
                                imgPath={notAccessibleBarChartImgMobile}
                                label={langMap.UnlockButtonText[lang]}
                                classImage={"w-100 bar-chart-img-mobile"}
                                altImage="noaccess-bar-chart-img-mobile"
                              />
                            </>
                          }
                          title = {langMap.BarsWidgetCommonSkillsTitle[lang]}
                          subtitle = {langMap.BarsWidgetCommonSkillsSubtitle[lang]}
                          className = "chars-card h-100"
                        />
                      </Col>
                    </Row>
                  </div>   
                </>
                :
                <Row>
                  <Col xs={12} className="text-center mb-5 mt-4 p-0">
                    <Row className="text-center w-100 img-graph-collection m-auto">
                      <Col sm={6} className={`${chartClasses} widget-left`}>
                        <AccessRestrictedWidget
                          restricted = {true}
                          title = {langMap.SeriesWidgetHiringTitle[lang]}
                          subtitle = {langMap.SeriesWidgetHiringSubtitle[lang]}
                          className = "chars-card h-100 placeholder-chart-card mt-auto"
                          whenNotAccessible = {
                            <Image src={char1} alt="demo-char-image" className="demo-char" />
                          }
                        />
                      </Col>
                      <Col sm={6} className={`${chartClasses} widget-right`}>
                      <AccessRestrictedWidget
                        restricted = {true}
                        title = {langMap.SeriesWidgetEmploymentTitle[lang]}
                        subtitle = {langMap.SeriesWidgetEmploymentSubtitle[lang]}
                        className = "chars-card h-100 placeholder-chart-card mt-auto"
                        whenNotAccessible = {
                          <Image src={char2} alt="demo-char-image" className="demo-char" />
                        }
                      />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              { 
                userContext.user ? (
                  <Row className="d-print-none pb-5">
                    <Col xs={12} className="text-center">
                      <h3 className="fw-bolder text-secondary-lc py-2 mt-4">
                        {langMap.BookADemoIntroductionTitle[lang]}
                      </h3>
                      <p className="book-text">
                        {langMap.BookADemoIntroductionTextTop[lang]} <b>Lightcast European Insights</b> {langMap.BookADemoIntroductionTextBottom[lang]}
                      </p>
                      <ModalPopup
                        title={langMap.BookADemoButtonText[lang]}
                        textConfig={{ top: "", bottom: langMap.BookADemoTitle[lang]}}
                        textButton={langMap.BookADemoButtonText[lang]}
                        textButtonConfirm={langMap.BookADemoConfirmButtonText[lang]}
                        errorConfig={{title: `${langMap.BookADemoErrorTitle[lang]}`, text: `${langMap.BookADemoErrorText[lang]} `, link: <a href="https://lightcast.io/contact" rel="noreferrer" className="text-secondary contact-modal" target="_blank">{langMap.BookADemoErrorTextLink[lang]}</a>}}
                        loadImg={gifLoading}
                        buttonFunction={() => bookADemo()}
                        sending={sendingRequest}
                        sendingTitle={langMap.SendingRequestText[lang]}
                        // confirmed={confirmedRequest}
                        error={errorRequest}
                        closeError={() => setErrorRequest(false)}
                        addictionalComponents={
                          <textarea placeholder="Write here..." className={`${ errorBookADemoText && "empty-area" } demo-request-textarea`} id="book-a-demo-content"></textarea>
                        }
                      />
                    </Col>
                  </Row>
                )
                :
                <></>
              } 
            </Container>
          </Row>
        </Container>
      </div>
      <div className="row-tabledpage section-body">
        <Footer />
      </div>
      <PdfPrinter printHandler={handlePrint} disabledPrint={!isOccupationSelected(currentOccupation) || !userContext.user}/>
      {!userContext.user &&
        <GeneralButton
          label = {langMap.UnlockButtonText[lang]}
          className = "text-white-lc unlock-button"
          iconClassName = "floatLock"
          buttonIcon={faUnlockAlt}
          destination={AppRoutes.Registration.path}
        />
      }
      { currentCountry && currentOccupation &&
        <ToTheTopButton/>
      }
    </main>
  );
};

export default Presentation;
import React, { useContext, useEffect } from 'react';
import { Routes , Route} from "react-router-dom";
import { AppRoutes } from "../../config/routes";
import Presentation from "../pages/Presentation/Presentation";
import Registration from '../pages/Registration/Registration';
import Signin from "../pages/SignIn/Signin";
import NotFound from "../pages/NotFound/NotFound";
import ServerError from "../pages/ServerError/ServerError";
import ConfirmRegistration from '../pages/ConfirmRegistratrion/ConfirmRegistration';
import UserContext from '../../utils/context/user/userContext';
import UserProfile from '../pages/UserProfile/UserProfile';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import { useLocation } from 'react-router-dom';
import ConfirmDeletingAccount from '../pages/ConfirmDeletingAccount/ConfirmDeletingAccount';
import RouteWithScripts from './RouteWithScripts';
import EmbeddableView from '../pages/EmbeddableView/EmbeddableView';
import CompletedBooking from '../pages/CompletedBooking/CompletedBooking';


const AppRouter = () => {

  const userContext = useContext(UserContext);
    
  const toTheTop = () => {
    window.scrollTo(0,0);
  }

  const routePath = useLocation();

  useEffect(() => {

    toTheTop();

  }, [routePath]);

  return(
    <Routes>
        <Route exact path={AppRoutes.EmbeddableView.path} element={<EmbeddableView/>} />

        <Route element={<RouteWithScripts />}>
          <Route exact path={AppRoutes.Presentation.path} element={<Presentation/>} />
          <Route exact path={AppRoutes.CompletedBooking.path} element={<CompletedBooking/>} />
          <Route exact path={AppRoutes.Registration.path} element={<Registration/>} />
          <Route exact path={AppRoutes.RegistrationConfirmation.path} element={<ConfirmRegistration/>} />
          <Route exact path={AppRoutes.ConfirmDeletingAccount.path} element={<ConfirmDeletingAccount/>} />

          { userContext.user && <Route exact path={AppRoutes.UserProfile.path} element={<UserProfile/>}/> }

          <Route exact path={AppRoutes.Signin.path} element={<Signin/>} />
          <Route exact path={AppRoutes.TermsAndConditions.path} element={<TermsAndConditions/>} />
          <Route exact path={AppRoutes.ServerError.path} element={<ServerError/>} />
          <Route exact path={AppRoutes.ForgotPassword.path} element={<ForgotPassword/>} />
          <Route exact path={AppRoutes.ResetPassword.path} element={<ResetPassword/>} />
        </Route>

        <Route path="/*" element={<NotFound/>}/>
    </Routes>
  )
};

export default AppRouter;
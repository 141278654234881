import { initialCountryObject } from "../../config/widgets/selectors/availableCountries";
import { initialOccupationObject } from "../../config/widgets/selectors/availableOccupationsByCountry";
import { CHANGE_COUNTRY, CHANGE_OCCUPATION } from "../actions/actionTypes";


const initialState = {selectedCountry: initialCountryObject, selectedOccupation: initialOccupationObject, availableOccupations: []};

export const FiltersReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_COUNTRY:
      case CHANGE_OCCUPATION:
        localStorage.setItem("selectedCountry", JSON.stringify(action.country));
        localStorage.setItem("selectedOccupation", JSON.stringify(action.occupation));
        localStorage.setItem("selectedOccupationsList", JSON.stringify(action.displayedOccupations));
        return {
          selectedCountry: action.country,
          selectedOccupation: action.occupation,
          displayedOccupations: action.displayedOccupations
        };
      default:
        return state;
    }
  };
  
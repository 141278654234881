import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { currentDate, printDateFormat} from "../../config/dates";
import CompanyLogo from "../../assets/img/Lighcast_RGB_Lockup_Color_full.png";
import { browserLocale } from '../../config/locales';
import { currentYear } from '../../config/dates';
import { useContext } from "react";
import LanguageContext from "../../utils/context/i18n/languageContext";
import { langMap } from "./i18n";

const PrintLayout = () => {

    const lang = useContext(LanguageContext).lang;
    return(
        <>
            <Row className="print-top">
                <Col>
                    <Image src={CompanyLogo} width={200} alt="Lightcast Logo" />
                </Col>
            </Row>
            <Row className="print-bottom-left">
                <Col>
                    {currentDate.toLocaleString(browserLocale, printDateFormat)}
                </Col>
            </Row>
            <Row className="print-bottom-center">
                <Col>
                    {langMap.PrintInviteTextTop[lang]}<br/> 
                    {langMap.PrintInviteTextBottom[lang]}https://lightcast.io/euro/contact 
                </Col>
            </Row>
            <Row className="print-bottom-right">
                <Col>
                    Copyright © Lightcast {currentYear} 
                </Col>
            </Row>
        </>
    );

}

export default PrintLayout;
import { Languages } from "../../config/languages";
import { CHANGE_LANG } from "../actions/actionTypes";

const getInitialConfig = () => {
   const searchParams = new URLSearchParams(window.location.search);
   if(searchParams.has("locale")){
        const locale = searchParams.get("locale");
        const conf = Languages.find( conf => conf.locale === locale);
        // preserve en-GB in case of bad/missing locales
        if(conf){
            localStorage.setItem("selectedLocale", locale);
            return conf
        }
            
   } else if(localStorage.getItem("selectedLocale")) {
        const locale = localStorage.getItem("selectedLocale");
        const conf = Languages.find( conf => conf.locale === locale);
        if (conf) {
            return conf;
        }
   }

   return  {
        lang: "EN",
        locale: "en-GB",
        flag: "united-kingdom.svg"
    }
}

export const initialState = getInitialConfig();


export const LangReducer = (state=initialState, action) => {
    if(action.type === CHANGE_LANG){
        document.documentElement.setAttribute("lang", action.locale)
        localStorage.setItem("selectedLocale", action.locale);
        return {
            lang: action.lang,
            locale: action.locale,
            flag: action.flag
        };
    }
    return state;
}
import React from "react";
import { useEffect } from "react";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import GeneralButton from "../GeneralButton/GeneralButton";


const activationButton = () => {

  const toTheTopButton = document.querySelector(".tothetop-button");

  if(window.pageYOffset > 300){
    toTheTopButton.style.display = "block"
  }else{
    toTheTopButton.style.display = "none"
  }

}

const ToTheTopButton = () => {
    
  const toTheTop = () => {
    window.scrollTo(0,0);
  }

  useEffect(() => {
    
    window.addEventListener("scroll", activationButton);

    return () => { window.removeEventListener("scroll", activationButton);}

  }, []);

    
  return (
    <GeneralButton className="tothetop-button" iconClassName="floatTothetop" buttonIcon={faCaretUp} clickFunction={toTheTop}></GeneralButton>
  );
};

export default ToTheTopButton;
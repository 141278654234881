import React, { useContext } from "react";
import { Popup } from "@bge/global_solution_workbench_commons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { langMap } from "./i18n";
import LanguageContext from "../../utils/context/i18n/languageContext";

const PasswordPopup = ({placement = "top"}) => {

    const lang = useContext(LanguageContext).lang

    return(
        <Popup
            placement={placement}
            title={langMap.PasswordContainTitle[lang]}
            textLines={[
                langMap.PasswordContainCharacterText[lang],
                langMap.PasswordContainUppercaseText[lang],
                langMap.PasswordContainNumberText[lang],
                langMap.PasswordContainSpecialText[lang],
                langMap.PasswordContainWhitespaceText[lang]
            ]}
            buttonClass="password-info"
            icon={faCircleInfo}
            iconClass="password-info"
        />
    )
}
export default PasswordPopup;
export const langMap = {
    "ServiceDownTitleLeft": {
        "IT": "Il servizio è",
        "EN": "The service is",
        "FR": "Le service est",
        "ES": "El servicio es",
        "DE": "Der Service ist"
    },
    "ServiceDownTitleCenter": {
        "IT": "temporaneamente",
        "EN": "temporarily",
        "FR": "temporairement",
        "ES": "temporalmente",
        "DE": "vorübergehend"
    },
    "ServiceDownTitleRight": {
        "IT": "non disponibile",
        "EN": "unavailable",
        "FR": "indisponible",
        "ES": "no disponible",
        "DE": "nicht verfügbar"
    },
    "ServiceDownText": {
        "IT": "È sempre il momento di fare una pausa caffè. Dovremmo tornare quando avrete finito lo stacco.",
        "EN": "It's always time for a coffee break. We should be back by the time you finish your coffee.",
        "FR": "Il est toujours temps pour une pause-café. Nous devrions être de retour au moment où vous terminez votre café.",
        "ES": "Siempre es hora de hacer una pausa. Deberíamos estar de vuelta cuando haya terminado su pausa.",
        "DE": "Es ist immer Zeit für eine Kaffeepause. Wir sollten zurück sein, wenn Sie mit Ihrem Kaffee fertig sind. "
    },
    "BackHomeButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Go back home",
        "FR": "Rentrer à la maison",
        "ES": "Volver al inicio",
        "DE": "Zurück zur Startseite"
    }
}
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

import { Link } from 'react-router-dom';

import { AppRoutes } from "../../../config/routes";
import NotFoundImage from "../../../assets/illustrations/404.svg"
import { langMap } from "./i18n";

import LanguageContext from "../../../utils/context/i18n/languageContext";


const NotFound = () => {

  const lang = useContext(LanguageContext).lang;

  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Image src={NotFoundImage} alt="not-found-image" className="img-fluid w-75" />
                <h1 className="text-secondary-lc mt-5">
                  {langMap.PageNotFoundTitleLeft[lang]} <span className="fw-bolder">{langMap.PageNotFoundTitleRight[lang]}</span>
                </h1>
                <p className="lead my-4">
                  {langMap.PageNotFoundText[lang]}
                </p>
                <Button as={Link} variant="primary" className="animate-hover" to={AppRoutes.Presentation.path}>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  {langMap.BackHomeButtonText[lang]}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default NotFound;
export const langMap = {
    "ProvidedCodeError": {
        "IT": "Non è stato fornito alcun codice di registrazione",
        "EN": "No registration code has been provided",
        "FR": "Aucun code d'enregistrement n'a été fourni",
        "ES": "No se ha proporcionado ningún código de registro",
        "DE": "Es wurde kein Registrierungscode angegeben "
    },
    "BackToHomepageButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "SuccessRegistrationTitle": {
        "IT": "Registrato con successo!",
        "EN": "Successfully registered!",
        "FR": "Enregistré avec succès !",
        "ES": "¡Registrado exitosamente!",
        "DE": "Erfolgreich registriert!"
    },
    "SuccessRegistrationTextTop": {
        "IT": "Grazie per esserti registrato. Ora puoi accedere al prossimo livello nel viaggio tra i dati di Lightcast.",
        "EN": "Thank you for registering to unlock the next level of data in the Lightcast journey.",
        "FR": "Merci de vous inscrire pour déverrouiller le prochain niveau de données dans le parcours Lightcast.",
        "ES": "Gracias por registrarse para desbloquear el siguiente nivel de datos en el viaje de Lightcast.",
        "DE": "Vielen Dank, dass Sie sich registriert haben, um das nächste Level der Lightcast -Reise freizuschalten."
    },
    "SuccessRegistrationTextBottom": {
        "IT": "Fai clic sul pulsante in basso per vedere ulteriori approfondimenti.",
        "EN": "Click on the button below to see more insights.",
        "FR": "Cliquez sur le bouton ci-dessous pour voir plus d'informations.",
        "ES": "Haga clic en el botón de abajo para ver más información.",
        "DE": "Klicken Sie auf die Schaltfläche unten, um weitere Erkenntnisse anzuzeigen."
    },
    "ButtonLoginText": {
        "IT": "Entra",
        "EN": "Login",
        "FR": "Connexion",
        "ES": "Acceso",
        "DE": "Login"
    },
    "CheckingCodeText": {
        "IT": "Controllo codice...",
        "EN": "Checking code...",
        "FR": "Vérification du code...",
        "ES": "Verificación de código...",
        "DE": "Code wird geprüft"
    }
}
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from "../../../config/routes";
import { useSearchParams } from "react-router-dom";
import { faAngleLeft, faCircleCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons"
import { Endpoints } from "../../../config/api";
import { useEffect } from "react";
import { langMap } from "./i18n";
import { errorMessages } from "../../../config/errors";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import gifLoading from "../../../assets/gif/Lightcast_LoadingGif_White.gif"
import { noAuthRequest } from "../../../utils/REST/requests";

const ResetPassword = () => {

    const lang = useContext(LanguageContext).lang
    const [params] = useSearchParams();
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        (async ()=> {
            const token = params.get("token");
            if(token) {
                const res = await noAuthRequest("POST", Endpoints.CONFIRM_RESET_PASSWORD, {token: token});
                if (res.data) {
                    setError("");
                    setVerified(true);
                }
                else {
                    setVerified(false);
                    if(res.error)
                        setError(res.error);
                    else
                        navigate(AppRoutes.ServerError.path);
                }
            } 
            else
                setError(langMap.ProvidedCodeError[lang]);
        })();
    },[params, navigate, lang])

    return(
        <main>
            <section className="d-flex align-items-center mt-5 mt-lg-6 mb-lg-5">
                <Container>
                    <p className="text-center">
                        <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> {langMap.HomepageButtonText[lang]}
                        </Card.Link>
                    </p>
                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                        <div className="text-center text-md-center mb-4 mt-md-0">
                            {verified ?  
                            <>
                            <h1 className="mb-0 text-success text-secondary-lc">
                                {langMap.SuccessResetTitle[lang]}
                            </h1>
                            <h4 className="secondary-text-success pt-3">
                                {langMap.SuccessResetTextTop[lang]}<br/> 
                                {langMap.SuccessResetTextBottom[lang]}
                            </h4>  
                            <FontAwesomeIcon icon={faCircleCheck} className="mt-6 icon-success" /> 
                            <Link as={Link} to={AppRoutes.Signin.path} className="d-flex justify-content-center align-items-center mt-6">
                                <Button variant="primary" type="submit" className="w-50">
                                    {langMap.ButtonLoginText[lang]}
                                </Button>
                            </Link>
                            </> 
                            :
                            <>
                                {
                                    error ? 
                                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                                        <div className="text-center text-md-center mb-4 mt-md-0">
                                            {error.split(", ").map((err, i)=> <h3 key={"err" + i} className="mb-0 text-danger">{errorMessages[err][lang]}</h3>)}
                                            <FontAwesomeIcon icon={faCircleXmark} className="mt-6 icon-success" />
                                        </div>
                                    </Col>
                                    :
                                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                                        <div className="text-center text-md-center mb-4 mt-md-0">
                                            <div className="loading-logo-bg">
                                                <Image alt="loading-logo-gif" className="loading-logo" src={gifLoading} />
                                            </div>
                                            <h3 className="mb-0 text-danger">{langMap.CheckingCodeText[lang]}</h3>
                                        </div>
                                    </Col>
                                }
                            </>
                            }
                        </div>
                    </Col>  
                </Container>
            </section>
        </main>
    )

};

export default ResetPassword;
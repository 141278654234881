export const defaultSalaryWidgetData = {
    salary: {
        "currency": "EUR",
        "min_salary": 15000,
        "max_salary": 75000,
        "salary_10": 20000,
        "salary_20": 24000,
        "salary_30": 25000,
        "salary_40": 30000,
        "salary_50": 31000,
        "salary_60": 36000,
        "salary_70": 45000,
        "salary_80": 50000,
        "salary_90": 60000
    }
}
import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { faSignInAlt, faSignOutAlt, faUserAlt, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from 'react-router-dom';
import UserContext from "../../utils/context/user/userContext";
import { AppRoutes } from "../../config/routes";
import {langMap} from "./i18n";
import CompanyLogo from "../../assets/img/Lighcast_RGB_Lockup_Color_full.png";
import GeneralButton from "../GeneralButton/GeneralButton"
import LanguageContext from "../../utils/context/i18n/languageContext";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { I18N_ENABLED } from "../../config/env";

const Header = () => {

    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const lang = useContext(LanguageContext).lang;
  
    const onLogout = (e) => {
        e.preventDefault();
        navigate(AppRoutes.Presentation.path);
        userContext.handleUserLogout();
      }

    return(
      <Container className="m-0 p-0 w-100 content-presentation">
        <Row>
          <Container className="w-100 single-tabledpage">
            <Row>
              <Col sm={6} className="text-left">
                <div className="d-flex flex-column p-2">
                  <div className="text-left header-logo">
                    <a href="https://lightcast.io" target="_blank" rel="noopener noreferrer">
                      <Image src={CompanyLogo} width={250} alt="Lightcast Logo" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="d-flex flex-column p-2">
                  <div className="d-grid gap-2 d-md-block header-button-section">
                    { 
                      userContext.user ? 
                      <div className="header-button-log">
                        <Button variant="primary-lc" onClick={onLogout} className="header-button buttontext-primary me-1">
                          {langMap.SignOutButtonText[lang]}<FontAwesomeIcon icon={faSignOutAlt} className="d-none d-sm-inline ms-1"/> 
                        </Button>
                      </div>
                      : 
                      <div className="header-button-log">
                        <Button variant="primary-lc" as={Link} to={AppRoutes.Signin.path} className="header-button text-primary me-1">
                          {langMap.SignInButtonText[lang]}<FontAwesomeIcon icon={faSignInAlt} className="d-none d-sm-inline ms-1" />
                        </Button>
                      </div>
                    } 
                    {
                      userContext.user ?
                      <Col className="header-button-unlock">
                        <Button variant="secondary-lc" as={Link} to={AppRoutes.UserProfile.path}className="header-button text-white-lc me-1">
                        {langMap.MyProfileButtonText[lang]}<FontAwesomeIcon icon={faUserAlt} className="d-none myprofile-icon d-sm-inline ms-1" />
                        </Button>
                      </Col>
                      :
                      <Col className="header-button-unlock">
                        <GeneralButton
                          label = {`${langMap.RegisterButtonText[lang]}`}
                          className = "text-white-lc me-1 header-button"
                          buttonIcon={faUnlockAlt}
                          destination={AppRoutes.Registration.path}
                        />
                      </Col>
                    }
                  </div>
                  { I18N_ENABLED === "true"  &&
                    <LanguageSelector className="language-selector-position"/>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
};

export default  Header;
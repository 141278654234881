export const langMap = {
    "ProvidedCodeError": {
        "IT": "Non è stato fornito alcun codice",
        "EN": "No code has been provided",
        "FR": "Aucun code n'a été fourni",
        "ES": "No se ha facilitado ningún código",
        "DE": "Es wurde kein Code angegeben"
    },
    "BackToHomepageButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "Retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "SuccessAccountDeletedTitle": {
        "IT": "Account disattivato con successo",
        "EN": "Account successfully disabled",
        "FR": "Compte désactivé avec succès",
        "ES": "Cuenta desactivada correctamente",
        "DE": "Konto erfolgreich deaktiviert"
    },
    "SuccessAccountDeletedTextTop": {
        "IT": "L'account è stato disattivato con successo.",
        "EN": "Your account has been successfully disabled.",
        "FR": "Votre compte a été désactivé avec succès.",
        "ES": "Su cuenta ha sido desactivada correctamente.",
        "DE": "Ihr Konto wurde erfolgreich deaktiviert."
    },
    "CheckingCodeText": {
        "IT": "Controllo del codice...",
        "EN": "Checking code...",
        "FR": "Vérification du code...",
        "ES": "Comprobando código...",
        "DE": "Überprüfe Code..."
    }
}
export const langMap = {
    "BackToHomepageButtonText": {
        "IT": "Torna alla homepage",
        "EN": "Back to homepage",
        "FR": "retour à la page d'accueil",
        "ES": "Volver a la página de inicio",
        "DE": "Zurück zur Startseite"
    },
    "BookADemoSuccessTitle": {
        "IT": "Grazie!",
        "EN": "Thank you!",
        "FR": "Merci ! ",
        "ES": "Gracias!",
        "DE": "Vielen Dank!"
    },
    "BookADemoSuccessText": {
        "IT": "Verrai contattato a breve",
        "EN": "You will be contacted shortly",
        "FR": "Vous serez contacté sous peu",
        "ES": "Se le contactará en breve",
        "DE": "Wir werden Sie in Kürze kontaktieren"
    }
}
import React, { useEffect, useState, useRef, useContext} from "react"
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { Endpoints } from "../../../config/api";
import { AppRoutes } from "../../../config/routes";
import { langMap } from "./i18n";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import { errorMessages } from "../../../config/errors";
import { noAuthRequest } from "../../../utils/REST/requests";
import Header from "../../Header/Header";
import UserContext from "../../../utils/context/user/userContext";

const ConfirmDeletingAccount = () => {
    const userRef = useRef(useContext(UserContext));
    const {lang} = useContext(LanguageContext);
    const [params] = useSearchParams();
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        (async ()=> {
            const code = params.get("code");
            if(code) {
                const res = await noAuthRequest("POST", Endpoints.CONFIRM_USER_OPT_OUT, {confirmationCode: code});
                if (res.data) {
                    setError("");
                    setDeleted(true);
                    userRef.current.handleUserLogout();
                }
                else {
                    setDeleted(false);
                    if(res.error)
                        setError(res.error);
                    else
                        navigate(AppRoutes.ServerError.path);
                }
            } 
            else
                setError(langMap.ProvidedCodeError[lang]);
        })();
    },[params, navigate, userRef, lang])

    return(
        <main>
            <section className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
                <Header/>
            </section>
            <section className="d-flex align-items-center mt-5 mt-lg-6 mb-lg-5">
                <Container>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                        <div className="text-center text-md-center mb-4 mt-md-0">
                            {deleted ?  
                            <>
                            <h1 className="mb-0 text-success text-secondary-lc">
                                {langMap.SuccessAccountDeletedTextTop[lang]}
                            </h1>
                            <h4 className="secondary-text-success pt-3">
                                {langMap.SuccessAccountDeletedTitle[lang]}
                            </h4>  
                            <FontAwesomeIcon icon={faCircleCheck} className="mt-6 icon-success" /> 
                            <Link as={Link} to={AppRoutes.Presentation.path} className="d-flex justify-content-center align-items-center mt-6">
                                <Button variant="primary" type="submit" className="w-50">
                                    {langMap.BackToHomepageButtonText[lang]}
                                </Button>
                            </Link>
                            </> 
                            :
                            <>
                                {
                                    error ? 
                                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                                        <div className="text-center text-md-center mb-4 mt-md-0">
                                            {error.split(", ").map((err, i)=> <h3 key={"err" + i} className="mb-0 text-danger">{errorMessages[err][lang]}</h3>)}
                                            <FontAwesomeIcon icon={faCircleXmark} className="mt-6 icon-success" />
                                        </div>
                                    </Col>
                                    :
                                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                                        <div className="text-center text-md-center mb-4 mt-md-0">
                                            <FontAwesomeIcon icon={faCircleCheck} className="mt-6 icon-success mb-4" />
                                            <h3 className="mb-0 text-danger">{langMap.CheckingCodeText[lang]}</h3>
                                        </div>
                                    </Col>
                                }
                            </>
                            }
                        </div>
                    </Col>  
                </Container>
            </section>
        </main>
    )

};

export default ConfirmDeletingAccount;
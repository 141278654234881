import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { faEnvelope, faUnlockAlt, faEarthEurope, faUserGear, faBriefcase, faUserCircle, faBuilding, faTrashCan, faUserXmark, faEnvelopeCircleCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../../utils/context/user/userContext";
import { Endpoints } from "../../../config/api";
import { authenticatedRequest, updateResourceRequest } from "../../../utils/REST/requests";
import { AppRoutes } from "../../../config/routes";
import Header from "../../Header/Header";
import PasswordPopup from "../../PasswordPopup/PasswordPopup";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import { langMap } from "./i18n";
import { errorMessages } from "../../../config/errors";
import {ModalPopup} from "@bge/global_solution_workbench_commons";
import gifLoading from "../../../assets/gif/Lightcast_LoadingGif_White.gif";
import { ROOT_WEBAPP } from "../../../config/env";
import { useEffect } from "react";

const UserProfile = () => {

    const lang = useContext(LanguageContext).lang;
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const loginDate = new Date(user.lastLogin);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [confirmedRequest, setConfirmedRequest] = useState(false);
    const [errorRequest, setErrorRequest] = useState(false);
    

    const [userInfo, setUserInfo] = useState({
        username: user.username, email: user.email, firstName: user.firstName, lastName: user.lastName, jobTitle: user.jobTitle, organizationName: user.organizationName,
        homeCountry: user.homeCountry, concurrentAccess: user.concurrentAccess, enabled: user.enabled, currentPassword: "", newPassword: "", check: ""
    });
    const [error, setError] = useState("");
    const [response, setResponse] = useState("");

    const onDismiss = () => {
        setError("");
        setResponse("");
        setErrorRequest("");
    }

    useEffect(() => {
        let tooltip = document.getElementsByClassName("chartist-tooltip");
        if(tooltip.length){
          document.querySelector(".chartist-tooltip").remove();
        }
    }, []);    

    async function deleteAccount(pass){
        setSendingRequest(true);
        const res = await authenticatedRequest("POST", Endpoints.USER_OPT_OUT, {email: user.email, password: pass, origin: ROOT_WEBAPP});
        setSendingRequest(false);

        if (res.status === 200) { 
            setConfirmedRequest(true)
        }else{
            setErrorRequest(res.error)
        }   
    }

    const handleUpdate = async() => {
        const res = await updateResourceRequest(Endpoints.USER_PROFILE+user.id, userInfo);
        if (!res.response){
            setResponse("");
            setError(res.error);
        }
        else{
            setError("");
            userContext.handleUserLogin({user: res.response, error: res.error});
            setResponse(langMap.UpdateResponse[lang])
        }
    }

    return (
        <>
        <section className="section-header bg-primary-lc text-black-lc align-items-stretch border-header" id="header">
            <Header/>
        </section>
        <section className="overflow-hidden pt-2 align-items-stretch userprofile">
            <Container fluid>
                {/* <div className="react-big-icon d-none d-lg-block">
                    <FontAwesomeIcon icon={faUser} />
                </div> */}
                <Row className="mt-3 text-center">
                    <div className="hiuser">
                        <h1 className="nameuser text-secondary-lc">{langMap.HelloUser[lang]}, {user.username}</h1>
                    </div>
                </Row>
                <Row>
                    <Col xs={12} className="mb-5 text-center">
                        <span className="last-login-span">{langMap.LoginUser[lang]}<b>{loginDate.toLocaleString()}</b></span>
                    </Col>
                    <p className="text-center">
                        <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                            <FontAwesomeIcon icon={faAngleLeft} className="me-2 text-gray-lc-600" />{langMap.BackButtonText[lang]}
                        </Card.Link>
                    </p>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} className="collumns-input mb-4 justify-content-center" >
                        <h4 className="title-category-profile">{langMap.InfoTitle[lang]}</h4>
                        <hr className="hr-category-profile"></hr>
                        <FontAwesomeIcon className="text-secondary-lc icon-about-you" icon={faUserGear} />
                    </Col>
                    <Row className="collumns-input mb-6">
                        <Col sm={2}></Col>
                        <Col sm={4} className="collumn-input">
                            <Form.Group id="firstName" className="mb-4">
                                <Form.Label><span>{langMap.FirstNameTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faUserCircle} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="1" autoComplete="new-name" autoFocus required placeholder={userInfo.firstName} onChange={({ target }) => setUserInfo((prev) => ({...prev, firstName: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="homeCountry" className="mb-4">
                                <Form.Label><span>{langMap.CountryTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faEarthEurope} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="3" autoComplete="new-country" autoFocus required placeholder={userInfo.homeCountry} onChange={({ target }) => setUserInfo((prev) => ({...prev, homeCountry: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="organizationName" className="mb-4">
                                <Form.Label><span>{langMap.OrganizationTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faBuilding} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="5" autoComplete="new-organization" autoFocus required placeholder={userInfo.organizationName} onChange={({ target }) => setUserInfo((prev) => ({...prev, organizationName: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={4} className="collumn-input">
                            <Form.Group id="lastName" className="mb-4">
                                <Form.Label><span>{langMap.LastNameTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faUserCircle} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="2" autoComplete="new-lastname" autoFocus required placeholder={userInfo.lastName} onChange={({ target }) => setUserInfo((prev) => ({...prev, lastName: target.value}))}/>
                                </InputGroup>
                            </Form.Group> 
                            <Form.Group id="jobTitle" className="mb-4">
                                <Form.Label><span>{langMap.JobTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faBriefcase} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="4" autoComplete="new-username" autoFocus required placeholder={userInfo.jobTitle} onChange={({ target }) => setUserInfo((prev) => ({...prev, jobTitle: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                    <Col xs={12} className="collumns-input mb-4 justify-content-center" >
                        <h4 className="title-category-profile">{langMap.CredentialsTitle[lang]}</h4>
                        <hr className="hr-category-profile"></hr>
                        <FontAwesomeIcon className="text-secondary-lc icon-about-you" icon={faUnlockAlt} />
                    </Col>
                    <Row className="collumns-input mb-6">
                        <Col sm={2}></Col>
                        <Col sm={4} className="collumn-input">
                            <Form.Group id="username" className="mb-4">
                                <Form.Label><span>{langMap.UsernameTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faEnvelope} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="6" autoComplete="new-username" autoFocus required placeholder={userInfo.username} onChange={({ target }) => setUserInfo((prev) => ({...prev, username: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="password" className="mb-4">
                                <Form.Label><span>{langMap.PasswordTitle[lang]}</span></Form.Label>
                                <PasswordPopup/>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon className="profile-input-icons" icon={faUnlockAlt} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="8" autoComplete="new-password" required type="password" placeholder={langMap.PasswordText[lang]} onChange={({ target }) => setUserInfo((prev) => ({...prev, newPassword: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={4} className="collumn-input">
                            <Form.Group id="email" className="mb-4">
                                <Form.Label><span>{langMap.EmailTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faEnvelope} />
                                    </InputGroup.Text>
                                    <Form.Control autoComplete="new-email" tabIndex="7" autoFocus required placeholder={userInfo.email} onChange={({ target }) => setUserInfo((prev) => ({...prev, email: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="password" className="mb-4">
                                <Form.Label><span>{langMap.ConfirmPasswordTitle[lang]}</span></Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faUnlockAlt} />
                                </InputGroup.Text>
                                <Form.Control autoComplete="new-password" tabIndex="9" required type="password" placeholder={langMap.ConfirmPasswordText[lang]} onChange={({ target }) => setUserInfo((prev) => ({...prev, check: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                    <Col xs={12}>
                        { 
                        error &&
                            <Row className="justify-content-center">
                                <Col xs={6}>
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <Alert variant="danger" dismissible={true} onClose={() => onDismiss()}>
                                            {error.split(", ").map((err, i)=> <p key={"err" + i} className="error-label">{errorMessages[err][lang]}</p>)}
                                        </Alert>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {
                        response &&
                            <Row className="justify-content-center">
                                <Col xs={6}>
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <Alert variant="success" dismissible={true} onClose={() => onDismiss()}>
                                            {response}
                                        </Alert>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col xs={8} xl={4}>
                        <Form.Group id="password" className="mb-6 mt-2">
                            <Form.Label><span>{langMap.CurrentPasswordTitle[lang]}</span></Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon className="text-secondary-lc" icon={faUnlockAlt} />
                                </InputGroup.Text>
                                <Form.Control tabIndex="10" required type="password" placeholder={langMap.CurrentPasswordText[lang]} onChange={({ target }) => setUserInfo((prev) => ({...prev, currentPassword: target.value}))}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-5 mt-2">
                    <Col xs={8} xl={4}>
                        <Button tabIndex="11" variant="secondary-lc" className="w-100 my-2" onClick={handleUpdate}>
                            {langMap.UpdateButtonText[lang]}
                        </Button>
                    </Col>
                </Row>
                <Row className="d-print-none pb-5">
                    <Col xs={12} className="text-center">
                        <ModalPopup
                          title={langMap.DeleteAccountFormTitle[lang]}
                          innerTitle={langMap.DeleteAccountFormInnerTitle[lang]}
                          textConfig={{ top: langMap.DeleteAccountFormTextConfigTop[lang], bottom: ``}}
                          textButton={langMap.DeleteAccountFormButtonText[lang]}
                          textButtonConfirm={langMap.DeleteAccountFormConfirmButtonText[lang]}
                          titleSuccess={langMap.DeleteAccountFormSuccessTitle[lang]}
                          textSuccess={langMap.DeleteAccountFormSuccessText[lang]}
                          iconSuccess={faEnvelopeCircleCheck}
                          errorConfig={{title: langMap.DeleteAccountFormErrorTitle[lang], text: langMap.DeleteAccountFormErrorText[lang], link: <a href="https://lightcast.io/contact" rel="noreferrer" className="text-secondary contact-modal" target="_blank"> {langMap.DeleteAccountFormErrorTextLink[lang]}</a>}}
                          loadImg={gifLoading}
                          buttonFunction={() => deleteAccount(document.getElementById('password-delete-check').value)}
                          sending={sendingRequest}
                          confirmed={confirmedRequest}
                          closeError={() => setErrorRequest(false)}
                          addictionalComponents={
                              <>
                                  <Row>
                                      <Col xs={1}></Col>
                                      <Col xs={10}>
                                          <Form.Group id="password" className="mb-4">
                                              <InputGroup>
                                                  <InputGroup.Text>
                                                      <FontAwesomeIcon className="text-secondary-lc" icon={faUnlockAlt} />
                                                  </InputGroup.Text>
                                                  <Form.Control required type="password" id="password-delete-check" placeholder={langMap.CurrentPasswordText[lang]}/>
                                              </InputGroup>
                                          </Form.Group>
                                      </Col>
                                  </Row>
                                  { 
                                      errorRequest &&
                                      <Row className="justify-content-center">
                                          <Col xs={10}>
                                              <div className="d-flex justify-content-between align-items-center mb-2">
                                                  <Alert variant="danger" dismissible={true} onClose={() => onDismiss()}>
                                                      {errorRequest.split(", ").map((err, i)=> <p key={"err" + i} className="error-label">{errorMessages[err][lang]}</p>)}
                                                  </Alert>
                                              </div>
                                          </Col>
                                      </Row>
                                  }
                              </>
                          }
                          icon={<FontAwesomeIcon icon={faUserXmark} className="icon-bookademo" />}
                          iconButton = { <FontAwesomeIcon icon={faTrashCan} /> }
                          classNameButton = "btn-primary-lc"
                        />
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default UserProfile;

export const AppRoutes = {
    // pages
    Presentation: { path: "/" },
    EmbeddableView: { path: "/embeddable-view" },
    CompletedBooking: { path: "/thank-you" },
    Signin: { path: "/sign-in" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Registration: {path: "/registration"},
    TermsAndConditions: {path: "/terms-and-conditions"},
    RegistrationConfirmation: {path: "/registration/confirm"},
    UserProfile: {path: "/profile"},
    ConfirmDeletingAccount: {path: "/opt-out/confirm"}
};
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { currentYear } from "../../config/dates";
import { AppRoutes } from '../../config/routes';
import {Link} from "react-router-dom";
import LanguageContext from "../../utils/context/i18n/languageContext";
import { useContext } from "react";
import { langMap } from "./i18n";

const Footer = () => {

    const lang = useContext(LanguageContext).lang;

    return(
        <footer className="w-100 footer py-5 bg-dark-lc text-white-lc single-tabledpage">
            <Container>
                <Row>
                    <Col className="mb-md-2">
                    <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                        <p className="font-weight-normal font-small mb-0">Copyright © Lightcast 2021-<span className="current-year">{ currentYear }</span>. All rights reserved.</p>
                    </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="mb-md-2">
                    <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                        <Nav className="justify-content-center" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link href="https://lightcast.io/euro/contact" target="_blank">{langMap.ContactUs[lang]}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={AppRoutes.TermsAndConditions.path} className="nav-link">{langMap.TermsAndConditions[lang]}</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://lightcast.io/privacy-policy" target="_blank">{langMap.PrivacyPolicy[lang]}</Nav.Link>
                        </Nav.Item>
                        </Nav>
                    </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default  Footer;
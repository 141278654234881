export const langMap = {
    "SignInButtonText": {
        "IT": "Accedi",
        "EN": "Sign in",
        "FR": "S'identifier",
        "ES": "Iniciar sesión",
        "DE": "Anmelden"
    },
    "SignOutButtonText": {
        "IT": "Esci",
        "EN": "Sign out",
        "FR": "Déconnexion",
        "ES": "Desconectar",
        "DE": "Ausloggen"
    },
    "MyProfileButtonText": {
        "IT": "Profilo",
        "EN": "My profile",
        "FR": "Mon profil",
        "ES": "Mi perfil",
        "DE": "Mein Profil"
    },
    "RegisterButtonText": {
        "IT": "Registrati",
        "EN": "Register",
        "FR": "S'inscrire",
        "ES": "Registrate",
        "DE": "Registrieren"
    }
}
import React, {useContext, useEffect, useState} from 'react';
import UserContextState from "./utils/context/user/userContextStateConfig";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/router/AppRouter"
import { userInfoFromJwtRequest } from "./utils/REST/requests";
import LanguageContextState from './utils/context/i18n/languageContextStateConfig';
import FiltersContextState from './utils/context/filters/filtersContextStateConfig';
import { initialCountryObject } from './config/widgets/selectors/availableCountries';
import {initialOccupationObject } from './config/widgets/selectors/availableOccupationsByCountry';
import LanguageContext from './utils/context/i18n/languageContext';
import { langMap } from './config/widgets/selectors/i18n';

const getUserInfoFromJwt = async() => {
    // console.log("Checking tokens...")
  
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const hideProfile = localStorage.getItem("hideProfile");
  
    if(token && refreshToken){
      const response = await userInfoFromJwtRequest(token, refreshToken);
      if (response.status === 200){
        let user = response.user
        if (!user.lastProfile && user.profiles.length){
            user.lastProfile = user.profiles[0].id
        }
        if (hideProfile) {
            user.hideProfile = true;
        }
        return {user: user, isAuth: true, error: ""};
      }
      // refresh token expired
      if (response.status === 401){
        return {user: null, isAuth: false, error: response.error}
      }
      // service down
      return {user:null, isAuth: false, error: response.error}
    };
    // no tokens in local storage
    return {user: null, isAuth: false, error: ""};
};

const FiltersContextProvider = ({children}) => {
    const lang = useContext(LanguageContext).lang;
    
    let country = initialCountryObject;
    let occupation = initialOccupationObject;
    let occs = [];
    country.name = langMap.PickACountry[lang];
    occupation.name = langMap.ChooseAnOccupation[lang];
  
    const storageCountry = localStorage.getItem("selectedCountry");
    const storageOcc = localStorage.getItem("selectedOccupation");
    const storageOccs = localStorage.getItem("selectedOccupationsList");
    
    if (storageCountry && storageOcc && storageOccs){
        country = JSON.parse(storageCountry);
        occupation = JSON.parse(storageOcc);
        occs = JSON.parse(storageOccs);
    }


    return (
        <FiltersContextState start={{selectedCountry: country, selectedOccupation: occupation, displayedOccupations: occs}}>
            {children}
        </FiltersContextState>
    )
}

const App = () => {
    const [startingState, setStartingState] = useState({user: null, isAuth: false, error: ""});
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        (async () => {
            const start = await getUserInfoFromJwt();
            setStartingState(start);
            setLoading(false);
        })();
    }, []);

    if(!loading){
        return (
            <UserContextState start={startingState}>
                <LanguageContextState >
                    <FiltersContextProvider>
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                    </FiltersContextProvider>
                </LanguageContextState>
            </UserContextState>
        );
    };
    return <p>Loading</p>
};

export default App;

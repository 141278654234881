export const allOccupations = [
    {
        "code": "2411",
        "name": "Accountants",
        "disabled": true
    },
    {
        "code": "4311",
        "name": "Accounting and bookkeeping clerks",
        "disabled": true
    },
    {
        "code": "3313",
        "name": "Accounting associate professionals",
        "disabled": true
    },
    {
        "code": "2655",
        "name": "Actors",
        "disabled": true
    },
    {
        "code": "3343",
        "name": "Administrative and executive secretaries",
        "disabled": true
    },
    {
        "code": "2431",
        "name": "Advertising and marketing professionals",
        "disabled": true
    },
    {
        "code": "1222",
        "name": "Advertising and public relations managers",
        "disabled": true
    },
    {
        "code": "1343",
        "name": "Aged care services managers",
        "disabled": true
    },
    {
        "code": "1311",
        "name": "Agricultural and forestry production managers",
        "disabled": true
    },
    {
        "code": "7233",
        "name": "Agricultural and industrial machinery mechanics and repairers",
        "disabled": true
    },
    {
        "code": "3142",
        "name": "Agricultural technicians",
        "disabled": true
    },
    {
        "code": "7127",
        "name": "Air conditioning and refrigeration mechanics",
        "disabled": true
    },
    {
        "code": "3154",
        "name": "Air traffic controllers",
        "disabled": true
    },
    {
        "code": "3155",
        "name": "Air traffic safety electronics technicians",
        "disabled": true
    },
    {
        "code": "7232",
        "name": "Aircraft engine mechanics and repairers",
        "disabled": true
    },
    {
        "code": "3153",
        "name": "Aircraft pilots and related associate professionals",
        "disabled": true
    },
    {
        "code": "3258",
        "name": "Ambulance workers",
        "disabled": true
    },
    {
        "code": "6129",
        "name": "Animal producers (general)",
        "disabled": true
    },
    {
        "code": "2656",
        "name": "Announcers on radio, television and other media",
        "disabled": true
    },
    {
        "code": "6123",
        "name": "Apiarists and sericulturists",
        "disabled": true
    },
    {
        "code": "2514",
        "name": "Applications programmers",
        "disabled": true
    },
    {
        "code": "1312",
        "name": "Aquaculture and fisheries production managers",
        "disabled": true
    },
    {
        "code": "6221",
        "name": "Aquaculture workers",
        "disabled": true
    },
    {
        "code": "2621",
        "name": "Archivists and curators",
        "disabled": true
    },
    {
        "code": "310",
        "name": "Armed forces occupations, other ranks",
        "disabled": true
    },
    {
        "code": "8219",
        "name": "Assemblers (general)",
        "disabled": true
    },
    {
        "code": "5161",
        "name": "Astrologers, fortune-tellers and related workers",
        "disabled": true
    },
    {
        "code": "3421",
        "name": "Athletes and sports players",
        "disabled": true
    },
    {
        "code": "2266",
        "name": "Audiologists and speech therapists",
        "disabled": true
    },
    {
        "code": "2641",
        "name": "Authors and related writers",
        "disabled": true
    },
    {
        "code": "7512",
        "name": "Bakers, pastry-cooks and confectionery makers",
        "disabled": true
    },
    {
        "code": "4211",
        "name": "Bank tellers and related clerks",
        "disabled": true
    },
    {
        "code": "5132",
        "name": "Bartenders",
        "disabled": true
    },
    {
        "code": "5142",
        "name": "Beauticians and related workers",
        "disabled": true
    },
    {
        "code": "7234",
        "name": "Bicycle and related repairers",
        "disabled": true
    },
    {
        "code": "2131",
        "name": "Biologists, botanists, zoologists and related professionals",
        "disabled": true
    },
    {
        "code": "7221",
        "name": "Blacksmiths, hammersmiths and forging press workers",
        "disabled": true
    },
    {
        "code": "8154",
        "name": "Bleaching, dyeing and fabric cleaning machine operators",
        "disabled": true
    },
    {
        "code": "4212",
        "name": "Bookmakers, croupiers and related gaming workers",
        "disabled": true
    },
    {
        "code": "7112",
        "name": "Bricklayers and related workers",
        "disabled": true
    },
    {
        "code": "3521",
        "name": "Broadcasting and audio-visual technicians",
        "disabled": true
    },
    {
        "code": "7411",
        "name": "Building and related electricians",
        "disabled": true
    },
    {
        "code": "2161",
        "name": "Building architects",
        "disabled": true
    },
    {
        "code": "5153",
        "name": "Building caretakers",
        "disabled": true
    },
    {
        "code": "9313",
        "name": "Building construction labourers",
        "disabled": true
    },
    {
        "code": "7119",
        "name": "Building frame and related trades workers (general)",
        "disabled": true
    },
    {
        "code": "7133",
        "name": "Building structure cleaners",
        "disabled": true
    },
    {
        "code": "8331",
        "name": "Bus and tram drivers",
        "disabled": true
    },
    {
        "code": "3339",
        "name": "Business services agents (general)",
        "disabled": true
    },
    {
        "code": "1219",
        "name": "Business services and administration managers (general)",
        "disabled": true
    },
    {
        "code": "7511",
        "name": "Butchers, fishmongers and related food preparers",
        "disabled": true
    },
    {
        "code": "3323",
        "name": "Buyers",
        "disabled": true
    },
    {
        "code": "7522",
        "name": "Cabinet-makers and related workers",
        "disabled": true
    },
    {
        "code": "8322",
        "name": "Car, taxi and van drivers",
        "disabled": true
    },
    {
        "code": "7115",
        "name": "Carpenters and joiners",
        "disabled": true
    },
    {
        "code": "2165",
        "name": "Cartographers and surveyors",
        "disabled": true
    },
    {
        "code": "5230",
        "name": "Cashiers and ticket clerks",
        "disabled": true
    },
    {
        "code": "8114",
        "name": "Cement, stone and other mineral products machine operators",
        "disabled": true
    },
    {
        "code": "3434",
        "name": "Chefs",
        "disabled": true
    },
    {
        "code": "3111",
        "name": "Chemical and physical science technicians",
        "disabled": true
    },
    {
        "code": "3116",
        "name": "Chemical engineering technicians",
        "disabled": true
    },
    {
        "code": "2145",
        "name": "Chemical engineers",
        "disabled": true
    },
    {
        "code": "3133",
        "name": "Chemical processing plant controllers",
        "disabled": true
    },
    {
        "code": "8131",
        "name": "Chemical products plant and machine operators",
        "disabled": true
    },
    {
        "code": "2113",
        "name": "Chemists",
        "disabled": true
    },
    {
        "code": "1341",
        "name": "Child care services managers",
        "disabled": true
    },
    {
        "code": "5311",
        "name": "Child care workers",
        "disabled": true
    },
    {
        "code": "9312",
        "name": "Civil engineering labourers",
        "disabled": true
    },
    {
        "code": "3112",
        "name": "Civil engineering technicians",
        "disabled": true
    },
    {
        "code": "2142",
        "name": "Civil engineers",
        "disabled": true
    },
    {
        "code": "9112",
        "name": "Cleaners and helpers in offices, hotels and other establishments",
        "disabled": true
    },
    {
        "code": "5151",
        "name": "Cleaning and housekeeping supervisors in offices, hotels and other establishments",
        "disabled": true
    },
    {
        "code": "3331",
        "name": "Clearing and forwarding agents",
        "disabled": true
    },
    {
        "code": "4419",
        "name": "Clerical support workers (general)",
        "disabled": true
    },
    {
        "code": "4229",
        "name": "Client information workers (general)",
        "disabled": true
    },
    {
        "code": "4413",
        "name": "Coding, proof-reading and related clerks",
        "disabled": true
    },
    {
        "code": "3322",
        "name": "Commercial sales representatives",
        "disabled": true
    },
    {
        "code": "110",
        "name": "Commissioned armed forces officers",
        "disabled": true
    },
    {
        "code": "3253",
        "name": "Community health workers",
        "disabled": true
    },
    {
        "code": "5162",
        "name": "Companions and valets",
        "disabled": true
    },
    {
        "code": "3513",
        "name": "Computer network and systems technicians",
        "disabled": true
    },
    {
        "code": "2523",
        "name": "Computer network professionals",
        "disabled": true
    },
    {
        "code": "7114",
        "name": "Concrete placers, concrete finishers and related workers",
        "disabled": true
    },
    {
        "code": "3332",
        "name": "Conference and event planners",
        "disabled": true
    },
    {
        "code": "1323",
        "name": "Construction managers",
        "disabled": true
    },
    {
        "code": "3123",
        "name": "Construction supervisors",
        "disabled": true
    },
    {
        "code": "4222",
        "name": "Contact centre information clerks",
        "disabled": true
    },
    {
        "code": "5244",
        "name": "Contact centre salespersons",
        "disabled": true
    },
    {
        "code": "5120",
        "name": "Cooks",
        "disabled": true
    },
    {
        "code": "7549",
        "name": "Craft and related workers (general)",
        "disabled": true
    },
    {
        "code": "8343",
        "name": "Crane, hoist and related plant operators",
        "disabled": true
    },
    {
        "code": "2659",
        "name": "Creative and performing artists (general)",
        "disabled": true
    },
    {
        "code": "3312",
        "name": "Credit and loans officers",
        "disabled": true
    },
    {
        "code": "9211",
        "name": "Crop farm labourers",
        "disabled": true
    },
    {
        "code": "3351",
        "name": "Customs and border inspectors",
        "disabled": true
    },
    {
        "code": "7513",
        "name": "Dairy-products makers",
        "disabled": true
    },
    {
        "code": "2653",
        "name": "Dancers and choreographers",
        "disabled": true
    },
    {
        "code": "4132",
        "name": "Data entry clerks",
        "disabled": true
    },
    {
        "code": "2529",
        "name": "Database and network professionals (general)",
        "disabled": true
    },
    {
        "code": "2521",
        "name": "Database designers and administrators",
        "disabled": true
    },
    {
        "code": "4214",
        "name": "Debt-collectors and related workers",
        "disabled": true
    },
    {
        "code": "6223",
        "name": "Deep-sea fishery workers",
        "disabled": true
    },
    {
        "code": "3251",
        "name": "Dental assistants and therapists",
        "disabled": true
    },
    {
        "code": "2261",
        "name": "Dentists",
        "disabled": true
    },
    {
        "code": "2265",
        "name": "Dieticians and nutritionists",
        "disabled": true
    },
    {
        "code": "3254",
        "name": "Dispensing opticians",
        "disabled": true
    },
    {
        "code": "9111",
        "name": "Domestic cleaners and helpers",
        "disabled": true
    },
    {
        "code": "5152",
        "name": "Domestic housekeepers",
        "disabled": true
    },
    {
        "code": "5243",
        "name": "Door to door salespersons",
        "disabled": true
    },
    {
        "code": "3118",
        "name": "Draughtspersons",
        "disabled": true
    },
    {
        "code": "9332",
        "name": "Drivers of animal-drawn vehicles and machinery",
        "disabled": true
    },
    {
        "code": "5165",
        "name": "Driving instructors",
        "disabled": true
    },
    {
        "code": "2342",
        "name": "Early childhood educators",
        "disabled": true
    },
    {
        "code": "8342",
        "name": "Earthmoving and related plant operators",
        "disabled": true
    },
    {
        "code": "2631",
        "name": "Economists",
        "disabled": true
    },
    {
        "code": "1345",
        "name": "Education managers",
        "disabled": true
    },
    {
        "code": "2351",
        "name": "Education methods specialists",
        "disabled": true
    },
    {
        "code": "8212",
        "name": "Electrical and electronic equipment assemblers",
        "disabled": true
    },
    {
        "code": "3113",
        "name": "Electrical engineering technicians",
        "disabled": true
    },
    {
        "code": "2151",
        "name": "Electrical engineers",
        "disabled": true
    },
    {
        "code": "7413",
        "name": "Electrical line installers and repairers ",
        "disabled": true
    },
    {
        "code": "7412",
        "name": "Electrical mechanics and fitters",
        "disabled": true
    },
    {
        "code": "3114",
        "name": "Electronics engineering technicians",
        "disabled": true
    },
    {
        "code": "2152",
        "name": "Electronics engineers",
        "disabled": true
    },
    {
        "code": "7421",
        "name": "Electronics mechanics and servicers",
        "disabled": true
    },
    {
        "code": "9629",
        "name": "Elementary workers (general)",
        "disabled": true
    },
    {
        "code": "3333",
        "name": "Employment agents and contractors",
        "disabled": true
    },
    {
        "code": "2149",
        "name": "Engineering professionals (general)",
        "disabled": true
    },
    {
        "code": "4225",
        "name": "Enquiry clerks",
        "disabled": true
    },
    {
        "code": "2263",
        "name": "Environmental and occupational health and hygiene professionals",
        "disabled": true
    },
    {
        "code": "3257",
        "name": "Environmental and occupational health inspectors and associates",
        "disabled": true
    },
    {
        "code": "2143",
        "name": "Environmental engineers",
        "disabled": true
    },
    {
        "code": "2133",
        "name": "Environmental protection professionals",
        "disabled": true
    },
    {
        "code": "2132",
        "name": "Farming, forestry and fisheries advisers",
        "disabled": true
    },
    {
        "code": "5241",
        "name": "Fashion and other models",
        "disabled": true
    },
    {
        "code": "9411",
        "name": "Fast food preparers",
        "disabled": true
    },
    {
        "code": "8151",
        "name": "Fibre preparing, spinning and winding machine operators",
        "disabled": true
    },
    {
        "code": "6111",
        "name": "Field crop and vegetable growers",
        "disabled": true
    },
    {
        "code": "4415",
        "name": "Filing and copying clerks",
        "disabled": true
    },
    {
        "code": "2654",
        "name": "Film, stage and related directors and producers",
        "disabled": true
    },
    {
        "code": "1211",
        "name": "Finance managers",
        "disabled": true
    },
    {
        "code": "2413",
        "name": "Financial analysts",
        "disabled": true
    },
    {
        "code": "1346",
        "name": "Financial and insurance services branch managers",
        "disabled": true
    },
    {
        "code": "2412",
        "name": "Financial and investment advisers",
        "disabled": true
    },
    {
        "code": "5411",
        "name": "Fire-fighters",
        "disabled": true
    },
    {
        "code": "9216",
        "name": "Fishery and aquaculture labourers",
        "disabled": true
    },
    {
        "code": "3423",
        "name": "Fitness and recreation instructors and program leaders",
        "disabled": true
    },
    {
        "code": "7122",
        "name": "Floor layers and tile setters",
        "disabled": true
    },
    {
        "code": "7515",
        "name": "Food and beverage tasters and graders",
        "disabled": true
    },
    {
        "code": "8160",
        "name": "Food and related products machine operators",
        "disabled": true
    },
    {
        "code": "5246",
        "name": "Food service counter attendants",
        "disabled": true
    },
    {
        "code": "6210",
        "name": "Forestry and related workers",
        "disabled": true
    },
    {
        "code": "9215",
        "name": "Forestry labourers",
        "disabled": true
    },
    {
        "code": "3143",
        "name": "Forestry technicians",
        "disabled": true
    },
    {
        "code": "9333",
        "name": "Freight handlers",
        "disabled": true
    },
    {
        "code": "7514",
        "name": "Fruit, vegetable and related preservers",
        "disabled": true
    },
    {
        "code": "7544",
        "name": "Fumigators and other pest and weed controllers",
        "disabled": true
    },
    {
        "code": "8155",
        "name": "Fur and leather preparing machine operators",
        "disabled": true
    },
    {
        "code": "3433",
        "name": "Gallery, museum and library technicians",
        "disabled": true
    },
    {
        "code": "9611",
        "name": "Garbage and recycling collectors",
        "disabled": true
    },
    {
        "code": "9214",
        "name": "Garden and horticultural labourers",
        "disabled": true
    },
    {
        "code": "6113",
        "name": "Gardeners, horticultural and nursery growers",
        "disabled": true
    },
    {
        "code": "7532",
        "name": "Garment and related pattern-makers and cutters",
        "disabled": true
    },
    {
        "code": "4110",
        "name": "General office clerks",
        "disabled": true
    },
    {
        "code": "2211",
        "name": "Generalist medical practitioners ",
        "disabled": true
    },
    {
        "code": "2114",
        "name": "Geologists and geophysicists",
        "disabled": true
    },
    {
        "code": "8181",
        "name": "Glass and ceramics plant operators",
        "disabled": true
    },
    {
        "code": "7315",
        "name": "Glass makers, cutters, grinders and finishers",
        "disabled": true
    },
    {
        "code": "7125",
        "name": "Glaziers",
        "disabled": true
    },
    {
        "code": "3354",
        "name": "Government licensing officials",
        "disabled": true
    },
    {
        "code": "3353",
        "name": "Government social benefits officials",
        "disabled": true
    },
    {
        "code": "3352",
        "name": "Government tax and excise officials",
        "disabled": true
    },
    {
        "code": "2166",
        "name": "Graphic and multimedia designers",
        "disabled": true
    },
    {
        "code": "5141",
        "name": "Hairdressers",
        "disabled": true
    },
    {
        "code": "9331",
        "name": "Hand and pedal vehicle drivers",
        "disabled": true
    },
    {
        "code": "9121",
        "name": "Hand launderers and pressers",
        "disabled": true
    },
    {
        "code": "9321",
        "name": "Hand packers",
        "disabled": true
    },
    {
        "code": "7319",
        "name": "Handicraft workers (general)",
        "disabled": true
    },
    {
        "code": "7318",
        "name": "Handicraft workers in textile, leather and related materials",
        "disabled": true
    },
    {
        "code": "7317",
        "name": "Handicraft workers in wood, basketry and related materials",
        "disabled": true
    },
    {
        "code": "3259",
        "name": "Health associate professionals (general)",
        "disabled": true
    },
    {
        "code": "5321",
        "name": "Health care assistants",
        "disabled": true
    },
    {
        "code": "2269",
        "name": "Health professionals (general)",
        "disabled": true
    },
    {
        "code": "1342",
        "name": "Health services managers",
        "disabled": true
    },
    {
        "code": "8332",
        "name": "Heavy truck and lorry drivers",
        "disabled": true
    },
    {
        "code": "5322",
        "name": "Home-based personal care workers",
        "disabled": true
    },
    {
        "code": "1411",
        "name": "Hotel managers",
        "disabled": true
    },
    {
        "code": "4224",
        "name": "Hotel receptionists",
        "disabled": true
    },
    {
        "code": "7111",
        "name": "House builders",
        "disabled": true
    },
    {
        "code": "1212",
        "name": "Human resource managers",
        "disabled": true
    },
    {
        "code": "6224",
        "name": "Hunters and trappers",
        "disabled": true
    },
    {
        "code": "3132",
        "name": "Incinerator and water treatment plant operators",
        "disabled": true
    },
    {
        "code": "2141",
        "name": "Industrial and production engineers",
        "disabled": true
    },
    {
        "code": "7422",
        "name": "Information and communications technology installers and servicers",
        "disabled": true
    },
    {
        "code": "3511",
        "name": "Information and communications technology operations technicians",
        "disabled": true
    },
    {
        "code": "2434",
        "name": "Information and communications technology sales professionals",
        "disabled": true
    },
    {
        "code": "1330",
        "name": "Information and communications technology service managers",
        "disabled": true
    },
    {
        "code": "3512",
        "name": "Information and communications technology user support technicians",
        "disabled": true
    },
    {
        "code": "2356",
        "name": "Information technology trainers",
        "disabled": true
    },
    {
        "code": "6222",
        "name": "Inland and coastal waters fishery workers",
        "disabled": true
    },
    {
        "code": "7124",
        "name": "Insulation workers",
        "disabled": true
    },
    {
        "code": "3321",
        "name": "Insurance representatives",
        "disabled": true
    },
    {
        "code": "3432",
        "name": "Interior designers and decorators",
        "disabled": true
    },
    {
        "code": "7313",
        "name": "Jewellery and precious-metal workers",
        "disabled": true
    },
    {
        "code": "2642",
        "name": "Journalists",
        "disabled": true
    },
    {
        "code": "2612",
        "name": "Judges",
        "disabled": true
    },
    {
        "code": "9412",
        "name": "Kitchen helpers",
        "disabled": true
    },
    {
        "code": "2162",
        "name": "Landscape architects",
        "disabled": true
    },
    {
        "code": "8157",
        "name": "Laundry machine operators",
        "disabled": true
    },
    {
        "code": "2611",
        "name": "Lawyers",
        "disabled": true
    },
    {
        "code": "3411",
        "name": "Legal and related associate professionals",
        "disabled": true
    },
    {
        "code": "2619",
        "name": "Legal professionals (general)",
        "disabled": true
    },
    {
        "code": "3342",
        "name": "Legal secretaries",
        "disabled": true
    },
    {
        "code": "1111",
        "name": "Legislators",
        "disabled": true
    },
    {
        "code": "2622",
        "name": "Librarians and related information professionals",
        "disabled": true
    },
    {
        "code": "4411",
        "name": "Library clerks",
        "disabled": true
    },
    {
        "code": "3141",
        "name": "Life science technicians (excluding medical)",
        "disabled": true
    },
    {
        "code": "8344",
        "name": "Lifting truck operators",
        "disabled": true
    },
    {
        "code": "6121",
        "name": "Livestock and dairy producers",
        "disabled": true
    },
    {
        "code": "9212",
        "name": "Livestock farm labourers",
        "disabled": true
    },
    {
        "code": "8311",
        "name": "Locomotive engine drivers",
        "disabled": true
    },
    {
        "code": "4412",
        "name": "Mail carriers and sorting clerks",
        "disabled": true
    },
    {
        "code": "2421",
        "name": "Management and organization analysts",
        "disabled": true
    },
    {
        "code": "1120",
        "name": "Managing directors and chief executives",
        "disabled": true
    },
    {
        "code": "9329",
        "name": "Manufacturing labourers (general)",
        "disabled": true
    },
    {
        "code": "1321",
        "name": "Manufacturing managers",
        "disabled": true
    },
    {
        "code": "3122",
        "name": "Manufacturing supervisors",
        "disabled": true
    },
    {
        "code": "2120",
        "name": "Mathematicians, actuaries and statisticians",
        "disabled": true
    },
    {
        "code": "3115",
        "name": "Mechanical engineering technicians",
        "disabled": true
    },
    {
        "code": "2144",
        "name": "Mechanical engineers",
        "disabled": true
    },
    {
        "code": "8211",
        "name": "Mechanical machinery assemblers",
        "disabled": true
    },
    {
        "code": "3214",
        "name": "Medical and dental prosthetic technicians",
        "disabled": true
    },
    {
        "code": "3212",
        "name": "Medical and pathology laboratory technicians ",
        "disabled": true
    },
    {
        "code": "3256",
        "name": "Medical assistants",
        "disabled": true
    },
    {
        "code": "3211",
        "name": "Medical imaging and therapeutic equipment technicians",
        "disabled": true
    },
    {
        "code": "3252",
        "name": "Medical records and health information technicians",
        "disabled": true
    },
    {
        "code": "3344",
        "name": "Medical secretaries",
        "disabled": true
    },
    {
        "code": "9621",
        "name": "Messengers, package deliverers and luggage porters",
        "disabled": true
    },
    {
        "code": "8122",
        "name": "Metal finishing, plating and coating machine operators",
        "disabled": true
    },
    {
        "code": "7211",
        "name": "Metal moulders and coremakers",
        "disabled": true
    },
    {
        "code": "7224",
        "name": "Metal polishers, wheel grinders and tool sharpeners",
        "disabled": true
    },
    {
        "code": "8121",
        "name": "Metal processing plant operators",
        "disabled": true
    },
    {
        "code": "3135",
        "name": "Metal production process controllers",
        "disabled": true
    },
    {
        "code": "7223",
        "name": "Metal working machine tool setters and operators",
        "disabled": true
    },
    {
        "code": "2112",
        "name": "Meteorologists",
        "disabled": true
    },
    {
        "code": "9623",
        "name": "Meter readers and vending-machine collectors",
        "disabled": true
    },
    {
        "code": "3222",
        "name": "Midwifery associate professionals",
        "disabled": true
    },
    {
        "code": "2222",
        "name": "Midwifery professionals",
        "disabled": true
    },
    {
        "code": "8112",
        "name": "Mineral and stone processing plant operators",
        "disabled": true
    },
    {
        "code": "8111",
        "name": "Miners and quarriers",
        "disabled": true
    },
    {
        "code": "3117",
        "name": "Mining and metallurgical technicians",
        "disabled": true
    },
    {
        "code": "9311",
        "name": "Mining and quarrying labourers",
        "disabled": true
    },
    {
        "code": "2146",
        "name": "Mining engineers, metallurgists and related professionals",
        "disabled": true
    },
    {
        "code": "1322",
        "name": "Mining managers",
        "disabled": true
    },
    {
        "code": "3121",
        "name": "Mining supervisors",
        "disabled": true
    },
    {
        "code": "6130",
        "name": "Mixed crop and animal producers",
        "disabled": true
    },
    {
        "code": "9213",
        "name": "Mixed crop and livestock farm labourers",
        "disabled": true
    },
    {
        "code": "6114",
        "name": "Mixed crop growers",
        "disabled": true
    },
    {
        "code": "8341",
        "name": "Mobile farm and forestry plant operators",
        "disabled": true
    },
    {
        "code": "7231",
        "name": "Motor vehicle mechanics and repairers",
        "disabled": true
    },
    {
        "code": "8321",
        "name": "Motorcycle drivers",
        "disabled": true
    },
    {
        "code": "7312",
        "name": "Musical instrument makers and tuners",
        "disabled": true
    },
    {
        "code": "2652",
        "name": "Musicians, singers and composers",
        "disabled": true
    },
    {
        "code": "210",
        "name": "Non-commissioned armed forces officers",
        "disabled": true
    },
    {
        "code": "3221",
        "name": "Nursing associate professionals",
        "disabled": true
    },
    {
        "code": "2221",
        "name": "Nursing professionals",
        "disabled": true
    },
    {
        "code": "9622",
        "name": "Odd job persons",
        "disabled": true
    },
    {
        "code": "3341",
        "name": "Office supervisors",
        "disabled": true
    },
    {
        "code": "2267",
        "name": "Optometrists and ophthalmic opticians",
        "disabled": true
    },
    {
        "code": "3435",
        "name": "Other artistic and cultural associate professionals",
        "disabled": true
    },
    {
        "code": "2355",
        "name": "Other arts teachers",
        "disabled": true
    },
    {
        "code": "9129",
        "name": "Other cleaning workers",
        "disabled": true
    },
    {
        "code": "2353",
        "name": "Other language teachers",
        "disabled": true
    },
    {
        "code": "2354",
        "name": "Other music teachers",
        "disabled": true
    },
    {
        "code": "8183",
        "name": "Packing, bottling and labelling machine operators",
        "disabled": true
    },
    {
        "code": "7131",
        "name": "Painters and related workers",
        "disabled": true
    },
    {
        "code": "8143",
        "name": "Paper products machine operators",
        "disabled": true
    },
    {
        "code": "2240",
        "name": "Paramedical practitioners",
        "disabled": true
    },
    {
        "code": "4213",
        "name": "Pawnbrokers and money-lenders",
        "disabled": true
    },
    {
        "code": "4313",
        "name": "Payroll clerks",
        "disabled": true
    },
    {
        "code": "7535",
        "name": "Pelt dressers, tanners and fellmongers",
        "disabled": true
    },
    {
        "code": "5329",
        "name": "Personal care workers in health services (general)",
        "disabled": true
    },
    {
        "code": "5169",
        "name": "Personal services workers (general)",
        "disabled": true
    },
    {
        "code": "2423",
        "name": "Personnel and careers professionals",
        "disabled": true
    },
    {
        "code": "4416",
        "name": "Personnel clerks",
        "disabled": true
    },
    {
        "code": "5164",
        "name": "Pet groomers and animal care workers",
        "disabled": true
    },
    {
        "code": "3134",
        "name": "Petroleum and natural gas refining plant operators",
        "disabled": true
    },
    {
        "code": "3213",
        "name": "Pharmaceutical technicians and assistants",
        "disabled": true
    },
    {
        "code": "2262",
        "name": "Pharmacists",
        "disabled": true
    },
    {
        "code": "2633",
        "name": "Philosophers, historians and political scientists",
        "disabled": true
    },
    {
        "code": "3431",
        "name": "Photographers",
        "disabled": true
    },
    {
        "code": "8132",
        "name": "Photographic products machine operators",
        "disabled": true
    },
    {
        "code": "3119",
        "name": "Physical and engineering science technicians (general)",
        "disabled": true
    },
    {
        "code": "2111",
        "name": "Physicists and astronomers",
        "disabled": true
    },
    {
        "code": "2264",
        "name": "Physiotherapists ",
        "disabled": true
    },
    {
        "code": "3255",
        "name": "Physiotherapy technicians and assistants",
        "disabled": true
    },
    {
        "code": "7123",
        "name": "Plasterers",
        "disabled": true
    },
    {
        "code": "8142",
        "name": "Plastic products machine operators",
        "disabled": true
    },
    {
        "code": "7126",
        "name": "Plumbers and pipe fitters",
        "disabled": true
    },
    {
        "code": "3355",
        "name": "Police inspectors and detectives",
        "disabled": true
    },
    {
        "code": "5412",
        "name": "Police officers",
        "disabled": true
    },
    {
        "code": "2422",
        "name": "Policy administration professionals",
        "disabled": true
    },
    {
        "code": "1213",
        "name": "Policy and planning managers",
        "disabled": true
    },
    {
        "code": "7314",
        "name": "Potters and related workers",
        "disabled": true
    },
    {
        "code": "6122",
        "name": "Poultry producers",
        "disabled": true
    },
    {
        "code": "3131",
        "name": "Power production plant operators",
        "disabled": true
    },
    {
        "code": "7321",
        "name": "Pre-press technicians",
        "disabled": true
    },
    {
        "code": "7311",
        "name": "Precision-instrument makers and repairers",
        "disabled": true
    },
    {
        "code": "2341",
        "name": "Primary school teachers",
        "disabled": true
    },
    {
        "code": "7323",
        "name": "Print finishing and binding workers",
        "disabled": true
    },
    {
        "code": "7322",
        "name": "Printers ",
        "disabled": true
    },
    {
        "code": "5413",
        "name": "Prison guards",
        "disabled": true
    },
    {
        "code": "3139",
        "name": "Process control technicians (general)",
        "disabled": true
    },
    {
        "code": "2163",
        "name": "Product and garment designers",
        "disabled": true
    },
    {
        "code": "7543",
        "name": "Product graders and testers (excluding foods and beverages)",
        "disabled": true
    },
    {
        "code": "4322",
        "name": "Production clerks",
        "disabled": true
    },
    {
        "code": "1349",
        "name": "Professional services managers (general)",
        "disabled": true
    },
    {
        "code": "5419",
        "name": "Protective services workers (general)",
        "disabled": true
    },
    {
        "code": "2634",
        "name": "Psychologists",
        "disabled": true
    },
    {
        "code": "2432",
        "name": "Public relations professionals",
        "disabled": true
    },
    {
        "code": "8171",
        "name": "Pulp and papermaking plant operators",
        "disabled": true
    },
    {
        "code": "8312",
        "name": "Railway brake, signal and switch operators",
        "disabled": true
    },
    {
        "code": "3334",
        "name": "Real estate agents and property managers",
        "disabled": true
    },
    {
        "code": "4226",
        "name": "Receptionists (general)",
        "disabled": true
    },
    {
        "code": "9612",
        "name": "Refuse sorters",
        "disabled": true
    },
    {
        "code": "3359",
        "name": "Regulatory government associate professionals (general)",
        "disabled": true
    },
    {
        "code": "3413",
        "name": "Religious associate professionals",
        "disabled": true
    },
    {
        "code": "2636",
        "name": "Religious professionals",
        "disabled": true
    },
    {
        "code": "1223",
        "name": "Research and development managers",
        "disabled": true
    },
    {
        "code": "1412",
        "name": "Restaurant managers",
        "disabled": true
    },
    {
        "code": "1420",
        "name": "Retail and wholesale trade managers",
        "disabled": true
    },
    {
        "code": "7215",
        "name": "Riggers and cable splicers",
        "disabled": true
    },
    {
        "code": "7121",
        "name": "Roofers",
        "disabled": true
    },
    {
        "code": "8141",
        "name": "Rubber products machine operators",
        "disabled": true
    },
    {
        "code": "1221",
        "name": "Sales and marketing managers",
        "disabled": true
    },
    {
        "code": "5242",
        "name": "Sales demonstrators",
        "disabled": true
    },
    {
        "code": "5249",
        "name": "Sales workers (general)",
        "disabled": true
    },
    {
        "code": "4414",
        "name": "Scribes and related workers",
        "disabled": true
    },
    {
        "code": "2330",
        "name": "Secondary education teachers",
        "disabled": true
    },
    {
        "code": "4120",
        "name": "Secretaries (general)",
        "disabled": true
    },
    {
        "code": "3311",
        "name": "Securities and finance dealers and brokers",
        "disabled": true
    },
    {
        "code": "5414",
        "name": "Security guards",
        "disabled": true
    },
    {
        "code": "1112",
        "name": "Senior government officials",
        "disabled": true
    },
    {
        "code": "1114",
        "name": "Senior officials of special-interest organizations",
        "disabled": true
    },
    {
        "code": "5245",
        "name": "Service station attendants",
        "disabled": true
    },
    {
        "code": "1439",
        "name": "Services managers (general)",
        "disabled": true
    },
    {
        "code": "8153",
        "name": "Sewing machine operators",
        "disabled": true
    },
    {
        "code": "7533",
        "name": "Sewing, embroidery and related workers",
        "disabled": true
    },
    {
        "code": "7213",
        "name": "Sheet-metal workers",
        "disabled": true
    },
    {
        "code": "9334",
        "name": "Shelf fillers",
        "disabled": true
    },
    {
        "code": "8350",
        "name": "Ships\u201a\u00c4\u00f4 deck crews and related workers",
        "disabled": true
    },
    {
        "code": "3152",
        "name": "Ships\u201a\u00c4\u00f4 deck officers and pilots",
        "disabled": true
    },
    {
        "code": "3151",
        "name": "Ships\u201a\u00c4\u00f4 engineers",
        "disabled": true
    },
    {
        "code": "7536",
        "name": "Shoemakers and related workers",
        "disabled": true
    },
    {
        "code": "8156",
        "name": "Shoemaking and related machine operators",
        "disabled": true
    },
    {
        "code": "5221",
        "name": "Shop keepers",
        "disabled": true
    },
    {
        "code": "5223",
        "name": "Shop sales assistants",
        "disabled": true
    },
    {
        "code": "5222",
        "name": "Shop supervisors",
        "disabled": true
    },
    {
        "code": "7542",
        "name": "Shotfirers and blasters",
        "disabled": true
    },
    {
        "code": "7316",
        "name": "Sign writers, decorative painters, engravers and etchers",
        "disabled": true
    },
    {
        "code": "1344",
        "name": "Social welfare managers",
        "disabled": true
    },
    {
        "code": "2635",
        "name": "Social work and counselling professionals",
        "disabled": true
    },
    {
        "code": "3412",
        "name": "Social work associate professionals",
        "disabled": true
    },
    {
        "code": "2632",
        "name": "Sociologists, anthropologists and related professionals",
        "disabled": true
    },
    {
        "code": "2519",
        "name": "Software and applications developers and analysts (general)",
        "disabled": true
    },
    {
        "code": "2512",
        "name": "Software developers",
        "disabled": true
    },
    {
        "code": "2352",
        "name": "Special needs teachers",
        "disabled": true
    },
    {
        "code": "2212",
        "name": "Specialist medical practitioners ",
        "disabled": true
    },
    {
        "code": "3422",
        "name": "Sports coaches, instructors and officials",
        "disabled": true
    },
    {
        "code": "1431",
        "name": "Sports, recreation and cultural centre managers",
        "disabled": true
    },
    {
        "code": "7132",
        "name": "Spray painters and varnishers",
        "disabled": true
    },
    {
        "code": "5211",
        "name": "Stall and market salespersons",
        "disabled": true
    },
    {
        "code": "8189",
        "name": "Stationary plant and machine operators (general)",
        "disabled": true
    },
    {
        "code": "4312",
        "name": "Statistical, finance and insurance clerks",
        "disabled": true
    },
    {
        "code": "3314",
        "name": "Statistical, mathematical and related associate professionals",
        "disabled": true
    },
    {
        "code": "8182",
        "name": "Steam engine and boiler operators",
        "disabled": true
    },
    {
        "code": "4321",
        "name": "Stock clerks",
        "disabled": true
    },
    {
        "code": "7113",
        "name": "Stonemasons, stone cutters, splitters and carvers",
        "disabled": true
    },
    {
        "code": "9510",
        "name": "Street and related service workers",
        "disabled": true
    },
    {
        "code": "5212",
        "name": "Street food salespersons",
        "disabled": true
    },
    {
        "code": "9520",
        "name": "Street vendors (excluding food)",
        "disabled": true
    },
    {
        "code": "7214",
        "name": "Structural-metal preparers and erectors",
        "disabled": true
    },
    {
        "code": "6310",
        "name": "Subsistence crop farmers",
        "disabled": true
    },
    {
        "code": "6340",
        "name": "Subsistence fishers, hunters, trappers and gatherers",
        "disabled": true
    },
    {
        "code": "6320",
        "name": "Subsistence livestock farmers",
        "disabled": true
    },
    {
        "code": "6330",
        "name": "Subsistence mixed crop and livestock farmers",
        "disabled": true
    },
    {
        "code": "1324",
        "name": "Supply, distribution and related managers",
        "disabled": true
    },
    {
        "code": "4227",
        "name": "Survey and market research interviewers",
        "disabled": true
    },
    {
        "code": "9613",
        "name": "Sweepers and related labourers",
        "disabled": true
    },
    {
        "code": "2522",
        "name": "Systems administrators",
        "disabled": true
    },
    {
        "code": "2511",
        "name": "Systems analysts",
        "disabled": true
    },
    {
        "code": "7531",
        "name": "Tailors, dressmakers, furriers and hatters",
        "disabled": true
    },
    {
        "code": "5312",
        "name": "Teachers\u201a\u00c4\u00f4 aides",
        "disabled": true
    },
    {
        "code": "2359",
        "name": "Teaching professionals (general)",
        "disabled": true
    },
    {
        "code": "2433",
        "name": "Technical and medical sales professionals (excluding ICT)",
        "disabled": true
    },
    {
        "code": "3522",
        "name": "Telecommunications engineering technicians",
        "disabled": true
    },
    {
        "code": "2153",
        "name": "Telecommunications engineers",
        "disabled": true
    },
    {
        "code": "4223",
        "name": "Telephone switchboard operators",
        "disabled": true
    },
    {
        "code": "8159",
        "name": "Textile, fur and leather products machine operators (general)",
        "disabled": true
    },
    {
        "code": "7516",
        "name": "Tobacco preparers and tobacco products makers",
        "disabled": true
    },
    {
        "code": "7222",
        "name": "Toolmakers and related workers",
        "disabled": true
    },
    {
        "code": "2164",
        "name": "Town and traffic planners",
        "disabled": true
    },
    {
        "code": "3324",
        "name": "Trade brokers",
        "disabled": true
    },
    {
        "code": "3230",
        "name": "Traditional and complementary medicine associate professionals",
        "disabled": true
    },
    {
        "code": "2230",
        "name": "Traditional and complementary medicine professionals",
        "disabled": true
    },
    {
        "code": "1113",
        "name": "Traditional chiefs and heads of village",
        "disabled": true
    },
    {
        "code": "2424",
        "name": "Training and staff development professionals",
        "disabled": true
    },
    {
        "code": "2643",
        "name": "Translators, interpreters and other linguists",
        "disabled": true
    },
    {
        "code": "4323",
        "name": "Transport clerks",
        "disabled": true
    },
    {
        "code": "5112",
        "name": "Transport conductors",
        "disabled": true
    },
    {
        "code": "5111",
        "name": "Travel attendants and travel stewards",
        "disabled": true
    },
    {
        "code": "4221",
        "name": "Travel consultants and clerks",
        "disabled": true
    },
    {
        "code": "5113",
        "name": "Travel guides",
        "disabled": true
    },
    {
        "code": "6112",
        "name": "Tree and shrub crop growers",
        "disabled": true
    },
    {
        "code": "4131",
        "name": "Typists and word processing operators",
        "disabled": true
    },
    {
        "code": "5163",
        "name": "Undertakers and embalmers",
        "disabled": true
    },
    {
        "code": "7541",
        "name": "Underwater divers",
        "disabled": true
    },
    {
        "code": "2310",
        "name": "University and higher education teachers",
        "disabled": true
    },
    {
        "code": "7534",
        "name": "Upholsterers and related workers",
        "disabled": true
    },
    {
        "code": "3315",
        "name": "Valuers and loss assessors",
        "disabled": true
    },
    {
        "code": "9122",
        "name": "Vehicle cleaners",
        "disabled": true
    },
    {
        "code": "2250",
        "name": "Veterinarians",
        "disabled": true
    },
    {
        "code": "3240",
        "name": "Veterinary technicians and assistants",
        "disabled": true
    },
    {
        "code": "2651",
        "name": "Visual artists",
        "disabled": true
    },
    {
        "code": "2320",
        "name": "Vocational education teachers",
        "disabled": true
    },
    {
        "code": "5131",
        "name": "Waiters",
        "disabled": true
    },
    {
        "code": "9624",
        "name": "Water and firewood collectors",
        "disabled": true
    },
    {
        "code": "8152",
        "name": "Weaving and knitting machine operators",
        "disabled": true
    },
    {
        "code": "2513",
        "name": "Web and multimedia developers",
        "disabled": true
    },
    {
        "code": "3514",
        "name": "Web technicians",
        "disabled": true
    },
    {
        "code": "7212",
        "name": "Welders and flamecutters",
        "disabled": true
    },
    {
        "code": "8113",
        "name": "Well drillers and borers and related workers",
        "disabled": true
    },
    {
        "code": "9123",
        "name": "Window cleaners",
        "disabled": true
    },
    {
        "code": "8172",
        "name": "Wood processing plant operators",
        "disabled": true
    },
    {
        "code": "7521",
        "name": "Wood treaters",
        "disabled": true
    },
    {
        "code": "7523",
        "name": "Woodworking-machine tool setters and operators",
        "disabled": true
    }
]
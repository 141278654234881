import { initialOccupationObject } from "../../config/widgets/selectors/availableOccupationsByCountry";
import {CHANGE_LANG, CHANGE_COUNTRY, CHANGE_OCCUPATION, LOGIN, LOGOUT} from "./actionTypes"

export const login = (data) => {
  return {
    type: LOGIN,
    user: data.user,
    error: data.error
  };
};
  
export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const changeLang = (data) => {
  return {
    type: CHANGE_LANG,
    lang: data.lang,
    locale: data.locale,
    flag: data.flag
  }
}

export const changeCountry = (data) => {
  return {
    type: CHANGE_COUNTRY,
    country: data.country,
    occupation: initialOccupationObject,
    displayedOccupations: data.displayedOccupations
  }
}

export const changeOccupation = (data) => {
  return {
    type: CHANGE_OCCUPATION,
    country: data.country,
    occupation: data.occupation,
    displayedOccupations: data.displayedOccupations
  }
}